import { DateTime, DateTimeFormatOptions } from 'luxon';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';

export function capitalize(str: string): string {
  if (str.length === 0)
    return str;
  if (str.length === 1)
    return str.toUpperCase();
  return `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`;
}

export function emailToName(email: string): string {
  if (email.match(/\w+.\w+@voltserver.com/g)) {
    const dotIdx = email.indexOf('.');
    const first = email.substring(0, dotIdx).replace(/\d/, '');
    const last = email.substring(dotIdx + 1, email.indexOf('@')).replace(/\d/, '');
    return `${capitalize(first)} ${capitalize(last)}`;
  }
  return email;
}

export function formatDateTime(date: Date): string {
  const dt = typeof date === 'string'
    ? DateTime.fromISO(date, { zone: 'UTC' })
    : DateTime.fromJSDate(date, { zone: 'UTC' });
  return dt.toLocaleString({
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatDate(date: Date | string, options?: DateTimeFormatOptions): string {
  const dt = typeof date === 'string'
    ? DateTime.fromISO(date, { zone: 'UTC' })
    : DateTime.fromJSDate(date, { zone: 'UTC' });
  return dt.toLocaleString(options ?? DateTime.DATE_FULL);
}

type Options = { deep?: boolean; exclude?: string[] | RegExp[] };

/**
 * Maps the keys of an object to snake_case
 *
 * @param obj The object to map
 * @param options The options to use when mapping. If `deep`, then nested objects will be mapped too. Use `exclude`
 *  to prevent certain keys from being mapped.
 */
export function snakeCaseKeys(obj?: object, options: Options = {}): object | undefined {
  if (!obj) return obj;
  return mapKeys(obj, snakeCase, options);
}

/**
 * Maps the keys of an object to camelCase
 *
 * @param obj The object to map
 * @param options The options to use when mapping. If `deep`, then nested objects will be mapped too. Use `exclude`
 *  to prevent certain keys from being mapped.
 */
export function camelCaseKeys(obj?: object, options: Options = {}): object | undefined {
  if (!obj) return obj;
  return mapKeys(obj, camelCase, options);
}

export function mapKeys(obj: object, mapper: (v: string) => string, options: Options = {}): object {
  const opts: Options = { deep: true, exclude: [], ...options };
  if (!obj || typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    const newArray: object[] = [];
    obj.forEach((value, index) => {
      newArray[index] = typeof value === 'object' && opts.deep
        ? mapKeys(value, mapper, opts)
        : value;
    });
    return newArray;
  }

  const mappedObj: {[key: string]: object} = {};
  Object.entries(obj).forEach(([key, value]) => {
    const newKey = matches(opts.exclude ?? [], key) ? key : mapper(key);
    mappedObj[newKey] = typeof value === 'object' && opts.deep
      ? mapKeys(value, mapper, opts)
      : value;
  });
  return mappedObj;
}

export function matches(patterns: string[] | RegExp[], value: string) {
  return patterns.some((pattern: string | RegExp) => (typeof pattern === 'string'
    ? pattern === value
    : pattern.test(value)));
}
