import http from './http';
import { IHowToGetBetter } from '../../../app/models/how_to_get_better';

const BASE_URL= '/how_to_get_better';

export async function fetchAll(): Promise<IHowToGetBetter[]> {
  const {data} = await http.get(BASE_URL);
  return data;
}

export async function create(item: IHowToGetBetter): Promise<IHowToGetBetter> {
  const {data} = await http.post(BASE_URL, item);
  return data;
}

export async function update(item: IHowToGetBetter): Promise<IHowToGetBetter> {
  const {data} = await http.put(`${BASE_URL}/${item.entry_id}`, item);
  return data;
}

export async function destroy(item: IHowToGetBetter): Promise<number> {
  const {status} = await http.delete(`${BASE_URL}/${item.entry_id}`);
  return status;
}

export async function upvote(item: IHowToGetBetter, userId: string): Promise<IHowToGetBetter> {
  item.upvoted_by.push(userId);
  const {data} = await http.put(`${BASE_URL}/${item.entry_id}/upvote`);
  return data;
}

export async function unUpvote(item: IHowToGetBetter, userId: string): Promise<IHowToGetBetter> {
  item.upvoted_by.splice(item.upvoted_by.indexOf(userId), 1);
  const {data} = await http.delete(`${BASE_URL}/${item.entry_id}/upvote`);
  return data;
}
