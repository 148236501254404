/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import DeviceTimeline from './DeviceTimeline';
import DeviceInfo from './DeviceInfo';
import TimelineNoteField from './TimelineNoteField';
import { IDevice } from '../../../../app/models/device';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  device: IDevice;
  fetchData: () => Promise<void>;
}

const DevicePage: React.FC<IProps> = ({
  device,
  fetchData,
}: IProps) => {
  if (!device || !device.events) return <LoadingSpinner />;
  if (device.events.length === 0) {
    return (
      <p>No records for that serial number could be found</p>
    );
  }

  return (
    <div>
      <DeviceInfo {...device} />
      <br />
      <div className="timeline">
        <header className="timeline-header">
          <span className="tag is-medium is-primary">Recent</span>
        </header>
        <TimelineNoteField {...device} fetchData={fetchData} />
        <DeviceTimeline {...device} />
        <div className="timeline-header">
          <span className="tag is-medium is-primary">Start</span>
        </div>
      </div>
    </div>
  );
};

export default DevicePage;
