import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';

import http from '@actions/http';
import { UserProfile, IUser } from '@models/User';

import { axiosFetcher } from './common';

export function useUser(email?: string, config?: SWRConfiguration): SWRResponse<UserProfile, AxiosError> {
  return useSWR<UserProfile, AxiosError>(
    email ? `/users/${email}` : null,
    (url: string) => http.get(url).then((resp) => resp.data).catch(() => defaultUserProfile(email)),
    config,
  );
}

export function useMe(shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<IUser, AxiosError> {
  return useSWR<IUser, AxiosError>(shouldFetch ? '/me' : null, axiosFetcher, config);
}

function defaultUserProfile(email: string): UserProfile {
  return {
    '@odata.context': '',
    businessPhones: [],
    displayName: email,
    givenName: email,
    jobTitle: '',
    mail: email,
    mobilePhone: null,
    officeLocation: null,
    preferredLanguage: '',
    surname: email,
    userPrincipalName: email,
    id: email,
  };
}
