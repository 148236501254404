import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {AxiosError} from 'axios';

import http from '../actions/http';
import { Part } from '../../../app/models/parts';
import { RmaInventory, RmaItem, RmaUnserializedItem } from '../../../app/models/inventory';
import { axiosFetcher } from './common';


export function useUnserializedItemNameList(shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<string[], AxiosError> {
    return useSWR<string[], AxiosError>( //Takes the unserialized_item_name property from each object in the array, then reduces the array to a string array
    shouldFetch ? `/inventory/unserializedItemList` : null,
      (url: string) => http.get(url).then((resp) => (resp.data as { unserialized_item_name: string }[])).then((data) => data.map(d => d.unserialized_item_name)),
      config,
    );
}

export function useCustomers(shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<string[], AxiosError> {
    return useSWR<string[], AxiosError>(
        shouldFetch ? `/entry/allCustomers` : null,
        (url: string) => http.get(url).then((resp) => (resp.data as string[]).sort()),
        config,
    );
}

export function useParts(shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<Part[], AxiosError> {
    return useSWR<Part[], AxiosError>(
        shouldFetch ? `/entry/allParts` : null,
        axiosFetcher,
        config,
    );
}

export function useNewRma(rmaNumber: string, shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<RmaInventory, AxiosError> {
    return useSWR<RmaInventory, AxiosError>(
        shouldFetch ? `/entry/rma/${rmaNumber}` : null,
        axiosFetcher,
        config,
    );
}

export function useRmaItems(rmaNumber: string, shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<RmaItem[], AxiosError> {
    return useSWR<RmaItem[], AxiosError>(
        shouldFetch && rmaNumber !== 'new' ? `/entry/rma/${rmaNumber}/items/serialized`: null,
        axiosFetcher,
        config,
    );
}
export function useUnserializedRmaItems(rmaNumber: string, shouldFetch: boolean = true, config?: SWRConfiguration): SWRResponse<RmaUnserializedItem[], AxiosError> {
    return useSWR<RmaUnserializedItem[], AxiosError>(
        shouldFetch && rmaNumber !== 'new' ? `/entry/rma/${rmaNumber}/items/unserialized`: null,
        axiosFetcher,
        config,
    );
}