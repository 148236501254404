import React from 'react';

interface IProps {
  closeDialogAction: () => void;
  children: JSX.Element;
  className?: string;
}

const ContentModal = ({ closeDialogAction, children, className='' }: IProps): JSX.Element => (
  <div className={`modal is-active ${className}`}>
    <div className="modal-background" onClick={closeDialogAction} aria-hidden="true" />
    { children }
    <button className="modal-close is-large" aria-label="closeModal" type="button" onClick={closeDialogAction} />
  </div>
);

ContentModal.defaultProps = {
  className: '',
};

export default ContentModal;
