import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { IUser } from '@models/User';
import { logout } from '@actions/auth';
import { fetchParts } from '@actions/device';
import { useDebounce } from '@hooks/common';

import SearchPanel from './SearchPanel';
import FeedbackDialog from '../FeedbackDialog';
import WhatsNew from './WhatsNew';

interface Props {
  user: IUser;
  toggleMenu: () => void;
}

export type ContextType = {
  hidePanel: () => void;
};
export const NavBarContext = React.createContext<ContextType>({
  hidePanel: () => null,
});
export const useNavBarContext = () => useContext(NavBarContext);

const NavBar: React.FC<Props> = ({ user, toggleMenu }: Props) => {
  const [partCodes, setPartCodes] = useState([]);
  const [search, setSearch] = useState('');
  const panelRef = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLInputElement>();
  const [showPanel, setShowPanel] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showing, setShowing] = useState<boolean>(false);
  const [newVersion, setNewVersion] = useState<boolean>(false);

  const doLogout = (): void => {
    logout().then(() => {
      window.location.reload();
    });
  };

  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    fetchParts().then(setPartCodes);
  }, []);

  // Listener to hide search panel when clicking outside of it and the search field
  useEffect(() => {
    if (showPanel) {
      const handleClick = (e: any) => {
        if (panelRef.current.contains(e.target) || searchRef.current.contains(e.target)) return;
        setShowPanel(false);
      };
      document.addEventListener('mousedown', handleClick);
      return () => document.removeEventListener('mousedown', handleClick);
    }
    return () => null;
  }, [showPanel]);

  return (
    <NavBarContext.Provider value={{
      hidePanel: () => setShowPanel(false),
    }}>
      <div className="navbar">
        <button type="button" className="navbar-btn button is-white menu-toggle" onClick={toggleMenu}>
          <i className="fas fa-bars" />
        </button>

        <div className="navbar-center">
          <div className="search-wrapper">
            {showPanel && (
              <SearchPanel ref={panelRef} search={debouncedSearch} partCodes={partCodes} user={user} />
            )}
            <div className="field search-input" style={{ marginBottom: 0 }}>
              <p className="control has-icons-left">
                <input
                  onChange={(e) => setSearch(e.target.value.trim())}
                  ref={searchRef}
                  className="input"
                  type="text"
                  onFocus={() => setShowPanel(true)}
                  placeholder="Search GLaDOS"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search" />
                </span>
              </p>
            </div>
          </div>
          <button
            data-tip="Help / Feedback"
            data-for="feedback-tt"
            type="button"
            className="navbar-btn button is-white"
            onClick={() => setShowFeedbackDialog(true)}>
            <i className="fas fa-question-circle" />
          </button>
          <ReactTooltip id="feedback-tt" delayShow={250} place="bottom" effect="solid" />
        </div>

        <div className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link is-unselectable" unselectable="on">
            {user.profile.displayName ?? 'Account'}
          </div>

          <div className="navbar-dropdown is-right">
            <button
              type="button"
              className="navbar-item button is-white"
              onClick={() => setShowing(!showing)}>
              <i className={`fas fa-code-branch mrs ${newVersion && 'has-text-success'}`} />
              <span>What&apos;s New</span>
            </button>
            <button type="button" onClick={doLogout} className="navbar-item button is-white">
              <i className="fas fa-sign-out-alt mrs" />
              <span>Log Out</span>
            </button>
          </div>
        </div>

        <WhatsNew
          user={user}
          showing={showing}
          setShowing={setShowing}
          setNewVersion={setNewVersion}
        />

        {showFeedbackDialog && (
          <FeedbackDialog
            closeDialogAction={() => setShowFeedbackDialog(false)}
            user={user}
          />
        )}
      </div>
    </NavBarContext.Provider>
  );
};

export default NavBar;
