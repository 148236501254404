import http from './http';
import { DeviceMetric } from '../../../app/models/device';

export async function fetchStandard(): Promise<DeviceMetric> {
  const {data} = await http.get('/metrics/standard');
  return data;
}

export async function fetchDevices(): Promise<{[key: string]: DeviceMetric}> {
  const {data} = await http.get('/metrics/devices');
  return data;
}
