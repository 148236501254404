import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { IUser } from '@models/User';

import DEPARTMENTS from '../../utils/document';
import { IDocument } from '../../../../app/models/document';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  okAction: (doc: IDocument) => Promise<void>;
  user: IUser;
  title: string;
  confirmText: string;
  entity?: IDocument;
}

const EditDocumentModal: React.FC<IProps> = ({
  toggled,
  closeAction,
  title,
  confirmText,
  entity,
  okAction,
  user,
}: IProps) => {

  const [document, setDocument] = useState<IDocument>(entity || {
    id: null,
    link: '',
    title: '',
    type: '',
    class: 'Info',
    department: [],
    audience: 'Internal',
    tag: '',
    last_updated_by: '',
    last_updated_date: null,
  });

  useEffect(() => {
    if (entity) setDocument(entity);
  }, [entity]);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = evt.target;
    setDocument({...document, [name]: value });
  };

  const handleDepartmentChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = evt.target;
    const { department } = document;
    const newDepartments = department.slice(0); // clone original
    if (checked && !newDepartments.includes(value)) newDepartments.push(value);
    else if (!checked && newDepartments.includes(value)) newDepartments.splice(newDepartments.indexOf(value), 1);
    setDocument({...document, department: newDepartments});
  };

  const handleSave = async (): Promise<void> => {
    await okAction({
      ...document,
      last_updated_by: user.profile.displayName,
      last_updated_date: DateTime.local().toISODate(),
    });
    setDocument(entity || {
      id: null,
      link: '',
      title: '',
      type: '',
      class: 'Info',
      department: [],
      audience: 'Internal',
      tag: '',
      last_updated_by: '',
      last_updated_date: null,
    });
    closeAction();
  };

  const { department } = document;

  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" type="button" aria-label="close" onClick={closeAction} />
        </header>
        <section className="modal-card-body">

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Title</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="title"
                    onChange={handleChange}
                    value={document.title}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Link / Text</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <textarea
                    className="textarea"
                    name="link"
                    onChange={handleChange}
                    value={document.link}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Class</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="select">
                    <select name="class" value={document.class} onChange={handleChange}>
                      <option value="info">Info</option>
                      <option value="process">Process</option>
                      <option value="product">Product</option>
                      <option value="template">Template</option>
                      <option value="tool">Tool</option>
                      <option value="training">Training</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Type</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="type"
                    onChange={handleChange}
                    value={document.type}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <h3 className="label">Department</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="checkbox" htmlFor="departmentCheck">
                    <input
                      id="engDept"
                      type="checkbox"
                      name={`department.${DEPARTMENTS.ENG}`}
                      onChange={handleDepartmentChange}
                      value={DEPARTMENTS.ENG}
                      checked={department.includes(DEPARTMENTS.ENG)}
                    /> {DEPARTMENTS.ENG}
                  </label>
                  <label className="checkbox" htmlFor="opsDept">
                    <input
                      id="opsDept"
                      type="checkbox"
                      name={`department.${DEPARTMENTS.OPS}`}
                      onChange={handleDepartmentChange}
                      value={DEPARTMENTS.OPS}
                      checked={department.includes(DEPARTMENTS.OPS)}
                    /> Ops
                  </label>
                  <label className="checkbox" htmlFor="salesDept">
                    <input
                      id="salesDept"
                      type="checkbox"
                      name={`department.${DEPARTMENTS.SALES}`}
                      onChange={handleDepartmentChange}
                      value={DEPARTMENTS.SALES}
                      checked={department.includes(DEPARTMENTS.SALES)}
                    /> Sales
                  </label>
                  <label className="checkbox" htmlFor="csDept">
                    <input
                      id="csDept"
                      type="checkbox"
                      name={`department.${DEPARTMENTS.CS}`}
                      onChange={handleDepartmentChange}
                      value={DEPARTMENTS.CS}
                      checked={department.includes(DEPARTMENTS.CS)}
                    /> CS
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Tag</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="tag"
                    onChange={handleChange}
                    value={document.tag}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Audience</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="select">
                    <select name="audience" value={document.audience} onChange={handleChange}>
                      <option value="internal">Internal</option>
                      <option value="partner">Partner</option>
                      <option value="customer">Customer</option>
                      <option value="other">(Other)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleSave} />
        </footer>
      </div>
    </div>
  );
};

EditDocumentModal.defaultProps = {
  entity: null,
};

export default EditDocumentModal;
