import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { isWebUri } from 'valid-url';

import { fetch } from '@actions/document';

import LoadingSpinner from '../shared/LoadingSpinner';

const DocumentPage: React.FC = () => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!id) history.replace('/navi');
    else {
      fetch(id)
        .then((doc) => {
          if (isWebUri(doc.link)) window.location.href = doc.link;
          else history.replace('/navi');
        })
        .catch(() => history.replace('/navi'));
    }
  }, []);

  return <LoadingSpinner />;
};

export default DocumentPage;
