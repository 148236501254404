import React from 'react';

import { IprSummary, IprShipment } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';

interface IProps {
  summary: IprSummary;
  shipment: IprShipment[];
}

const IprInfo: React.FC<IProps> = ({
  summary: {
    customer,
    address,
    box_count,
    tracking,
    carrier,
    ipr_number,
    counts,
  },
  shipment,
}: IProps) => {
  if (!ipr_number) return null;

  let shipmentDate = '';
  if (shipment.length) shipmentDate = formatDate(shipment[0].shipment_date);

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          IPR #: {ipr_number}
        </p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <h3 className="vs-title">{customer || 'Unknown customer'}</h3>
              <p>{address || 'Unknown address'}</p>
              <p style={{ fontSize: '1.15rem', marginBottom: 0 }}>
                <i className="fa fa-shipping-fast" /> {carrier || 'Unknown carrier'}
                <i className="fa fa-box mll" />
                <span className="mls">{box_count} box{box_count > 1 ? 'es' : ''}</span>
              </p>
              <p style={{ color: '#9f9f9f', fontStyle: 'italic' }}>{shipmentDate}</p>
            </div>
            <div className="column">
              {renderDeviceCounts(counts)}
            </div>
            <div className="column">
              <span className="has-text-weight-semibold">Tracking</span>
              {tracking.map(num => (
                <a
                  key={`tracking-${num}-anchor`}
                  href={`https://www.google.com/search?q=${num}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <p>{num}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderDeviceCounts(count: {[key: string]: number}): JSX.Element[] {
  return Object.keys(count).map(key => (
    <p key={`device-count-${key}`} style={{ marginBottom: '0.3rem' }}>
      <b>{key}</b>: {count[key]}
    </p>
  ));
}

export default IprInfo;
