export enum IPRState {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  UNSUBMITTED = 'unsubmitted',
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  SHIPPED = 'shipped',
  COMPLETED = 'completed',
  NONE = '',
}

export interface IProductEntry {
  id: number | null;
  product: string;
  rev: string;
  qty: number;
  advanced_replacement: boolean;
  user_comments: string;
}

export interface DbIPREntry {
  ipr_num: number;
  state: IPRState;
  inventory_approver: string;
  accounting_approver: string;
  inventory_date: string;
  accounting_date: string;
  custom_product: boolean;
  is_rma: boolean;
  rma_number: number;
  created_by: string;
  created_datetime: string;
  date_needed: string;
  comments: string;
  reviewer_comments: string;
  shipping_address: string;
  charge_to: string;
  contact_name: string;
  ship_date: string;
  invoice_date: string;
  customer: string;
}

export interface IIPREntry extends DbIPREntry{
  products: IProductEntry[];
}
