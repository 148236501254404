import React from 'react';
import { Link } from 'react-router-dom';

import RmaShippingInfo from './RmaShippingInfo';
import { SummaryResult, RmaShipment, RmaSummary } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';

interface IProps {
  shipped: SummaryResult<RmaShipment, RmaSummary>;
}

const ShippedTable: React.FC<IProps> = ({shipped}: IProps) => {
  if ((!shipped || shipped.contents.length === 0)) {
    return (
      <div className="rma-page">
        <h1 className="has-text-weight-semibold is-size-5">New Units Shipped</h1>
        <p className="is-italic has-text-grey">No units were shipped</p>
      </div>
    );
  }
  return (
    <div className="rma-page">
      <h1 className="has-text-weight-semibold is-size-5">New Units Shipped</h1>
      <RmaShippingInfo summary={shipped.summary} shipment={shipped.contents} />
      <table className="table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Type</th>
            <th>Date Shipped</th>
            <th>JMA Branded</th>
            <th>Box</th>
            <th>Carrier</th>
            <th>Tracking</th>
          </tr>
        </thead>
        <tbody>
          { shipped.contents.map(renderShippedTableRow) }
        </tbody>
      </table>
    </div>
  );
};

function renderShippedTableRow(device: RmaShipment): JSX.Element {
  const {
    serial_number,
    part_number,
    shipment_date,
    jma_branded,
    carrier,
    tracking,
    box_number,
  } = device;

  return (
    <tr key={`${serial_number}-${part_number}`}>
      <td>{serial_number === 'N/A'
        ? ''
        : (<Link to={`/search/devices/${serial_number}`}>{serial_number}</Link>)}
      </td>
      <td>{part_number}</td>
      <td>{formatDate(shipment_date)}</td>
      <td>{jma_branded ? 'Yes':'No'}</td>
      <td>{box_number}</td>
      <td>{carrier}</td>
      <td>
        <a href={`https://www.google.com/search?q=${tracking}`} target="_blank" rel="noopener noreferrer">
          {tracking}
        </a>
      </td>
    </tr>
  );
}

export default ShippedTable;
