import React, { useState, useEffect } from 'react';
import { match, Prompt } from 'react-router';
import { History } from 'history';

import * as rma_actions from '@actions/rma_entry';

import { usePageTitle } from '@hooks/common';
import {useSFCaseLink} from '@hooks/rma';
import * as entry from '@hooks/entry';

import { RmaItem, RmaUnserializedItem, RmaInventory } from '../../../../app/models/inventory';

import { Part } from '../../../../app/models/parts';

import {
  UnserializedItemRmaEntryForm,
  SerializedItemRmaEntryForm,
  SerializedRmaTable,
  UnserializedRmaTable,
} from './RmaEntryComponents';

import {
  EntryCheckbox,
  EntryTextbox,
  EntrySelect,
  EntryNotification,
  EntryTextArea,
  EntryComboBox,
  serialToPart,
} from './EntryMiscComponents';

import ConfirmNavigation from './ConfirmNavigationModal';

const RMA_TYPE_OPTIONS = [
  'Hardware Failure',
  'SRA',
];

const REPORT_URL = 'http://voltserverdev7709.cloudapp.net/ReportServer/Pages/ReportViewer.aspx?%2fInventory%2fPacking+Slips%2fRMA+Return+Slip&rs:Command=Render&RmaNumber=';

interface IProps {
  match: match<{rma_number: string}>;
  history: History;
}

interface SerializedItem {
  part: string;
  jmaBranded: boolean;
  serialNumber: string;
  action: string;
}

interface UnserializedItem {
  part: string;
  quantity: string;
  action: string;
}

const newRma: RmaInventory = {
  rma_number: '',
  rma_type: 'Hardware Failure',
  comments: '',
  notes: '',
  customer: '',
  site: '',
  sales_force_number: '',
  user_id: '',
  machine_id: '',
  entry_date_time: null,
  status: 'Open',
  requires_eng_analysis: false,
};

const newSerializedItem: SerializedItem = {
  part: 'DCT200',
  jmaBranded: false,
  serialNumber: '',
  action: 'Advance Replacement',
};

const newUnserializedItem: UnserializedItem = {
  part: '',
  quantity: '',
  action: 'Advance Replacement',
};

const RMAEntryPage: React.FC<IProps> = ({
  match: { params },
  history,
}: IProps) => {
  const { rma_number } = params;
  usePageTitle(rma_number !== 'new' ? `RMA #${rma_number}` : 'New RMA');
  const { data: sfLink } = useSFCaseLink(rma_number);

  // Information related to the RMA itself.

  const [rma, setRma] = useState<RmaInventory>(newRma);

  // Serialized or Unserialized item currently being added to the RMA.

  const [serializedItem, setSerializedItem] = useState<SerializedItem>(newSerializedItem);
  const [unserializedItem, setUnserializedItem] = useState<UnserializedItem>(newUnserializedItem);

  // Items currently in the RMA to display.

  const [allRmaItems, setAllRmaItems] = useState<RmaItem[]>(null);
  const [allUnserializedRmaItems, setAllUnserializedRmaItems] = useState<RmaUnserializedItem[]>(null);

  // Dirty Form State information.

  const [cleanRma, setCleanRma] = useState<RmaInventory>(newRma);
  const [rmaFormDirty, setRmaFormDirty] = useState<boolean>(false);
  const [serializedItemsFormDirty, setSerializedItemsFormDirty] = useState<boolean>(false);
  const [unserializedItemsFormDirty, setUnserializedItemsFormDirty] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>('');

  // Miscellaneous state information.

  const [success, setSuccess] = useState<boolean>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [readonly, setReadonly] = useState<boolean>(true);

  // Fetch Information

    //Fetch static information

  const {data: unserializedItemNameList, error: unserializedItemNameListError} = entry.useUnserializedItemNameList();
  if(unserializedItemNameListError) setErrorMessages(errorMessages.concat([unserializedItemNameListError.message]));

  const {data: allCustomers, error:allCustomersError} = entry.useCustomers();
  if(allCustomersError) setErrorMessages(errorMessages.concat([allCustomersError.message]));

  const {data: allParts, error:allPartsError} = entry.useParts();
  if(allPartsError) setErrorMessages(errorMessages.concat([allPartsError.message]));

    //Fetch RMA-specific information

  const {data: swrNewRma, error:swrNewRmaError} = entry.useNewRma(rma_number, rma === newRma);
  if(swrNewRmaError) setErrorMessages(errorMessages.concat([swrNewRmaError.message]));
  else if(swrNewRma && swrNewRma !== newRma){
    setRma(swrNewRma);
    setCleanRma(swrNewRma);
  }

  const {data: swrRmaItems, error:rmaItemsError} = entry.useRmaItems(rma_number, !allRmaItems || allRmaItems.length === 0);
  if(rmaItemsError) setErrorMessages(errorMessages.concat([rmaItemsError.message]));
  if(swrRmaItems && swrRmaItems.length > 0)setAllRmaItems(swrRmaItems);
  else if(!allRmaItems)setAllRmaItems([]); //Initialize empty list

  const {data: swrUnserializedRmaItems, error:unserializedRmaItemsError} = entry.useUnserializedRmaItems(rma_number, !allUnserializedRmaItems || allUnserializedRmaItems.length === 0);
  if(unserializedRmaItemsError) setErrorMessages(errorMessages.concat([unserializedRmaItemsError.message]));
  if(swrUnserializedRmaItems && swrUnserializedRmaItems.length > 0)setAllUnserializedRmaItems(swrUnserializedRmaItems);
  else if(!allUnserializedRmaItems)setAllUnserializedRmaItems([]); //Initialize empty list

  // Update form clean / dirty status.

  useEffect(() => {
    checkDirtyForm();
  }, [rma, cleanRma, serializedItem, unserializedItem]);

  // On change of rmaFormDirty, update the URL.
  useEffect(() => {
    if (rma_number === 'new'
      && cleanRma.rma_number
      && !rmaFormDirty
      && !serializedItemsFormDirty
      && !unserializedItemsFormDirty) history.replace(`/rma/${rma.rma_number}`);
  }, [rma_number,
    cleanRma.rma_number,
    rmaFormDirty,
    serializedItemsFormDirty,
    unserializedItemsFormDirty]);

  useEffect(() => {
    if (!allParts) return;
    const serialData = serialToPart(serializedItem.serialNumber, allParts);
    if (!serialData) return;
    setSerializedItem({
      ...serializedItem,
      part: serialData.part,
      jmaBranded: serialData.jmaBranded,
    });
  }, [serializedItem.serialNumber]);

  const delayConfirmModalNavigation = () => {
    setTimeout(() => {
      if (nextLocation === 'back') history.goBack();
      else history.push(nextLocation);
    }, 300);
  };

  // Form Validation functions

  function validateInputsSerialized(): boolean {
    const errors = [];
    if (serializedItem.part.length === 0) errors.push('Part cannot be empty.');
    if (serializedItem.serialNumber.length === 0) errors.push('Serial number cannot be empty.');
    const snExists = allRmaItems.map(item => item.serial_number).includes(serializedItem.serialNumber);
    if (snExists) errors.push('Serial number already included in this RMA.');
    setErrorMessages(errors);
    return errors.length === 0;
  }

  function validateInputsUnserialized(): boolean {
    const errors = [];

    const quantityInt = parseInt(unserializedItem.quantity, 10);
    if (Number.isNaN(quantityInt)) errors.push('Quantity must be a number.');
    if (unserializedItem.quantity.length > 5) errors.push('Quantity must be less than 5 digits.');
    if (!unserializedItem.part) errors.push('Part name must have a value.');

    setErrorMessages(errors);
    return errors.length === 0;
  }

  function validateRmaForm(): boolean {
    const errors = [];
    if (!rma.rma_number) errors.push('The RMA cannot be saved without an RMA number.');
    if (!rma.rma_type) errors.push('RMA type required before the new RMA can be created.');
    if (!rma.site) errors.push('Site is required before the RMA can be saved.');
    if (!rma.customer) errors.push('Enter a customer or choose one from the list before saving.');
    setErrorMessages(errors);
    return errors.length === 0;
  }

  function checkDirtyForm(): boolean {
    // Abort if run before initial fetches.

    if (rma === null || cleanRma === null || serializedItem === null || unserializedItem === null) return false;

    let cleanRmaForm = true;
    let cleanSerializedForm = true;
    let cleanUnserializedForm = true;

    Object.keys(rma).forEach(key => {
      cleanRmaForm = cleanRmaForm && rma[key] === cleanRma[key];
    });

    cleanSerializedForm = cleanSerializedForm && serializedItem.serialNumber === newSerializedItem.serialNumber;
    cleanSerializedForm = cleanSerializedForm && serializedItem.part === newSerializedItem.part;
    cleanSerializedForm = cleanSerializedForm && serializedItem.jmaBranded === newSerializedItem.jmaBranded;

    cleanUnserializedForm = cleanUnserializedForm && unserializedItem.part === newUnserializedItem.part;
    cleanUnserializedForm = cleanUnserializedForm && unserializedItem.quantity === newUnserializedItem.quantity;

    setRmaFormDirty(!cleanRmaForm);
    setSerializedItemsFormDirty(!cleanSerializedForm);
    setUnserializedItemsFormDirty(!cleanUnserializedForm);

    return cleanRmaForm && cleanSerializedForm && cleanUnserializedForm;
  }

  // Save data functions.

  function saveRma(): Promise<boolean> {
    if (!validateRmaForm()) return;

    if (rma_number === 'new' && cleanRma.rma_number === '') {
      rma_actions.createRma(rma).then(() => {
        setSuccessWithTimeout(true);
        setCleanRma({...rma});
        setRmaFormDirty(false);
      }).catch(err => {
        setSuccessWithTimeout(false);
        setErrorMessages([err.message]);
      });
    }
    else {
      rma_actions.updateRma(rma).then(() => {
        setSuccessWithTimeout(true);
        setCleanRma({...rma});
        return true;
      }).catch(err => {
        setSuccessWithTimeout(false);
        setErrorMessages([err.message]);
        return false;
      });
    }
  }

  async function saveSerializedItem(): Promise<void> {
    if (!validateInputsSerialized()) return;

    addSerializedItem(serializedItem, rma.rma_number).then(added => {
      setSuccessWithTimeout(true);
      setAllRmaItems(allRmaItems.concat([added]));
      setSerializedItem({
        part: newSerializedItem.part,
        jmaBranded: newSerializedItem.jmaBranded,
        serialNumber: newSerializedItem.serialNumber,
        action: serializedItem.action,
      });
    }).catch(err => {
      setSuccessWithTimeout(false);
      setErrorMessages(errorMessages.concat(err.message));
    });

    if (rma_number === 'new') await saveRma();
  }

  async function saveUnserializedItem(): Promise<void> {
    if (!validateInputsUnserialized()) return;

    addUnserializedItem(unserializedItem, rma.rma_number).then(added => {
      setSuccessWithTimeout(true);
      setAllUnserializedRmaItems(allUnserializedRmaItems.concat([added]));
      setUnserializedItem({
        part: newUnserializedItem.part,
        quantity: newUnserializedItem.quantity,
        action: unserializedItem.action,
      });
    }).catch(err => {
      setSuccessWithTimeout(false);
      setErrorMessages(errorMessages.concat(err.message));
    });

    if (rma_number === 'new') await saveRma();
  }

  // Utility functions

  function setSuccessWithTimeout(value: boolean): void {
    setSuccess(value);
    setTimeout(() => {
      setSuccess(null);
    }, 5000);
  }

  function isLoading(): boolean {
    return allRmaItems === null
      || allUnserializedRmaItems === null
      || unserializedItemNameList === null
      || allCustomers === null
      || allParts === null;
  }

  function whichFormIsDirty(): string {
    if (rmaFormDirty) return 'RMA Details';
    if (serializedItemsFormDirty) return 'Serialized Item Entry';
    if (unserializedItemsFormDirty) return 'Unserialized Item Entry';
    return 'Unknown';
  }

  return (
    <div>

      {/* Prompt the user when they leave a dirty form. */}
      <Prompt
        when={rmaFormDirty || serializedItemsFormDirty || unserializedItemsFormDirty}
        message={(location, action) => {
          if (action === 'POP') setNextLocation('back');
          else setNextLocation(location.pathname);
          setShowConfirmModal(true);
          return false;
        }}
      />

      <ConfirmNavigation
        show={showConfirmModal}
        whichForm={whichFormIsDirty()}
        close={() => setShowConfirmModal(false)}
        leavePage={() => {
          setRmaFormDirty(false);
          setSerializedItemsFormDirty(false);
          setUnserializedItemsFormDirty(false);
          setShowConfirmModal(false);
          delayConfirmModalNavigation();
        }}
        save={() => {
          if (rmaFormDirty) saveRma();
          else if (serializedItemsFormDirty) saveSerializedItem();
          else if (unserializedItemsFormDirty) saveUnserializedItem();
          setShowConfirmModal(false);
          delayConfirmModalNavigation();
        }}
      />

      {/* Error message notification */}

      <p>
        {errorMessages.length !== 0
          ? (
            <EntryNotification
              body={errorMessages.map((err) => <p key={err}>{err}</p>)}
              notificationType="is-danger"
              onClose={() => setErrorMessages([])}
            />
          ) : ''}
      </p>

      {/* Success/failure message notification */}

      <p>
        {success !== null
          && (
            <EntryNotification
              body={<p>{success ? 'Success' : 'Failure'}</p>}
              notificationType={success ? 'is-success' : 'is-failure'}
              onClose={() => setSuccess(null)}
            />
          )}
      </p>

      <div className={`box elev-1 ${isLoading() ? 'is-loading' : ''}`}>
        <div className="level">
          <div className="level-left">
            <button
              className="button"
              type="button"
              disabled={rma.status === 'Closed'}
              onClick={() => {
                if (readonly) {
                  setRmaFormDirty(false);
                  setSerializedItemsFormDirty(false);
                  setUnserializedItemsFormDirty(false);
                }
                setReadonly(!readonly);
              }}>
              {readonly ? 'Edit' : 'Cancel'}
            </button>

            <button
              type="button"
              className="button is-primary mls"
              onClick={() => saveRma()}
              disabled={readonly}>
              Save RMA
            </button>

            {rma.rma_number
              && (
                <a
                  href={sfLink?.url || '#'}
                  className={`mls button ${!sfLink ? 'inactiveLink' : ''} ${sfLink === undefined ? 'is-loading' : ''}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <span>{`SFDC Case${!sfLink ? ' Missing' : ''}`}</span>
                  <span className="icon">
                    <i className="fa fa-external-link-alt" />
                  </span>
                </a>
              )}

            <a
              className="button mls"
              href={`${REPORT_URL}${rma?.rma_number}`}
              target="_blank"
              rel="noopener noreferrer">
              <span>Generate Return Slip</span>
              <span className="icon">
                <i className="fa fa-external-link-alt" />
              </span>
            </a>
          </div>
          <div className="level-right">
            {rma.rma_number && rma.rma_number !== 'new' && (
              <button
                type="button"
                className={`mls button ${rma.status === 'Open' ? 'is-danger' : ''}`}
                onClick={() => {
                  const newStatus = rma.status === 'Open' ? 'Closed' : 'Open';
                  rma_actions.updateStatus(rma.rma_number, newStatus).then(() => {
                    setRma({
                      ...rma,
                      status: newStatus,
                    });
                    if (newStatus === 'Closed') setReadonly(true);
                  });
                }}>
                {rma.status === 'Open' ? 'Close' : 'Reopen'} RMA
              </button>
            )}
          </div>
        </div>

        <div className="columns">
          {/* RMA Overview */}
          <div className="column">
            <h1 className="mtm title">
              {() => {
                if (cleanRma === null) return '';
                return (cleanRma && !cleanRma.rma_number
                  ? 'New RMA'
                  : `${cleanRma.rma_number} - ${cleanRma.customer} - ${cleanRma.site}`);
              }}
            </h1>
            {/* RMA Number */}

            <EntryTextbox
              name="RMA #"
              value={rma.rma_number}
              fieldWidth="20em"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRma({
                  ...rma,
                  rma_number: event.target.value,
                });
              }}
              disabled={readonly || rma_number !== 'new'}
            />

            {/* Type */}

            <EntrySelect
              name="Type"
              value={rma.rma_type}
              options={RMA_TYPE_OPTIONS}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setRma({
                  ...rma,
                  rma_type: event.target.value,
                });
              }}
              disabled={readonly}
            />

            {/* Customer */}

            <EntryComboBox
              disabled={readonly}
              name="Customer"
              value={rma.customer}
              options={allCustomers || []}
              fieldWidth="20em"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRma({
                  ...rma,
                  customer: event.target.value,
                });
              }}
            />

            {/* Site */}

            <EntryTextbox
              name="Site"
              value={rma.site}
              disabled={readonly}
              fieldWidth="20em"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRma({
                  ...rma,
                  site: event.target.value,
                });
              }}
            />

            {/* Engineering Analysis checkbox */}

            <EntryCheckbox
              name="Requires Engineering Analysis?"
              checked={rma.requires_eng_analysis}
              disabled={readonly}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRma({
                  ...rma,
                  requires_eng_analysis: event.target.checked,
                });
              }}
            />

            {/* Notes */}

            <EntryTextArea
              name="Notes"
              value={rma.notes}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setRma({
                  ...rma,
                  notes: event.target.value,
                });
              }}
              rows={15}
              disabled={readonly}
            />
          </div>

          {/* RMA Items */}

          <div className="column" style={{ flexGrow: 1.5 }}>

            {allRmaItems !== null && (allRmaItems.length > 0 || (!readonly && allRmaItems.length === 0))
              ? <h1 className="title">Serialized Items</h1>
              : <div />}

            <SerializedRmaTable
              readonly={readonly}
              allRmaItems={allRmaItems || []}
              deleteSerializedItem={(entryId: number) => {
                rma_actions.deleteRmaItem(rma, String(entryId)).then(() => {
                  setSuccessWithTimeout(true);
                  setAllRmaItems(allRmaItems.filter(item => item.entry_id !== entryId));
                }).catch(e => {
                  setSuccessWithTimeout(false);
                  setErrorMessages(errorMessages.concat(e.message));
                });
              }}
            />

            <SerializedItemRmaEntryForm
              serialNumber={serializedItem.serialNumber}
              action={serializedItem.action}
              partName={serializedItem.part}
              jmaBranded={serializedItem.jmaBranded}
              handleSerialNumberChange={(event) => {
                setSerializedItem({
                  ...serializedItem,
                  serialNumber: event.target.value,
                });
              }}
              handleSerialNumberEnter={(event) => {
                event.preventDefault();
                if (event.keyCode === 13) saveSerializedItem();
              }}
              handleActionChange={(event) => {
                setSerializedItem({
                  ...serializedItem,
                  action: event.target.value,
                });
              }}
              handlePartNameChange={(event) => {
                setSerializedItem({
                  ...serializedItem,
                  part: event.target.value,
                });
              }}
              handleJmaBrandedChange={() => {
                setSerializedItem({
                  ...serializedItem,
                  jmaBranded: !serializedItem.jmaBranded,
                });
              }}
              partNames={allParts ? allParts.map(part => part.part_name) : []}
              readonly={readonly}
              submit={saveSerializedItem}
            />

            {(allUnserializedRmaItems !== null
              && (allUnserializedRmaItems.length > 0
                || (!readonly && allUnserializedRmaItems.length === 0)))
              ? <h1 className="title mtm">Unserialized Items</h1>
              : <div />}

            <UnserializedRmaTable
              readonly={readonly}
              allUnserializedItems={allUnserializedRmaItems || []}
              deleteUnserializedItem={(entryId: number) => {
                rma_actions.deleteUnserializedRmaItem(rma, String(entryId)).then(() => {
                  setSuccessWithTimeout(true);
                  setAllUnserializedRmaItems(allUnserializedRmaItems.filter(item => item.entry_id !== entryId));
                }).catch(e => {
                  setSuccessWithTimeout(false);
                  setErrorMessages(errorMessages.concat(e.message));
                });
              }}
            />

            <UnserializedItemRmaEntryForm
              partName={unserializedItem.part}
              quantity={unserializedItem.quantity}
              action={unserializedItem.action}
              handlePartNameChange={(event) => {
                setUnserializedItem({
                  ...unserializedItem,
                  part: event.target.value,
                });
              }}
              handleQuantityChange={(event) => {
                setUnserializedItem({
                  ...unserializedItem,
                  quantity: event.target.value,
                });
              }}
              handleActionChange={(event) => {
                setUnserializedItem({
                  ...unserializedItem,
                  action: event.target.value,
                });
              }}
              submit={() => saveUnserializedItem()}
              readonly={readonly}
              partNameList={unserializedItemNameList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

async function addSerializedItem(item: SerializedItem, rma_number: string): Promise<RmaItem> {
  const rmaItem: RmaItem = {
    entry_id: null,
    entry_date_time: new Date(),
    serial_number: item.serialNumber,
    part_number: item.part,
    jma_branded: item.jmaBranded,
    rma_number,
    status: 'Pending',
    user_id: 'web',
    action: item.action,
    send_notification_on_receive: null,
    notification_sent: null,
  };
  await rma_actions.addRmaItem(rmaItem);
  return rmaItem;
}

async function addUnserializedItem(item: UnserializedItem, rma_number: string): Promise<RmaUnserializedItem> {
  const rmaUnserializedItem: RmaUnserializedItem = {
    entry_id: null,
    entry_date_time: new Date(),
    serial_number: 'N/A',
    part_number: item.part,
    quantity: parseInt(item.quantity, 10),
    jma_branded: false,
    status: 'Pending',
    rma_number,
    user_id: 'web',
    action: item.action,
    send_notification_on_receive: null,
    notification_sent: null,
  };
  await rma_actions.addUnserializedRmaItem(rmaUnserializedItem);
  return rmaUnserializedItem;
}

export default RMAEntryPage;
