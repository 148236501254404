import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { RmaReceivedItem } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';

interface IProps {
  received: RmaReceivedItem[];
}

const ReceivedTable: React.FC<IProps> = ({received}: IProps) => {
  if ((!received || received.length === 0)) {
    return (
      <div>
        <h1 className="has-text-weight-semibold is-size-5">Units Received</h1>
        <p className="is-italic has-text-grey">No units received</p>
      </div>
    );
  }

  return (
    <div>
      <h1 className="has-text-weight-semibold is-size-5">Units Received</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Type</th>
            <th>Date Received</th>
            <th>Internal Analysis Notes</th>
            <th>Analysis Date</th>
            <th>IPO Date</th>
            <th>IPO Notes</th>
            <th>Return Ship Date</th>
            <th>Return Ship Address</th>
            <th>Tracking</th>
            <th>Scrap</th>
          </tr>
        </thead>
        <tbody>
          { received.map(renderReceivedTableRow) }
        </tbody>
      </table>
    </div>
  );
};

function renderReceivedTableRow(device: RmaReceivedItem): JSX.Element {
  const {
    serial_number,
    product_type,
    received_date_time,
    findings,
    analysis_date_time,
    return_shipment_date,
    return_shipment_address,
    return_shipment_tracking,
    scrap,
    ipo_date_time,
    ipo_notes,
  } = device;

  const returnDT = typeof return_shipment_date === 'string'
    ? DateTime.fromISO(return_shipment_date)
    : DateTime.fromJSDate(return_shipment_date);
  const receiveDT = typeof received_date_time === 'string'
    ? DateTime.fromISO(received_date_time)
    : DateTime.fromJSDate(received_date_time);

  return (
    <tr key={serial_number}>
      <td>{serial_number === 'N/A'
        ? ('')
        : <Link to={`/search/devices/${serial_number}`}>{serial_number}</Link>}
      </td>
      <td>{product_type}</td>
      <td>{formatDate(received_date_time)}</td>
      <td>{findings || 'NA'}</td>
      <td>{analysis_date_time ? formatDate(analysis_date_time, DateTime.DATE_MED) : 'NA'}</td>
      <td>{ipo_date_time ? formatDate(ipo_date_time, DateTime.DATE_MED) : 'NA'}</td>
      <td>{ipo_notes || 'NA'}</td>
      <td>{return_shipment_date && returnDT > receiveDT ? formatDate(return_shipment_date, DateTime.DATE_MED) : 'NA'}
      </td>
      <td>{returnDT > receiveDT && return_shipment_address ? return_shipment_address : 'NA'}
      </td>
      <td>{return_shipment_tracking
        ? (<a href={`https://www.google.com/search?q=${return_shipment_tracking}`} target="_blank" rel="noopener noreferrer">{return_shipment_tracking}</a>)
        : 'NA'}
      </td>
      <td>{scrap}</td>
    </tr>
  );
}

export default ReceivedTable;
