import React, { useState, useEffect } from 'react';

import { IUser } from '@models/User';
import { ChangeEvent } from '@models/utils';

import { IFieldIssue } from '../../../../app/models/field_issues';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  title: string;
  confirmText: string;
  okAction: (entity: IFieldIssue) => Promise<void>;
  user: IUser;
  entity?: IFieldIssue;
}

const EditIssueModal: React.FC<IProps> = ({
  toggled,
  closeAction,
  title,
  confirmText,
  okAction,
  user,
  entity,
}: IProps) => {
  const [issue, setIssue] = useState<IFieldIssue>(entity || {
    entry_id: null,
    title: '',
    type: 'Issue',
    product: '',
    status: 'Open❗',
    description: '',
    link: '',
    created_by: '',
    created_at: null,
    upvoted_by: [],
  });

  useEffect(() => {
    if (entity) setIssue(entity);
  }, [entity]);

  const handleChange = (evt: ChangeEvent): void => {
    const { name, value } = evt.target;
    setIssue({...issue, [name]: value});
  };

  const handleSave = async (): Promise<void> => {
    await okAction({
      ...issue,
      created_by: user.profile.displayName,
    });
    setIssue(entity || {
      entry_id: null,
      title: '',
      type: 'Issue',
      product: '',
      status: 'Open❗',
      description: '',
      link: '',
      created_by: '',
      created_at: null,
      upvoted_by: [],
    });
    closeAction();
  };

  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" type="button" aria-label="close" onClick={closeAction} />
        </header>
        <section className="modal-card-body">

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Title</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="title"
                    onChange={handleChange}
                    value={issue.title}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Type</h3>
            </div>
            <div className="field-body">
              <div className="field is-normal">
                <div className="control">
                  <div className="select is-fullWidth">
                    <select name="type" value={issue.type} onChange={handleChange}>
                      <option value="Feature">Feature</option>
                      <option value="Issue">Issue</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Product</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="product"
                    onChange={handleChange}
                    value={issue.product}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Status</h3>
            </div>
            <div className="field-body">
              <div className="field is-normal">
                <div className="control">
                  <div className="select is-fullWidth">
                    <select name="status" value={issue.status} onChange={handleChange}>
                      <option value="Open❗">Open❗</option>
                      <option value="Will Be Fixed 👍">Will Be Fixed 👍</option>
                      <option value="Maybe Someday❓">Maybe Someday❓</option>
                      <option value="Fixed ✅">Fixed ✅</option>
                      <option value="Will Not Fix ❌">Will Not Fix ❌</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Link</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="link"
                    onChange={handleChange}
                    value={issue.link || ''}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Description</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea"
                    name="description"
                    rows={5}
                    onChange={handleChange}
                    value={issue.description}
                  />
                </div>
              </div>
            </div>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleSave} />
        </footer>
      </div>
    </div>
  );
};

EditIssueModal.defaultProps = {
  entity: null,
};

export default EditIssueModal;
