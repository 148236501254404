import React from 'react';

import { formatDate } from '../../utils/format';
import { getManufacturer } from '../../utils/manufacturers';
import { MrbReceiving } from '../../../../app/models/inventory';

const MrbRecord: React.FC<MrbReceiving> = ({
  serial_number,
  po_number,
  manufacturer_name,
  entry_date_time,
  receive_date,
}: MrbReceiving) => {
  if (!serial_number) return null;
  const date_time = receive_date || entry_date_time;
  return (
    <div>
      <p className="heading">{formatDate(date_time)}</p>
      <p>Received MRB from { getManufacturer(manufacturer_name) } on PO #{po_number}</p>
    </div>
  );
};

export default MrbRecord;
