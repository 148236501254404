import React, { useState, useEffect } from 'react';
import ReactTable, { Filter, Column } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Location, History } from 'history';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';
import {
  fetchAll,
  create,
  update,
  destroy,
  upvote,
  unUpvote,
} from '@actions/how_to_get_better';
import { renderIfAdmin } from '@utils/permissions';
import {
  updateFilteredQueryString,
  filterAsString,
  filterDatesShort,
} from '@utils/table_helpers';
import { IUser } from '@models/User';
import { formatDate } from '@utils/format';

import { IHowToGetBetter } from '../../../../app/models/how_to_get_better';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import EditEntryModal from './EditEntryModal';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
  user: IUser;
}

const HowToGetBetterPage: React.FC<IProps> = ({location: { search }, history, user}: IProps) => {
  usePageTitle('How To Get Better');

  const [h2gbs, setH2gbs] = useState<IHowToGetBetter[] | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IHowToGetBetter>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<IHowToGetBetter>(null);

  const addDocumentDialog = (): void => {
    setModalOpen(true);
    setEditItem(null);
  };

  const editItemFunc = (editingItem: IHowToGetBetter, evt: React.MouseEvent): void => {
    setModalOpen(true);
    setEditItem(editingItem);
    evt && evt.preventDefault();
  };

  const deleteItemFunc = async (h2gb: IHowToGetBetter): Promise<void> => {
    await destroy(h2gb);
    setShowDeleteDialog(false);
    setDeleteItem(null);
    await fetchAll().then(how2GetBetters => setH2gbs(how2GetBetters));
  };

  const saveEntry = async (entry: IHowToGetBetter): Promise<void> => {
    await (entry.entry_id ? update(entry) : create(entry));
    await fetchAll().then(how2GetBetters => setH2gbs(how2GetBetters));
  };

  const doUpvote = async (userID: string, h2gb: IHowToGetBetter, index: number): Promise<void> => {
    const newH2GB = await upvote(h2gb, userID);
    setH2gbs(Object.assign([], h2gbs, {[index]: newH2GB}));
  };

  const downvote = async (userID: string, h2gb: IHowToGetBetter, index: number): Promise<void> => {
    const newH2GB = await unUpvote(h2gb, userID);
    setH2gbs(Object.assign([], h2gbs, {[index]: newH2GB}));
  };

  const alreadyUpvoted = (
    upvotedBy: string[],
    userName: string,
    userID: string,
  ): boolean => upvotedBy.includes(userName) || upvotedBy.includes(userID);

  const upvoteOrDownvote = (userName: string, userID: string, h2gb: IHowToGetBetter, index: number): void => {
    if (alreadyUpvoted(h2gb.upvoted_by, userName, userID)) downvote(userName, h2gb, index);
    else doUpvote(userName, h2gb, index);
  };

  const amountVoted = (upvotedBy: string): number => upvotedBy.length;

  const toggleIcon = (upvotedBy: string[], userName: string, userID: string): string => {
    if (alreadyUpvoted(upvotedBy, userName, userID)) return 'fa fa-thumbs-up downvote';
    return 'far fa-thumbs-up novote';
  };

  const toggleColor = (upvotedBy: string[], userName: string, userID: string): string => {
    if (alreadyUpvoted(upvotedBy, userName, userID)) return 'downvote';
    return 'novote';
  };

  useEffect(() => {
    if (h2gbs === null) fetchAll().then(h2gb => setH2gbs(h2gb));
  }, []);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{
    Header: 'Item',
    accessor: 'item',
    className: 'itemTitle',
    maxWidth: 700,
    minWidth: 300,
  }, {
    Header: 'Type',
    accessor: 'type',
    maxWidth: 200,
    minWidth: 120,
  },
  {
    Header: 'Effort Level',
    accessor: 'effort_level',
    maxWidth: 120,
    minWidth: 100,
  },
  { Header: 'Flag',
    accessor: 'flag',
    filterable: false,
    maxWidth: 90,
    minWidth: 60,
    Cell: function flagCell(cell) {
      if (cell.value === true) {
        return (
          <div className="has-text-centered">
            <span>⚠️</span>
          </div>
        );
      }
      return null;
    }}, {
    Header: 'Status',
    accessor: 'status',
    maxWidth: 90,
    minWidth: 70,
  },
  { Header: 'Comment',
    accessor: 'comment',
    maxWidth: 700,
    minWidth: 220,
    Cell: function commentCell(cell) {
      return (
        <div>
          <span data-tip data-for={`${cell.index}-tooltip`}>{cell.original.comment}</span>
          <ReactTooltip id={`${cell.index}-tooltip`} multiline className="better-tooltip">
            <span>{cell.original.comment}</span>
          </ReactTooltip>
        </div>
      );
    }}, {
    Header: 'Link',
    accessor: 'link',
    filterable: false,
    maxWidth: 60,
    minWidth: 50,
    Cell: function linkCell(cell) {
      if (!cell.value) return null;
      return (
        <a
          href={cell.value}
          target="_new"
          style={{
            paddingLeft: '3px',
            paddingTop: '3px',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            display: 'block',
            fontSize: '1.3em',
          }}>
          <i className="fa fa-link" />
        </a>
      );
    },
  },
  { Header: 'Upvotes',
    accessor: 'upvoted_by',
    filterable: false,
    maxWidth: 90,
    minWidth: 80,
    sortMethod: (arrA, arrB) => arrA.length - arrB.length,
    Cell: function upvotedCell(cell) {
      return (
        <div className="has-text-centered">
          <span
            style={{paddingRight: '5px'}}
            className={toggleColor(cell.original.upvoted_by || '', user.profile.displayName, user.profile.id)}>
            {amountVoted(cell.original.upvoted_by)}
          </span>
          <button
            className="button-no-bg"
            type="button"
            onClick={() => upvoteOrDownvote(user.profile.displayName, user.profile.id, cell.original, cell.index)}>
            <i className={toggleIcon(
              cell.original.upvoted_by || '',
              user.profile.displayName, user.profile.id,
            )}
            />
          </button>
        </div>
      );
    }},
  {
    Header: 'Assigned To',
    accessor: 'assigned_to',
    maxWidth: 100,
  }, {
    Header: 'Created By',
    accessor: 'created_by',
    maxWidth: 100,
  },
  { Header: 'Created On',
    accessor: 'created_at',
    maxWidth: 130,
    filterMethod: filterDatesShort,
    Cell: function createdCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value ? formatDate(cell.value, DateTime.DATE_SHORT) : '--'}</span>
        </div>
      );
    }},
  { Header: 'Actions',
    filterable: false,
    sortable: false,
    resizable: false,
    maxWidth: 80,
    Cell: function actionCell(cell) {
      return (
        <div className="has-text-centered">
          <button
            className="icon button-like-link"
            type="button"
            title="Edit"
            onClick={evt => editItemFunc(cell.original, evt)}>
            <i className="fa fa-pencil-alt" />
          </button>
          {renderIfAdmin(
            <button
              className="icon has-text-danger mls button-no-bg"
              type="button"
              title="Delete"
              onClick={() => {
                setShowDeleteDialog(true);
                setDeleteItem(cell.original);
              }}>
              <i className="fa fa-trash" />
            </button>,
            user,
          )}
        </div>
      );
    }}];

  return (
    <div className="documents-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      {
        showDeleteDialog
        && (
          <ConfirmDeleteDialog
            entity={deleteItem}
            action={deleteItemFunc}
            message={`Are you sure you want to delete "${deleteItem.item}?"`}
            closeDialogAction={() => {
              setShowDeleteDialog(false);
              setDeleteItem(null);
            }}
          />
        )
      }
      { editItem
        ? (
          <EditEntryModal
            key={editItem.entry_id}
            title="Edit Entry"
            confirmText="Update"
            toggled={modalOpen}
            entity={editItem}
            closeAction={() => setModalOpen(false)}
            okAction={saveEntry}
            user={user}
          />
        )
        : (
          <EditEntryModal
            title="Create Entry"
            confirmText="Add"
            toggled={modalOpen}
            closeAction={() => setModalOpen(false)}
            okAction={saveEntry}
            user={user}
          />
        )}

      <div className="is-flex is-fullwidth">
        <h2 className="page-title" style={{ flex: 1 }}>How To Get Better</h2>
        <button className="button elev-1" type="submit" onClick={addDocumentDialog}>
          <span>Add</span>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
        </button>
      </div>

      {h2gbs === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          className="-striped -highlight"
          data={h2gbs}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultSorted={[{id: 'created_at', desc: true}]}
          defaultFilterMethod={filterAsString}
          showPagination={h2gbs.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default HowToGetBetterPage;
