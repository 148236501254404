import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { useSettings } from '@hooks/settings';
import {
  fetchRecentChanges,
  updateUserSettings,
  fetchChangeAfter,
  fetchChangeBefore,
} from '@actions/settings';
import { IUser } from '@models/User';

import ContentModal from '../ContentModal';
import ChangelogItem from './ChangelogItem';
import LoadingSpinner from '../LoadingSpinner';

import { ChangeInformation, VersionInformation } from '../../../../../app/utils/changelog';
import { UserSettings } from '../../../../../app/models/settings';

interface IProps {
  user: IUser;
  showing: boolean;
  setShowing: (show: boolean) => void;
  setNewVersion: (vers: boolean) => void;
}

const WhatsNew: React.FC<IProps> = ({user, showing, setShowing, setNewVersion}: IProps) => {
  const [whatsNew, setWhatsNew] = useState<VersionInformation>(null);
  const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>(true);
  const { data: settings } = useSettings(user.profile.id, true, {
    refreshInterval: 60000,
    revalidateOnFocus: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });

  useEffect(() => {
    if (whatsNew === null && settings) {
      (async () => {
        setCheckBoxChecked(!settings.show_whats_new);
        // Fetch changes
        fetchRecentChanges().then(changes => {
          setWhatsNew(changes);
          if (settings.last_version < changes.date) {
            setNewVersion(true);
            if (settings.show_whats_new) setShowing(true);
          }
        });
      })();
    }
  }, [settings, whatsNew]);

  useEffect(() => {
    const updatedTime: UserSettings = {
      uuid: user.profile.id,
      show_whats_new: null,
      last_version: new Date(),
    };
    updateUserSettings(updatedTime);
  }, [showing]);

  return (
    <div>
      {showing && (
        <ContentModal closeDialogAction={() => setShowing(false)}>
          <div className="modal-card">
            <header className="modal-card-head">
              <h3 className="modal-card-title">What&apos;s New{whatsNew && ` in ${whatsNew.version}`}?</h3>
              <button className="delete" type="button" aria-label="close" onClick={() => setShowing(false)} />
            </header>
            <section className="modal-card-body markdown-card">
              {whatsNew ? whatsNew.changes.map((change: ChangeInformation) => (
                <ChangelogItem change={change} key={change.title} />
              )) : <LoadingSpinner />}
              <div className="level mtm">
                <div className="level-left">
                  <div className="level-item">
                    <label className="show-again-checkbox" htmlFor="showAgainCheckbox">
                      <input
                        className="mrs"
                        id="showAgainCheckbox"
                        type="checkbox"
                        disabled={settings === null || settings.uuid === 'Error'}
                        checked={checkBoxChecked}
                        onChange={(event) => {
                          const newSettings: UserSettings = {
                            ...settings,
                            last_version: new Date(),
                            show_whats_new: !event.target.checked,
                          };
                          setCheckBoxChecked(event.target.checked);
                          updateUserSettings(newSettings);
                        }}
                      />
                      Don&apos;t show me again
                    </label>
                  </div>
                </div>
                <div>
                  <span className="icon">
                    <i className={`fa fa-${whatsNew.changeType === 'Feature' ? 'file-code' : 'bug'}`} />
                  </span>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button
                      type="button"
                      className="button"
                      disabled={shouldPreviousBeDisabled(whatsNew)}
                      onClick={() => {
                        const dateFormatted = DateTime.fromJSDate(
                          new Date(whatsNew.date),
                          { zone: 'UTC' },
                        ).toFormat('D');
                        fetchChangeBefore(dateFormatted).then(versionBefore => {
                          setWhatsNew(versionBefore);
                        });
                      }}>
                      <span>Previous</span>
                      <span className="icon">
                        <i className="fa fa-arrow-left" />
                      </span>
                    </button>
                    <button
                      type="button"
                      className="button mlm"
                      disabled={shouldNextBeDisabled(whatsNew)}
                      onClick={() => {
                        const dateFormatted = DateTime.fromJSDate(
                          new Date(whatsNew.date),
                          { zone: 'UTC' },
                        ).toFormat('D');
                        fetchChangeAfter(dateFormatted).then(versionAfter => {
                          setWhatsNew(versionAfter);
                        });
                      }}>
                      <span>Next</span>
                      <span className="icon"><i className="fa fa-arrow-right" /></span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <footer className="modified-modal-card-footer pbxs" />
          </div>
        </ContentModal>
      )}
    </div>
  );
};

function shouldPreviousBeDisabled(version: VersionInformation): boolean {
  if (version === null) return true;
  if (version.oldest === undefined) return false;
  return true;
}

function shouldNextBeDisabled(version: VersionInformation): boolean {
  if (version === null) return true;
  if (version.newest === undefined) return false;
  return true;
}

export default WhatsNew;
