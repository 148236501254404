import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { RMAQuery } from '../../../../../../app/models/rma';
import LoadingSpinner from '../../LoadingSpinner';
import * as RMAActions from '../../../../actions/rma';
import { useNavBarContext } from '../NavBar';

interface Props {
  search: string;
}

const RMAResults: React.FC<Props> = ({ search }: Props) => {
  const { hidePanel } = useNavBarContext();
  const [results, setResults] = useState<RMAQuery[] | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('rma', '').trim();
    if (!searchVal || searchVal.length === 0) return;
    RMAActions.search(searchVal).then(setResults);
  }, [search]);

  if (!results) return <LoadingSpinner />;
  if (results.length === 0) return <span className="mts">No matches found.</span>;
  return (
    <>
      {results.map((rma) => (
        <Link
          onClick={hidePanel}
          key={rma.rma_number}
          className="search-result"
          to={`/search/rma/${rma.rma_number}`}>
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">RMA #{rma.rma_number}</div>
            <div className="result-subtitle">{rma.customer} - {rma.site}</div>
          </div>
          <span className="result-timestamp">
            {DateTime.fromISO(rma.entry_date_time).toFormat('DD')}
          </span>
        </Link>
      ))}
    </>
  );
};

export default RMAResults;
