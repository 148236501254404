import React from 'react';

const LoadingSpinner: React.FC = () => (
  <div className="loader-wrapper mtm">
    <div className="loader is-loading" />
    <span>Loading...</span>
  </div>
);

export default LoadingSpinner;
