import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { renderIfEngOrOp, renderIfOpOrAccounting } from '@utils/permissions';
import { IUser } from '@models/User';

import { PartQuery } from '../../../../../app/models/parts';
import SKUResults from './result/SKUResults';
import RMAResults from './result/RMAResults';
import IPRResults from './result/IPRResults';
import NaviResults from './result/NaviResults';
import POResults from './result/POResults';
import IPOResults from './result/IPOResults';
import DeviceResults from './result/DeviceResults';

interface Props {
  search: string;
  partCodes: PartQuery[];
  user: IUser;
}

const SearchPanel = React.forwardRef<HTMLDivElement, Props>(({ search, partCodes, user }: Props, ref) => {
  const [type, setType] = useState<string | null>(null);
  const [matchedPart, setMatchedPart] = useState<string | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase();
    if (searchVal.match(/rma/g)) setType('rma');
    else if (searchVal.match(/ipr/g)) setType('ipr');
    else if (searchVal.match(/ipo/g)) setType('ipo');
    else if (searchVal.match(/po/g)) setType('po');
    else if (searchVal.match(/sku/g)) setType('sku');
    else if (searchVal.match(/navi/g)) setType('navi');
    else if (searchVal.match(/sn|s\/n|serial/g)) setType('serial');
    else {
      const searchNums = search.replace(/[^\d]/g, '');
      let partName = null;
      for (const pc of partCodes) {
        if (pc.serial_length === searchNums.length && searchNums.startsWith(pc.code)) {
          setType('serial');
          partName = pc.part_name;
          break;
        }
      }
      setMatchedPart(partName);
    }
  }, [search, partCodes]);

  const FilterButton = ({ label, filterType }: { label: string; filterType: string }) => (
    <button
      type="button"
      className={classNames('button', { selected: type === filterType })}
      onClick={() => (type === filterType ? setType(null) : setType(filterType))}>
      {label}
    </button>
  );

  return (
    <div className="search-panel" ref={ref}>
      <div className="search-buttons">
        <FilterButton label={matchedPart ? `Serial Number: ${matchedPart}` : 'Serial Number'} filterType="serial" />
        <FilterButton label="RMA" filterType="rma" />
        <FilterButton label="IPR" filterType="ipr" />
        <FilterButton label="IPO" filterType="ipo" />
        {renderIfOpOrAccounting(<FilterButton label="PO" filterType="po" />, user)}
        {renderIfEngOrOp(<FilterButton label="SKU" filterType="sku" />, user)}
        <FilterButton label="Navi" filterType="navi" />
      </div>

      {search && search.length > 0 && (
        <div className="search-results">
          {type === 'sku' && <SKUResults search={search} />}
          {type === 'rma' && <RMAResults search={search} />}
          {type === 'ipr' && <IPRResults search={search} />}
          {type === 'po' && <POResults search={search} />}
          {type === 'navi' && <NaviResults search={search} />}
          {type === 'ipo' && <IPOResults search={search} />}
          {type === 'serial' && <DeviceResults search={search} />}
        </div>
      )}
    </div>
  );
});

SearchPanel.displayName = 'SearchPanel';

export default SearchPanel;
