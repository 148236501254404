import http from './http';
import { InventoryCount } from '../../../app/models/views';
import { SKU } from '../../../app/models/inventory';

export async function fetch(): Promise<InventoryCount[]> {
  const {data} = await http.get('/inventory');
  return data;
}

export async function fetchSKUs(): Promise<SKU[]> {
  const {data} = await http.get('/inventory/skus');
  return data;
}

export async function fetchSKUs2(): Promise<SKU[]> {
  const {data} = await http.get('/inventory/skus2');
  return data;
}

export async function fetchSKUs3(): Promise<SKU[]> {
  const {data} = await http.get('/inventory/skus3');
  return data;
}

export async function fetchSKUs2File(guid: string): Promise<SKU[]> {
  const { data } = await http.get(`/inventory/skus2/file/${guid}`);
  return data;
}

export async function fetchUnserializedItemNameList(): Promise<{ unserialized_item_name: string }[]> {
  const { data } = await http.get('/inventory/unserializedItemList');
  return data;
}
