import React from 'react';
import { Link } from 'react-router-dom';

import { IPO } from '../../../../app/models/inventory';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  ipo: IPO[];
}

const IpoPage: React.FC<IProps> = ({ipo}: IProps) => {
  if (ipo === null) return <LoadingSpinner />;
  if (!ipo || !Array.isArray(ipo)) return <p> No ipos match that number </p>;
  return (
    <div className="po-page">
      <table className="table is-fullwidth po-table">
        <thead>
          <th>Serial Number</th>
          <th>Type</th>
          <th>Custom</th>
          <th>Notes</th>
        </thead>
        <tbody>
          {ipo.map(renderTableRow)}
        </tbody>
      </table>
    </div>
  );
};

function renderTableRow(ipo: IPO): JSX.Element {
  const {
    serial_number,
    part_number,
    custom,
    notes,
    ipo_number,
  } = ipo;

  return (
    <tr key={ipo_number}>
      <td><Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></td>
      <td>{part_number}</td>
      <td>{custom ? 'Yes':'No'}</td>
      <td className="message-body">{notes}</td>
    </tr>
  );
}

export default IpoPage;
