import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';

import InstallDetails from '@models/Device';

import Manufacturer from '../../../app/models/manufacturer';
import { PartQuery } from '../../../app/models/parts';
import { axiosFetcher } from './common';

export function useScrapReasons(
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<string[], AxiosError> {
  return useSWR<string[], AxiosError>(shouldFetch ? '/device/valid-scrap-reasons' : null, axiosFetcher, config);
}

export function useParts(
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<PartQuery[], AxiosError> {
  return useSWR<PartQuery[], AxiosError>(shouldFetch ? '/device/parts' : null, axiosFetcher, config);
}

export function useManufacturers(
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<Manufacturer[], AxiosError> {
  return useSWR<Manufacturer[], AxiosError>(shouldFetch ? '/device/manufacturers' : null, axiosFetcher, config);
}

export function useInstallDetails(
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<InstallDetails[], AxiosError> {
  return useSWR<InstallDetails[], AxiosError>(
    shouldFetch ? '/device_configurations/recent' : null,
    axiosFetcher,
    config,
  );
}
