/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { List } from 'immutable';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import Required from '@components/shared/Required';
import IPRItemList from '@components/ipr_entry/form/IPRItemList';
import ConfirmDeleteDialog from '@components/shared/ConfirmDeleteDialog';
import IPR, { IIPR } from '@models/IPR';
import IPRItem from '@models/IPRItem';
import { User, UserProfile } from '@models/User';

import { IPRState } from '../../../../../app/models/ipr_entries';

interface Props {
  initialData?: Partial<IPR>;
  onSubmit: (data: IPR) => void;
  user: User;
  creator: UserProfile;
}

const IPRForm: React.FC<Props> = ({ initialData, onSubmit, user, creator }: Props) => {
  const isCreator = creator.mail === user.profile.mail;

  const history = useHistory();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [products, setProducts] = useState(initialData?.products ?? List([
    new IPRItem({ id: null, product: 'TX550-01-VS', rev: '', qty: 1, advancedReplacement: false, userComments: '' }),
  ]));
  const {
    register, handleSubmit, watch, formState: { errors, isValid }, setValue,
  } = useForm({
    defaultValues: initialData?.toJS(),
    mode: 'onChange',
  });

  const isRma = watch('isRma');
  useEffect(() => {
    if (!isRma)
      setValue('rmaNumber', null);
  }, [isRma, setValue]);

  const doSubmit = (data: IIPR): void => {
    onSubmit(new IPR({
      ...(initialData?.toJS() ?? {}),
      ...data,
      createdBy: creator.mail,
      state: IPRState.SUBMITTED,
      products,
    }));
  };

  const saveForLater = (data: IIPR): void => {
    onSubmit(new IPR({
      ...(initialData?.toJS() ?? {}),
      ...data,
      createdBy: creator.mail,
      state: IPRState.UNSUBMITTED,
      products,
    }));
  };

  return (
    <form onSubmit={handleSubmit(doSubmit)} className="mtm">
      {/* Date Needed */}
      <div className="field">
        <label className="label">Date Needed<Required /></label>
        <div className="control">
          <input
            className={classNames('input', { 'is-danger': !!errors.dateNeeded })}
            type="date"
            min={getMinDateNeeded(initialData?.createdDatetime)}
            {...register('dateNeeded', { required: 'Required' })}
          />
          {errors.dateNeeded && <span className="has-text-danger">{errors.dateNeeded.message}</span> }
        </div>
      </div>

      {/* Part of RMA? & RMA Number */}
      <div className="field">
        <label className="label">Part of RMA?<Required /></label>
        <div className="control is-flex">
          <div className="select">
            <select
              {...register('isRma', { setValueAs: value => {
                if (typeof value === 'boolean')
                  return value;
                return value === 'true';
              }})}>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          {isRma && (
            <div className="mls">
              <input
                className={classNames('input', { 'is-danger': !!errors.rmaNumber })}
                type="text"
                placeholder="RMA Number"
                {...register('rmaNumber', { required: 'Required' })}
              />
              {errors.rmaNumber && <span className="has-text-danger">{errors.rmaNumber.message}</span> }
            </div>
          )}
        </div>
      </div>

      {/* Charge To */}
      <div className="field">
        <label className="label">Charge To<Required /></label>
        <div className="select">
          <select defaultValue={null} {...register('chargeTo', { required: true })}>
            <option value={null} disabled>-- Select One --</option>
            <option value="Sales & Marketing">Sales & Marketing</option>
            <option value="Engineering">Engineering</option>
            <option value="Ops/COGS">Ops/COGS</option>
          </select>
        </div>
      </div>

      {/* Custom Product */}
      <div className="field">
        <label className="label">Custom Product?</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="select">
            <select {...register('customProduct')}>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          {watch('customProduct') && (
            <span className="mls help-text">Please specify details in the <b>comments</b> below</span>
          )}
        </div>
      </div>

      {/* Contact Name */}
      <div className="field is-full">
        <label className="label">Contact Name</label>
        <div className="control">
          <input maxLength={50} className="input" type="text" {...register('contactName')} />
        </div>
      </div>

      {/* Customer */}
      <div className="field is-full">
        <label className="label">Customer / Venue<Required /></label>
        <div className="control">
          <input
            maxLength={50}
            className={classNames('input', { 'is-danger': !!errors.customer })}
            type="text"
            {...register('customer', { required: 'Required' })}
          />
        </div>
        {errors.customer && <span className="has-text-danger is-block">{errors.customer.message}</span> }
        <span className="help-text">Specify <b>&ldquo;Internal&rdquo;</b> if not shipping to a customer</span>
      </div>

      {/* Shipping Address */}
      <div className="field is-full">
        <label className="label">Shipping Info</label>
        <div className="control">
          <textarea className="textarea" {...register('shippingAddress')} />
        </div>
      </div>

      {/* Comments */}
      <div className="field is-full">
        <label className="label">Comments</label>
        <div className="control">
          <textarea className="textarea" {...register('comments')} />
        </div>
      </div>

      {/* Requested Products */}
      <h2>Products Requested</h2>
      <IPRItemList items={products} setItems={setProducts} isRma={isRma} />

      <div className="ipr-actions mtl is-fullwidth">
        {/* ROLE: Admin */}
        {/* Delete */}
        {(user.isAdmin || isCreator) && initialData?.iprNum && (
          <button
            type="button"
            className="button is-danger"
            onClick={() => setShowDeleteDialog(true)}>
            <i className="fa fa-trash prxs" />
            Delete
          </button>
        )}

        {/* Cancel */}
        {!initialData?.iprNum && (
          <button
            type="button"
            className="button is-white"
            onClick={() => history.replace('/iprs')}>
            <i className="fa fa-times prxs" />
            Cancel
          </button>
        )}
        <div style={{ flex: 1 }} />

        {isCreator && (
          <>
            <button className="button is-white" type="button" onClick={handleSubmit(saveForLater)}>
              <i className="fas fa-clock prxs" />
              Save for Later
            </button>
            <button className="button is-primary" type="submit" disabled={!isValid}>
              <i className="fa fa-check prxs" />
              Submit
            </button>
          </>
        )}
      </div>

      {showDeleteDialog && (
        <ConfirmDeleteDialog
          closeDialogAction={() => setShowDeleteDialog(false)}
          entity={initialData.iprNum}
          action={(iprNum) => IPR.delete(iprNum).then(() => history.replace('/iprs'))}
          message={(
            <span>Are you sure you want to delete <b>IPR #{initialData.iprNum}</b>?</span>
          )}
        />
      )}
    </form>
  );
};

function getMinDateNeeded(createdDate?: string): string {
  if (createdDate)
    return DateTime.fromISO(createdDate)/*.plus({ days: 1 })*/.toISODate();
  return DateTime.now()/*.plus({ days: 1 })*/.toISODate();
}

export default IPRForm;
