import React from 'react';
import classNames from 'classnames';

import { useSKUs } from '../../../../hooks/skus';
import LoadingSpinner from '../../LoadingSpinner';

interface Props {
  search: string;
}

const SKUResults: React.FC<Props> = ({ search }: Props) => {
  const searchVal = search.toLowerCase();
  const { data: skus } = useSKUs();

  if (!skus) return <LoadingSpinner />;
  const matchingSKUs = skus.filter((sku) => {
    if (sku.status !== 'In Production' && sku.status !== 'In Design') return false;
    return sku.sku.toLowerCase().includes(searchVal) || sku.name.toLowerCase().includes(searchVal);
  });
  if (matchingSKUs.length === 0) return <span className="mts">No matches found.</span>;

  return (
    <>
      {matchingSKUs.map((sku) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={sku.sku}
          className="search-result"
          onClick={async () => {
            // fetch file data for this item
            const resp = await fetch(`/inventory/skus2/file/${sku.guid}`);
            if (!resp.ok) return;

            // open the file in this window
            const fileData = await resp.arrayBuffer();
            const blob = new Blob([fileData], {type: 'application/pdf'});
            const objectUrl = URL.createObjectURL(blob);

            // create a link and simulate a click to open in a new tab
            const link = document.createElement('a');
            link.href = objectUrl;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            link.click();
            link.remove();
          }}>
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">{sku.sku.toUpperCase()}</div>
            <div className="result-subtitle">{sku.name}</div>
          </div>
          <span className={classNames('tag', { 'is-primary': sku.status === 'In Production' })}>
            {sku.status}
          </span>
        </div>
      ))}
    </>
  );
};

export default SKUResults;
