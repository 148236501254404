import { List, Record } from 'immutable';
import { DateTime } from 'luxon';

import http from '@actions/http';
import IPRItem, { IIPRItem } from '@models/IPRItem';

import { camelCaseKeys, snakeCaseKeys } from '@utils/format';
import { isPresent } from '@utils/common';

import { IPRState } from '../../../app/models/ipr_entries';

export interface IIPR {
  iprNum: number | null;
  state: IPRState;
  inventoryApprover: string | null;
  accountingApprover: string | null;
  inventoryDate: string | null;
  accountingDate: string | null;
  customProduct: boolean;
  isRma: boolean;
  rmaNumber: number | null;
  createdBy: string;
  createdDatetime: string;
  dateNeeded: string;
  comments: string | null;
  reviewerComments: string | null;
  shippingAddress: string | null;
  chargeTo: string | null;
  contactName: string | null;
  shipDate: string | null;
  invoiceDate: string | null;
  customer: string;
  products: List<IPRItem>;
}

export default class IPR extends Record<IIPR>({
  iprNum: null,
  state: IPRState.UNSUBMITTED,
  inventoryApprover: null,
  accountingApprover: null,
  inventoryDate: null,
  accountingDate: null,
  customProduct: false,
  createdBy: '',
  isRma: false,
  rmaNumber: null,
  createdDatetime: new Date().toISOString(),
  dateNeeded: formatForDateInput(new Date().toISOString()),
  comments: null,
  reviewerComments: null,
  shippingAddress: null,
  chargeTo: null,
  contactName: null,
  shipDate: null,
  invoiceDate: null,
  customer: '',
  products: List(),
}) implements IIPR {
  constructor(ipr: IIPR) {
    const newItems = ((ipr.products || []) as IIPRItem[]).map((item: IIPRItem) => new IPRItem(item));
    super({
      ...ipr,
      dateNeeded: formatForDateInput(ipr.dateNeeded),
      products: List(newItems),
    });
  }

  static async upsert(ipr: IPR): Promise<{ iprNum: number }> {
    const { data } = await http.post('/iprs', snakeCaseKeys(ipr.toJS()));
    return camelCaseKeys(data) as { iprNum: number };
  }

  static async delete(id: number): Promise<void> {
    await http.delete(`/iprs/${id}`);
  }

  /**
   * Unsubmits this IPR for further editing before submission
   */
  async unsubmit(): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/unsubmit`);
    this.merge(data);
    return this;
  }

  /**
   * Approves this IPR for inventory and updates any reviewer appropriate fields
   * @param ipr The updated IPR data that inventory reviewers are allowed to edit
   */
  async approveInventory(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/approveInventory`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  /**
   * Rejects this IPR for inventory and updates any reviewer appropriate fields
   * @param ipr The updated IPR data that inventory reviewers are allowed to edit
   */
  async rejectInventory(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/rejectInventory`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  async unapproveInventory(): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/unapproveInventory`);
    this.merge(data);
    return this;
  }

  /**
   * Approves this IPR for finance and updates any reviewer appropriate fields
   * @param ipr The updated IPR data that finance reviewers are allowed to edit
   */
  async approveFinance(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/approveFinance`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  /**
   * Rejects this IPR for finance and updates any reviewer appropriate fields
   * @param ipr The updated IPR data that finance reviewers are allowed to edit
   */
  async rejectFinance(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/rejectFinance`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  async unapproveFinance(): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/unapproveFinance`);
    this.merge(data);
    return this;
  }

  async ship(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/ship`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  async invoice(ipr: Partial<IIPR>): Promise<IPR> {
    const { data } = await http.post<IIPR>(`/iprs/${this.iprNum}/invoice`, snakeCaseKeys(ipr));
    this.merge(data);
    return this;
  }

  async updateData(data: Partial<IIPR>): Promise<void> {
    await http.post('/iprs', snakeCaseKeys({
      ...this.toJS(),
      ...data,
    }));
  }

  get isExpired(): boolean {
    const shippedDT = DateTime.fromISO(this.shipDate, { zone: 'UTC' });
    if (!shippedDT.isValid)
      return false;
    return shippedDT.toSeconds() < DateTime.now().minus({ days: 4 }).toSeconds();
  }

  get isUnsubmitted(): boolean {
    return this.state === IPRState.UNSUBMITTED;
  }

  get isSubmitted(): boolean {
    return this.state === IPRState.SUBMITTED;
  }

  get isRejected(): boolean {
    return this.state === IPRState.REJECTED;
  }

  get isPending(): boolean {
    return this.state === IPRState.PENDING;
  }

  get isApproved(): boolean {
    return this.state === IPRState.APPROVED;
  }

  get isShipped(): boolean {
    return this.state === IPRState.SHIPPED;
  }

  get isCompleted(): boolean {
    return this.state === IPRState.COMPLETED;
  }

  get isFinanceApproved(): boolean {
    return isPresent(this.accountingApprover);
  }

  get isInventoryApproved(): boolean {
    return isPresent(this.inventoryApprover);
  }

  get isPersisted(): boolean {
    return this.iprNum !== null;
  }
}

function formatForDateInput(date?: string): string {
  if (!date) return '';
  const dt = DateTime.fromISO(date, { zone: 'UTC' });
  if (!dt.isValid) return '';
  return dt.toFormat('yyyy-MM-dd');
}
