import React, { useState, useEffect } from 'react';
import ReactTable, { Column, Filter } from 'react-table';
import { Location, History } from 'history';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';

import EditCompatibilityModal from './EditCompatibilityModal';
import * as Compatibility from '../../actions/compatibility';
import { updateFilteredQueryString, filterAsString } from '../../utils/table_helpers';
import { CompatibilityInfo } from '../../../../app/models/compatibility';
import { formatDate } from '../../utils/format';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
}

const CompatibilityPage: React.FC<IProps> = ({location: { search }, history}: IProps) => {
  usePageTitle('Load Compatibility');
  const [compatibility, setCompatibility] = useState<CompatibilityInfo[] | null>(null);
  const [createModalToggled, setCreateModalToggled] = useState<boolean>(false);
  const [editModalToggled, setEditModalToggled] = useState<boolean>(false);
  const [editEntity, setEditEntity] = useState<CompatibilityInfo>(null);

  const createCompatibility = (comp: CompatibilityInfo): Promise<void> => Compatibility.add(comp)
    .then(Compatibility.fetch)
    .then(result => setCompatibility(result.data));

  const updateCompatibility = (comp: CompatibilityInfo): Promise<void> => Compatibility.update(comp)
    .then(Compatibility.fetch)
    .then(result => setCompatibility(result.data));

  useEffect(() => {
    if (compatibility === null) Compatibility.fetch().then(result => setCompatibility(result.data));
  }, []);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{ Header: 'Date Added',
    accessor: 'entry_date_time',
    maxWidth: 100,
    Cell: function entryCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{formatDate(cell.value, DateTime.DATE_MED)}</span>
        </div>
      );
    }},
  {
    Header: 'Manufacturer',
    accessor: 'device_manufacturer',
    Cell: function manufacturerCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 115,
  },
  {
    Header: 'Model',
    accessor: 'device_model',
    Cell: function modelCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 140,
  }, {
    Header: 'Model Details',
    accessor: 'device_model_details',
    Cell: function detailsCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 225,
  }, {
    Header: 'NTC Energy (J)',
    accessor: 'ntc_energy',
    Cell: function energyCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 125,
  }, {
    Header: 'Design Power (W)',
    accessor: 'design_wattage',
    Cell: function powerCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 160,
  }, {
    Header: 'Min # DE Pairs',
    accessor: 'min_number_of_input_pairs',
    Cell: function minPairs(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 125,
  }, {
    Header: 'Loads per RX Output',
    accessor: 'loads_per_rx_output',
    Cell: function loadsPer(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    minWidth: 160,
  }, {
    Header: 'Load Type',
    accessor: 'load_type',
    Cell: function loadCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 125,
  }, {
    Header: 'Test Report',
    accessor: 'test_report_link',
    Cell: function testReportCell(cell) {
      if (!cell || !cell.value) return null;
      return (
        <a
          href={cell.value}
          target="_new"
          style={{
            paddingLeft: '3px',
            paddingTop: '3px',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            display: 'block',
            fontSize: '1.6em',
          }}>
          <i className="fa fa-link" />
        </a>
      );
    },
    maxWidth: 100,
  }, {
    Header: 'Compatible',
    accessor: 'compatible',
    maxWidth: 95,
    Cell: function compatibleCell(cell) {
      const bgColor = compatibilityColor(cell.value);
      return (
        <div style={{paddingLeft: '3px', backgroundColor: bgColor, whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span style={{color: 'white'}}>{cell.value}</span>
        </div>
      );
    },
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterComp({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">All</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
          <option value="limitations">Limitations</option>
          <option value="TBD">TBD</option>
        </select>
      );
    },
  }, {
    Header: 'Tested Receiver',
    accessor: 'tested_receiver',
    Cell: function testedReceivCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 128,
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterTested({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">All</option>
          <option value="prx440">PRX440</option>
          <option value="rx550">RX550</option>
          <option value="prx482">PRX482</option>
          <option value="prx482att">PRX482 ATT</option>
          <option value="rx548">RX548</option>
          <option value="rx520h">RX520H</option>
          <option value="rx554">RX554</option>
          <option value="rx542k">RX54-2K</option>
          <option value="rxac2m">RXAC-2M</option>
          <option value="other">Other</option>
        </select>
      );
    },
  }, {
    Header: 'Test Method',
    accessor: 'test_method',
    Cell: function testMethod(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 135,
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterTestMethod({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">All</option>
          <option value="Field Test">Field Test</option>
          <option value="Eng Simple Test">Eng Simple Test</option>
          <option value="Eng Full Test">Eng Full Test</option>
          <option value="Deduction">Deduction</option>
        </select>
      );
    },
  }, {
    Header: 'Added By',
    accessor: 'created_by',
    Cell: function addedBy(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 115,
  }, {
    Header: 'Comments',
    accessor: 'comments',
    Cell: function commentsCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
  }, {
    Header: 'Edit',
    accessor: 'id',
    Cell: function editCell(cell) {
      return (
        <button
          className="button-like-link"
          type="button"
          style={{paddingLeft: '3px', whiteSpace: 'pre-wrap', textAlign: 'center', display: 'block'}}
          onClick={() => {
            setEditModalToggled(true);
            setEditEntity(cell.original);
          }}>
          <i className="fa fa-pencil-alt" />
        </button>
      );
    },
    maxWidth: 50,
    Filter: () => null,
  }];

  return (
    <div className="compatibility-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <EditCompatibilityModal
        toggled={createModalToggled}
        closeAction={() => setCreateModalToggled(false)}
        title="Add Compatibility"
        confirmText="Add (Cannot be removed)"
        okAction={createCompatibility}
      />
      { editEntity && (
        <EditCompatibilityModal
          key={editEntity.entry_id}
          toggled={editModalToggled}
          closeAction={() => {
            setEditModalToggled(false);
            setEditEntity(null);
          }}
          entity={editEntity}
          title="Edit Compatibility"
          confirmText="Update"
          okAction={updateCompatibility}
        />
      )}
      <div className="is-flex is-fullwidth">
        <h1 className="page-title" style={{ flex: 1 }}>Compatibility</h1>
        <button
          className="button elev-1 mrs"
          type="submit"
          onClick={() => {
            setCreateModalToggled(true);
            setEditEntity(null);
          }}>
          <span>
            Add Compatibility Record
          </span>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
        </button>
        <a
          href={`/reports/compatibility.xlsx?${filtered.map(filter => `${filter.id}=${filter.value}`).join('&')}`}
          className="button is-link elev-1"
          download>
          <span className="icon">
            <i className="fa fa-download" />
          </span>
          <span>Download filtered as Excel</span>
        </a>
      </div>
      {compatibility === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={compatibility}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultSorted={[{id: 'entry_date_time', desc: true}]}
          defaultFilterMethod={filterAsString}
          showPagination={compatibility.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

function compatibilityColor(value: string): string {
  switch (value) {
    case 'yes':
      return 'hsl(141, 71%, 48%)';
    case 'no':
      return 'hsl(348, 100%, 61%)';
    case 'limitations':
      return 'hsl(48,  100%, 67%)';
    default:
      return 'hsl(0, 0%, 68%)';
  }
}

export default CompatibilityPage;
