import { Record } from 'immutable';

export interface IIPRItem {
  id: number | null;
  product: string;
  rev: string;
  qty: number;
  advancedReplacement: boolean;
  userComments: string | null;
}

export default class IPRItem extends Record<IIPRItem>({
  id: null,
  product: '',
  rev: '',
  qty: 0,
  advancedReplacement: false,
  userComments: null,
}) implements IIPRItem {}
