import {
  UnitNote, RmaShipment, IprShipment,
  RmaItem, PoShipment, Receiving,
  CmShipment, IPO, Analysis,
  MrbShipment, MrbReceiving, Scrap,
  RmaReceiving, Shipment,
} from '../models/inventory';
import { DeviceInstallDetails } from '../models/device_configurations';
import {
  HiPotTestData, Vsh500TestData, PcxAndPtxTestData, RX542KTestData,
  ProductionTestData, ProgrammingTestData, PM500TestData, MGT100TestData, ContinuityTestData,
} from '../models/test_data';

export enum EVENT_TYPES {
  HIGH_POT = 'HIGH_POT',
  PRODUCTION_TEST = 'PRODUCTION_TEST',
  PM500_PRODUCTION_TEST = 'PM500_PRODUCTION_TEST',
  MGT100_PRODUCTION_TEST = 'MGT100_PRODUCTION_TEST',
  VSH_PRODUCTION_TEST = 'VSH_PRODUCTION_TEST',
  PCX_OR_PTX_PRODUCTION_TEST = 'PCX_OR_PTX_PRODUCTION_TEST',
  INVENTORY_RECEIVE = 'INVENTORY_RECEIVE',
  RMA_RECEIVE = 'RMA_RECEIVE',
  RMA_SHIPMENT = 'RMA_SHIPMENT',
  RMA_ANALYSIS = 'RMA_ANALYSIS',
  PROGRAMMING = 'PROGRAMMING',
  SCRAP = 'SCRAP',
  MRB = 'MRB',
  MRB_ANALYSIS = 'MRB_ANALYSIS',
  UNIT_NOTES = 'UNIT_NOTES',
  IPO = 'IPO', // eslint-disable-line no-shadow
  PO_SHIPMENT = 'PO_SHIPMENT',
  IPR_SHIPMENT = 'IPR_SHIPMENT',
  MRB_SHIPMENT = 'MRB_SHIPMENT',
  RMA_ITEM = 'RMA_ITEM',
  DEVICE_INSTALL_DETAILS = 'DEVICE_INSTALL_DETAILS',
  CM_SHIPMENT = 'CM_SHIPMENT',
  RX54_2K_TEST = 'RX54_2K_TEST',
  CONTINUITY_TEST = 'CONTINUITY_TEST',
}

export type RecordTypes = ProductionTestData | ProgrammingTestData |
  HiPotTestData | PcxAndPtxTestData | Vsh500TestData | RmaReceiving |
  Scrap | MrbShipment | MrbReceiving | Analysis | UnitNote | IPO |
  Receiving | PoShipment | RmaShipment | IprShipment | RmaItem |
  PM500TestData | MGT100TestData | DeviceInstallDetails | CmShipment |
  RX542KTestData | ContinuityTestData;

export class EventRecord<T extends RecordTypes> {
  type: EVENT_TYPES;
  date_time: Date;
  data: T;

  constructor(type: EVENT_TYPES, data: T) {
    this.type = type;
    this.data = this.normalizeData(data) as T;
    this.date_time = this.findDateTime();
  }

  private normalizeData(data: T): RecordTypes {
    if (this.type === EVENT_TYPES.MGT100_PRODUCTION_TEST) {
      const dataTyped = data as MGT100TestData;
      const dataProd = data as ProductionTestData;
      dataProd.serial_number = dataTyped.SerialNumber.toString();
      dataProd.result = !!dataTyped.Result;
      dataProd.key_date_time = dataTyped.KeyDateTime;
      return dataProd;
    }
    return data;
  }

  private findDateTime(): Date {
    switch (this.type) {
      // Production Test Data
      case EVENT_TYPES.PM500_PRODUCTION_TEST:
      case EVENT_TYPES.PRODUCTION_TEST: {
        const dateTyped = this.data as ProductionTestData;
        return dateTyped.key_date_time || dateTyped.test_date_time || dateTyped.entry_date_time;
      }
      case EVENT_TYPES.PROGRAMMING:
        return (this.data as ProgrammingTestData).key_date_time;
      case EVENT_TYPES.MGT100_PRODUCTION_TEST:
        return (this.data as MGT100TestData).KeyDateTime;

      // Receiving
      case EVENT_TYPES.RMA_RECEIVE: {
        const dataTyped = this.data as RmaReceiving;
        return dataTyped.receive_date || dataTyped.entry_date_time;
      }
      case EVENT_TYPES.MRB:
        return (this.data as MrbReceiving).receive_date;
      case EVENT_TYPES.INVENTORY_RECEIVE: {
        const dataTyped = this.data as Receiving;
        return dataTyped.receive_date || dataTyped.entry_date_time;
      }

      case EVENT_TYPES.RMA_ITEM:
        return (this.data as RmaItem).entry_date_time;
      case EVENT_TYPES.DEVICE_INSTALL_DETAILS:
        return (this.data as DeviceInstallDetails).entry_time;

      // Shipments
      case EVENT_TYPES.PO_SHIPMENT:
        return (this.data as Shipment).shipment_date;
      case EVENT_TYPES.MRB_SHIPMENT:
        return (this.data as MrbShipment).shipment_date;
      case EVENT_TYPES.CM_SHIPMENT:
        return (this.data as CmShipment).shipment_date;

      case EVENT_TYPES.PCX_OR_PTX_PRODUCTION_TEST:
      case EVENT_TYPES.VSH_PRODUCTION_TEST:
      case EVENT_TYPES.SCRAP:
      case EVENT_TYPES.MRB_ANALYSIS:
      case EVENT_TYPES.RMA_ANALYSIS:
        return (this.data as Analysis).entry_date_time;
      case EVENT_TYPES.UNIT_NOTES:
      case EVENT_TYPES.IPO:
      case EVENT_TYPES.HIGH_POT:
      case EVENT_TYPES.CONTINUITY_TEST:
      default:
        return (this.data as any).entry_date_time;
    }
  }
}
