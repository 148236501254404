import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {AxiosError, AxiosResponse} from 'axios';

import http from '@actions/http';

import { UserSettings } from '../../../app/models/settings';

const defaultSettingsValue = (uuid: string): UserSettings => ({
  uuid,
  show_whats_new: false,
  last_version: new Date(),
});

const fetcher = async (dest: string, uuid: string): Promise<UserSettings> => {
  try {
    const resp: AxiosResponse<UserSettings> = await http.get(dest);
    if (resp.status <= 400) return resp.data as UserSettings;
    return defaultSettingsValue(uuid);
  }
  catch (ex) {
    return defaultSettingsValue(uuid);
  }
};

const url = (uuid: string): string => `/settings/${uuid}`;

export function useSettings(
  uuid: string,
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<UserSettings, AxiosError> {
  return useSWR<UserSettings, AxiosError>(
    shouldFetch ? url(uuid) : null,
    (dest: string) => fetcher(dest, uuid),
    config,
  );
}
