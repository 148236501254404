import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import { UnitNote } from '../../../../app/models/inventory';

const UnitNotesRecord: React.FC<UnitNote> = ({
  serial_number,
  notes,
  entry_date_time,
  user_id,
}: UnitNote) => {
  if (!serial_number) return null;
  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>A note was added by {user_id || 'Unknown'} to <Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></p>
        </div>
        <div className="message-body">
          {notes}
        </div>
      </article>
    </div>
  );
};

export default UnitNotesRecord;
