import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { ChangeInformation } from '../../../../../app/utils/changelog';

interface IProps {
  key: string;
  change: ChangeInformation;
}

const ChangelogItem: React.FC<IProps> = ({
  key,
  change,
}: IProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div className="box" key={key}>
      <h2 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0 }}>
        <span className="icon is-medium mrs" style={{ textAlign: 'right' }}>
          <i className={`fa ${change.icon}`} />
        </span>
        {change.title}
      </h2>
      <ReactMarkdown source={change.shortDescription} />
      {expanded && <ReactMarkdown source={change.longDescription} /> }
      <div className="buttons is-right">
        <button
          className="button"
          type="button"
          onClick={() => setExpanded(!expanded)}>
          <span>Show {expanded ? 'Less' : 'More'}</span>
          <span className="icon">
            <i className={`fa fa-angle-${expanded ? 'up' : 'down'}`} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ChangelogItem;
