import React, { useState, useEffect } from 'react';
import ReactTable, { Column, Filter } from 'react-table';
import { Location, History } from 'history';
import { isWebUri } from 'valid-url';
import ReactMarkdown from 'react-markdown';
import { DateTime } from 'luxon';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { usePageTitle } from '@hooks/common';
import {
  fetchAll,
  create,
  update,
  destroy,
} from '@actions/document';
import { renderIfAdmin } from '@utils/permissions';
import { updateFilteredQueryString, filterDatesMed, filterAsString } from '@utils/table_helpers';
import { IUser } from '@models/User';
import { formatDate } from '@utils/format';

import { IDocument } from '../../../../app/models/document';
import ContentModal from '../shared/ContentModal';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import EditDocumentModal from './EditDocumentModal';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
  user: IUser;
}

const DocumentsPage: React.FC<IProps> = ({location: { search }, history, user}: IProps) => {
  usePageTitle('Navi');

  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IDocument>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<IDocument>(null);
  const [showPopupText, setShowPopupText] = useState<boolean>(false);
  const [popupText, setPopupText] = useState<string>('');
  const [linkCopied, setLinkCopied] = useState<number | null>(null);

  const showPopupTextFunc = (text: string): void => {
    setShowPopupText(true);
    setPopupText(text);
  };

  const addDocumentDialog = (): void => {
    setModalOpen(true);
    setEditItem(null);
  };

  const editItemFunc = (editingItem: IDocument, evt: React.MouseEvent): void => {
    setModalOpen(true);
    setEditItem(editingItem);
    evt && evt.preventDefault();
  };

  const deleteItemFunc = async (document: IDocument): Promise<void> => {
    await destroy(document);
    setShowDeleteDialog(false);
    setDeleteItem(null);
    const docs = await fetchAll();
    setDocuments(docs);
  };

  const saveDocument = async (doc: IDocument): Promise<void> => {
    await (doc.id ? update(doc) : create(doc));
    const docs = await fetchAll();
    setDocuments(docs);
  };

  useEffect(() => {
    if (documents === null) fetchAll().then(docs => setDocuments(docs));
  }, []);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{
    Header: 'Title',
    accessor: 'title',
    className: 'itemTitle',
    Cell: function titleCell({original}) {
      if (isWebUri(original.link)) return <a href={original.link} target="_new">{ original.title }</a>;
      return (
        <button
          className="button-like-link"
          type="button"
          style={{paddingLeft: 0}}
          onClick={() => showPopupTextFunc(original.link)}>
          { original.title }
        </button>
      );
    },
  }, {
    Header: 'Class',
    accessor: 'class',
    maxWidth: 100,
  }, {
    Header: 'Type',
    accessor: 'type',
    maxWidth: 150,
  }, {
    Header: 'Tag',
    accessor: 'tag',
    maxWidth: 100,
  }, {
    Header: 'Department',
    accessor: 'department',
    maxWidth: 175,
    Cell: function departmentCell(cell) {
      return (<span>{cell.value.join(', ')}</span>);
    },
  }, {
    Header: 'Audience',
    accessor: 'audience',
    maxWidth: 90,
    resizable: false,
  }, {
    Header: 'Last Updated By',
    accessor: 'last_updated_by',
    maxWidth: 150,
  }, {
    Header: 'Last Updated Date',
    accessor: 'last_updated_date',
    maxWidth: 150,
    Cell: function updatedCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value ? formatDate(cell.value, DateTime.DATE_MED) : '--'}</span>
        </div>
      );
    },
    filterMethod: filterDatesMed,
  }, {
    Header: 'Actions',
    filterable: false,
    sortable: false,
    resizable: false,
    maxWidth: 80,
    Cell: function actionsCell({original}) {
      return (
        <div className="has-text-centered">
          <CopyToClipboard
            onCopy={() => {
              setLinkCopied(original.id);
              setTimeout(() => setLinkCopied(null), 2000);
            }}
            text={`${window.location.host}/navi/${original.id}`}>
            <button
              className="button-like-link icon"
              type="button"
              title="Copy Link">
              <i className={`fa ${linkCopied === original.id ? 'fa-check' : 'fa-link'}`} />
            </button>
          </CopyToClipboard>
          <button
            className="button-like-link icon"
            type="button"
            title="Edit"
            onClick={evt => editItemFunc(original, evt)}>
            <i className="fa fa-pencil-alt" />
          </button>
          {renderIfAdmin(
            <button
              className="icon has-text-danger button-no-bg"
              type="button"
              title="Delete"
              onClick={() => {
                setShowDeleteDialog(true);
                setDeleteItem(original);
              }}>
              <i className="fa fa-trash" />
            </button>,
            user,
          )}
        </div>
      );
    },
  }];

  return (
    <div className="documents-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      {
        showDeleteDialog
        && (
          <ConfirmDeleteDialog
            entity={deleteItem}
            action={deleteItemFunc}
            message={`Are you sure you want to delete "${deleteItem.title}?"`}
            closeDialogAction={() => {
              setShowDeleteDialog(false);
              setDeleteItem(null);
            }}
          />
        )
      }
      { editItem
        ? (
          <EditDocumentModal
            key={editItem.id}
            title="Edit Document"
            confirmText="Update"
            toggled={modalOpen}
            entity={editItem}
            closeAction={() => setModalOpen(false)}
            okAction={saveDocument}
            user={user}
          />
        )
        : (
          <EditDocumentModal
            title="Create Document"
            confirmText="Add"
            toggled={modalOpen}
            closeAction={() => setModalOpen(false)}
            okAction={saveDocument}
            user={user}
          />
        )}

      {showPopupText
      && (
        <ContentModal closeDialogAction={() => setShowPopupText(false)}>
          <div className="card markdown-card">
            <div className="card-content">
              <ReactMarkdown source={popupText} />
            </div>
          </div>
        </ContentModal>
      )}

      <div className="is-flex is-fullwidth">
        <h2 className="page-title" style={{ flex: 1 }}>Navi</h2>
        <button className="button elev-1" type="submit" onClick={addDocumentDialog}>
          <span>Add Document</span>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
        </button>
      </div>

      {documents === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          className="-striped -highlight"
          data={documents}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultFilterMethod={filterAsString}
          showPagination={documents.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default DocumentsPage;
