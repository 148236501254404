import http from './http';
import { IPO } from '../../../app/models/inventory';
import { IPOQuery } from '../../../app/lib/ipo';

async function fetchIPOs(serial_number: string): Promise<IPO[]> {
  const {data} = await http.get(`/ipo/${serial_number}`);
  return data;
}

export async function search(query: string): Promise<IPOQuery[]> {
  const { data } = await http.get(`/ipo/search/${encodeURIComponent(query)}`);
  return data;
}

export default fetchIPOs;
