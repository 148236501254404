import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { User } from '@models/User';
import IPR from '@models/IPR';
import ConfirmDeleteDialog from '@components/shared/ConfirmDeleteDialog';

interface Props {
  ipr: IPR;
  user: User;
  refresh: () => Promise<boolean>;
  onEdit: () => void;
}

const IPRActions: React.FC<Props> = ({ ipr, user, refresh, onEdit }: Props) => {
  const [shipDate, setShipDate] = useState(ipr.shipDate
    ? DateTime.fromISO(ipr.shipDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd'));
  const [showShipDropdown, setShowShipDropdown] = useState(false);

  const [invoiceDate, setInvoiceDate] = useState(ipr.invoiceDate
    ? DateTime.fromISO(ipr.invoiceDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd'));
  const [showInvoiceDropdown, setShowInvoiceDropdown] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const isCreator = ipr.iprNum === null || ipr.createdBy === user.profile.mail;
  const history = useHistory();
  return (
    <div className="ipr-actions">
      {showDeleteDialog && (
        <ConfirmDeleteDialog
          closeDialogAction={() => setShowDeleteDialog(false)}
          entity={ipr.iprNum}
          action={(iprNum) => IPR.delete(iprNum).then(() => history.replace('/iprs'))}
          message={(
            <span>Are you sure you want to delete <b>IPR #{ipr.iprNum}</b>?</span>
          )}
        />
      )}

      {/* ROLE: Admin */}
      {/* Delete */}
      {(user.isAdmin || (isCreator && ipr.isUnsubmitted && ipr.isPersisted)) && (
        <button
          type="button"
          className="button is-danger"
          onClick={() => setShowDeleteDialog(true)}>
          <i className="fa fa-trash prxs" />
          Delete
        </button>
      )}

      {/* ROLE: Finance/Inventory Approver */}
      {/* Make Changes */}
      {(ipr.isSubmitted || ipr.isPending) && ((user.isIPRFinanceApprover && !ipr.isFinanceApproved)
        || (user.isIPRInventoryApprover && !ipr.isInventoryApproved)) && (
        <button
          type="button"
          className="button is-info"
          onClick={onEdit}>
          <i className="fa fa-pencil-alt prxs" />
          Make Changes
        </button>
      )}

      <div style={{ flex: 1 }} />

      {/* ROLE: Creator */}
      {/* Undo Submit */}
      {isCreator && (ipr.isSubmitted || ipr.isPending) && (
        <button
          type="button"
          className="button is-info"
          onClick={() => ipr.unsubmit().then(refresh)}>
          <i className="fa fa-undo prxs" />
          Undo Submit
        </button>
      )}

      {/* ROLE: Finance Approver */}
      {user.isIPRFinanceApprover && (
        <>
          {/* Approve/Reject F&A */}
          {(ipr.isSubmitted || (ipr.isPending && !ipr.isFinanceApproved)) && (
            <>
              <button
                type="button"
                className="button is-danger"
                onClick={() => ipr.rejectFinance(ipr.toJS() as IPR).then(refresh)}>
                <i className="fa fa-times prxs" />
                Reject F&A
              </button>
              <button
                type="button"
                className="button is-primary"
                onClick={() => ipr.approveFinance(ipr.toJS() as IPR).then(refresh)}>
                <i className="fa fa-check prxs" />
                Approve F&A
              </button>
            </>
          )}

          {/* Undo F&A Approval */}
          {(ipr.isApproved || (ipr.isPending && ipr.isFinanceApproved)) && (
            <button
              type="button"
              className="button is-info"
              onClick={() => ipr.unapproveFinance().then(refresh)}>
              <i className="fa fa-undo prxs" />
              Undo F&A Approval
            </button>
          )}
        </>
      )}

      {/* ROLE: Inventory Approver */}
      {user.isIPRInventoryApprover && (
        <>
          {/* Approve/Reject Inventory */}
          {(ipr.isSubmitted || (ipr.isPending && !ipr.isInventoryApproved)) && (
            <>
              <button
                type="button"
                className="button is-danger"
                onClick={() => ipr.rejectInventory(ipr.toJS() as IPR).then(refresh)}>
                <i className="fa fa-times prxs" />
                Reject Inventory
              </button>
              <button
                type="button"
                className="button is-primary"
                onClick={() => ipr.approveInventory(ipr.toJS() as IPR).then(refresh)}>
                <i className="fa fa-check prxs" />
                Approve Inventory
              </button>
            </>
          )}

          {/* Undo Inventory Approval */}
          {(ipr.isApproved || (ipr.isPending && ipr.isInventoryApproved)) && (
            <button
              type="button"
              className="button is-info"
              onClick={() => ipr.unapproveInventory().then(refresh)}>
              <i className="fa fa-undo prxs" />
              Undo Inventory Approval
            </button>
          )}

          {/* Mark as Shipped / Update Ship Date */}
          {(ipr.isApproved || ipr.isShipped) && (
            <div className={classNames('dropdown mls', { 'is-active': showShipDropdown })}>
              <div className="dropdown-trigger">
                <button
                  type="button"
                  className="button is-primary"
                  aria-haspopup="true"
                  onClick={() => setShowShipDropdown(!showShipDropdown)}
                  aria-controls="dropdown-menu">
                  <i className={classNames('fa prxs', ipr.isShipped ? 'fa-truck-loading' : 'fa-shipping-fast')} />
                  {ipr.isShipped ? 'Update Ship Date' : 'Mark as Shipped'}
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content phs is-flex">
                  <input
                    type="date"
                    className="input"
                    value={shipDate}
                    onChange={(e) => setShipDate(e.target.value)}
                    max={DateTime.now().plus({ days: 1 }).toISODate()}
                    min={DateTime.now().minus({ days: 30 }).toISODate()}
                  />
                  <button
                    type="button"
                    onClick={() => ipr.ship(ipr.set('shipDate', shipDate).toJS() as IPR)
                      .then(refresh)
                      .then(() => setShowShipDropdown(false))}
                    className="button is-info no-shadow">
                    {ipr.isShipped ? 'Update' : 'Ship'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* ROLE: Executor */}
      {/* Invoice this IPR */}
      {user.isIPRExecutor && ipr.isShipped && (
        <div className={classNames('dropdown mls', { 'is-active': showInvoiceDropdown })}>
          <div className="dropdown-trigger">
            <button
              type="button"
              className="button is-info"
              aria-haspopup="true"
              onClick={() => setShowInvoiceDropdown(!showInvoiceDropdown)}
              aria-controls="dropdown-menu">
              <i className="fa fa-receipt prxs" />
              Invoice this IPR
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content phs is-flex">
              <input
                type="date"
                className="input"
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
                min={DateTime.fromISO(ipr.createdDatetime).toISODate()}
                max={DateTime.now().plus({ days: 1 }).toISODate()}
              />
              <button
                type="button"
                onClick={() => ipr.invoice(ipr.set('invoiceDate', invoiceDate).toJS() as IPR)
                  .then(refresh)
                  .then(() => setShowInvoiceDropdown(false))}
                className="button is-info no-shadow">
                Invoice
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IPRActions;
