import React, { useState } from 'react';

import ContentModal from '@components/shared/ContentModal';
import { scrapPart } from '@actions/device';
import { useScrapReasons } from '@hooks/device';

interface IProps {
  serial_number: string;
}

const defaultReason = '~~ Select Scrap Reason ~~';

const ScrapModal: React.FC<IProps> = ({ serial_number }: IProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(defaultReason);
  const [notes, setNotes] = useState<string>('');

  const { data: scrapReasons } = useScrapReasons();

  const scrapItem = (): void => {
    scrapPart(serial_number, reason, notes).then(() => setShow(false));
  };

  const reasons = scrapReasons ? [defaultReason].concat(scrapReasons) : [defaultReason];
  return (
    <>
      <button
        className="button is-danger mtm mrm"
        type="button"
        onClick={() => setShow(true)}>
        <span className="icon"><i className="fas fa-dumpster-fire" /></span>
        <span>Scrap Device</span>
      </button>

      {show && (
        <ContentModal closeDialogAction={() => setShow(false)}>
          <div className="modal-card">
            <header className="modal-card-head is-danger">
              <p className="modal-card-title" style={{ marginBottom: 0 }}>
                <i className="fa fa-lg fa-exclamation-triangle mrs" />
                <span>Scrap Item #{serial_number}?</span>
              </p>
              <button className="delete" type="button" aria-label="close" onClick={() => setShow(false)} />
            </header>
            <section className="modal-card-body">

              {/* Scrap Reason */}

              <div className="field is-full">
                <label className="label">Scrap Reason</label>
                <div className="control">
                  <select className="input" value={reason} onChange={(event) => setReason(event.target.value)}>
                    {reasons.map((sr: string) => <option value={sr} key={sr}>{sr}</option>)}
                  </select>
                </div>
              </div>

              {/* Scrap Notes */}

              <div className="field is-full">
                <label className="label">Scrap Notes</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </div>
              </div>

            </section>
            <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
              <button
                className="button is-danger"
                type="button"
                onClick={scrapItem}
                disabled={(reason === defaultReason) || notes === ''}>
                Scrap Item
              </button>
            </footer>
          </div>
        </ContentModal>
      )}
    </>
  );
};

export default ScrapModal;
