import axios from 'axios';

const DEFAULT_REQUEST_TIMEOUT = 10e3;

const http = axios.create({
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: {Accept: 'application/json'},
});

export default http;
