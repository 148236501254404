import React from 'react';

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<object, IState> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): IState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: object): void {
    console.warn(error, errorInfo); // eslint-disable-line no-console
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) return <h3>[Error loading component]</h3>;

    return children as JSX.Element;
  }
}

export default ErrorBoundary;
