import http from './http';
import Rma from '../../../app/lib/rma';
import { RmaSummaryView } from '../../../app/models/views';
import { RMAQuery, Contact } from '../../../app/models/rma';

export async function fetch(rma_number: string): Promise<Rma> {
  const {data} = await http.get(`/rma/${rma_number}`);
  return data;
}

export async function fetchSummary(): Promise<RmaSummaryView[]> {
  const {data} = await http.get('/rma/summary');
  return data;
}

export async function updateComments(rma: { comments: string; rma_number: string }): Promise<number> {
  const {status} = await http.put(`/rma/${rma.rma_number}/comments`, rma);
  return status;
}

/*export async function fetchSfLink(rmaNumber: string): Promise<string> {   //Function not currently used
  const { data } = await http.get(`/rma/${rmaNumber}/sfCaseLink`);
  return data.url;
}*/

export async function search(query: string): Promise<RMAQuery[]> {
  const { data } = await http.get(`/rma/search/${encodeURIComponent(query)}`);
  return data;
}

/*export async function fetchContactInfo(rmaNumber: string): Promise<Contact> {   //Function not currently used 
  const { data } = await http.get(`/rma/${rmaNumber}/sfContactInfo`);
  return data.contactInfo;
}*/
