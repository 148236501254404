import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../utils/format';
import { RmaReceiving } from '../../../../app/models/inventory';

const RmaRecord: React.FC<RmaReceiving> = ({
  serial_number,
  rma_number,
  entry_date_time,
  receive_date,
}: RmaReceiving) => {
  if (!serial_number) return null;
  const date_time = receive_date || entry_date_time;
  return (
    <div>
      <p className="heading">{formatDate(date_time)}</p>
      <p>Received RMA <Link to={`/search/rma/${rma_number}`}>#{rma_number}</Link></p>
    </div>
  );
};

export default RmaRecord;
