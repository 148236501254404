import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { User, UserProfile } from '@models/User';
import IPR, { IIPR } from '@models/IPR';
import IPRActions from '@components/ipr_entry/display/IPRActions';
import IPRStatus from '@components/ipr_entry/display/IPRStatus';
import IPRItemList from '@components/ipr_entry/form/IPRItemList';
import { isEmpty } from '@utils/common';

interface Props {
  user: User;
  creator: UserProfile;
  ipr: IPR;
  refresh: () => Promise<boolean>;
}

type Dirty = { [key in keyof IIPR]: boolean };

const IPRDisplay: React.FC<Props> = ({ user, creator, ipr: initialIPR, refresh }: Props) => {
  const [dirty, setDirty] = useState<Dirty>({} as Dirty);
  const [editDirty, setEditDirty] = useState<Dirty>({} as Dirty);

  const [ipr, setIPR] = useState<IPR>(initialIPR);
  const [editingIPR, setEditingIPR] = useState<IPR>(null);

  useEffect(() => {
    setIPR(initialIPR);
  }, [initialIPR]);

  const createdBy = creator.displayName ?? ipr.createdBy.toLowerCase();
  const createdAt = DateTime.fromISO(ipr.createdDatetime, { zone: 'UTC' }).toFormat('DDD');

  // Used to switch between edit mode and display mode
  const currIPR = editingIPR ?? ipr;
  return (
    <div className="content ipr-display">
      <div className="ipr-content">
        {ipr.isUnsubmitted && (
          <div className="message is-warning">
            <div className="message-body has-text-warning">This IPR has not been submitted yet</div>
          </div>
        )}
        <div className="card">
          <div className="card-header">
            <div className="is-flex">
              <h1 className="ipr-number">IPR #{currIPR.iprNum}</h1>
              <span className="created-date">Created {createdAt}</span>
            </div>
            <a href={`mailto:${creator.mail}?subject=IPR%20%23${currIPR.iprNum}`} className="created-by">
              {createdBy}
            </a>
          </div>

          <div className="card-body">
            <div className="top-fields">
              {/* Needed By */}
              <div className="data-field">
                <span>Needed By</span>
                <p>{DateTime.fromISO(currIPR.dateNeeded, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}</p>
              </div>

              {/* Charge To */}
              <div className="data-field">
                <span>Charge To</span>
                {(editingIPR && user.isIPRFinanceApprover) ? (
                  <div className="field">
                    <div className="select">
                      <select
                        onChange={(e) => {
                          setEditingIPR(editingIPR.set('chargeTo', e.target.value));
                          setEditDirty({ ...editDirty, chargeTo: true });
                        }}
                        value={currIPR.chargeTo}>
                        <option value={null} disabled>-- Select One --</option>
                        <option value="Sales & Marketing">Sales & Marketing</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Ops/COGS">Ops/COGS</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <p className={classNames({ dirty: dirty.chargeTo })}>{currIPR.chargeTo}</p>
                )}
              </div>

              {/* Custom Product */}
              <div className="data-field">
                <span>Custom?</span>
                {(editingIPR && user.isIPRFinanceApprover) ? (
                  <div className="field">
                    <div className="select">
                      <select
                        onChange={(e) => {
                          setEditingIPR(editingIPR.set('customProduct', e.target.value === 'true'));
                          setEditDirty({ ...editDirty, customProduct: true });
                        }}
                        value={currIPR.customProduct ? 'true' : 'false'}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <p className={classNames({ dirty: dirty.customProduct })}>
                    {currIPR.customProduct ? 'Yes' : 'No'}
                  </p>
                )}
              </div>

              {/* RMA */}
              <div className="data-field" style={{ minWidth: '4rem' }}>
                <span>RMA</span>
                {(editingIPR && user.isIPRFinanceApprover) ? (
                  <div className="field">
                    <input
                      type="text"
                      value={currIPR.rmaNumber}
                      onChange={(e) => {
                        setEditingIPR(editingIPR
                          .set('rmaNumber', e.target.value as any)
                          .set('isRma', !isEmpty(e.target.value)));
                        setEditDirty({ ...editDirty, rmaNumber: true });
                      }}
                      className="input"
                    />
                  </div>
                ) : (
                  <p className={classNames({ dirty: dirty.rmaNumber })}>
                    {currIPR.isRma ? (
                      <Link to={`/search/rma/${currIPR.rmaNumber}`}>{currIPR.rmaNumber}</Link>
                    ) : 'N/A'}
                  </p>
                )}
              </div>
            </div>

            {/* Contact Name */}
            <div className="data-field">
              <span>Contact Name</span>
              {(editingIPR && user.isIPRFinanceApprover) ? (
                <div className="field is-fullwidth">
                  <input
                    type="text"
                    value={currIPR.contactName}
                    onChange={(e) => {
                      setEditingIPR(editingIPR.set('contactName', e.target.value));
                      setEditDirty({ ...editDirty, contactName: true });
                    }}
                    className="input"
                  />
                </div>
              ) : (
                <p className={classNames({ dirty: dirty.contactName })}>
                  {currIPR.contactName?.trim() || 'N/A'}
                </p>
              )}
            </div>

            {/* Customer / Venue */}
            <div className="data-field">
              <span>Customer / Venue</span>
              {(editingIPR && user.isIPRFinanceApprover) ? (
                <div className="field is-fullwidth">
                  <input
                    type="text"
                    value={currIPR.customer}
                    onChange={(e) => {
                      setEditingIPR(editingIPR.set('customer', e.target.value));
                      setEditDirty({ ...editDirty, customer: true });
                    }}
                    className="input"
                  />
                </div>
              ) : (
                <p className={classNames({ dirty: dirty.customer })}>
                  {currIPR.customer?.trim() || 'N/A'}
                </p>
              )}
            </div>

            {/* Shipping Address */}
            <div className="data-field">
              <span>Shipping Info</span>
              {(editingIPR && user.isIPRApprover) ? (
                <div className="field is-fullwidth">
                  <textarea
                    className="textarea"
                    value={currIPR.shippingAddress}
                    onChange={(e) => {
                      setEditingIPR(editingIPR.set('shippingAddress', e.target.value));
                      setEditDirty({ ...editDirty, shippingAddress: true });
                    }}
                  />
                </div>
              ) : (
                <p className={classNames({ dirty: dirty.shippingAddress })}>
                  {currIPR.shippingAddress.trim().length === 0 ? 'N/A' : (
                    currIPR.shippingAddress.trim().split('\n').map((text) => (
                      <p className={classNames({ dirty: dirty.shippingAddress })} key={text}>{text}</p>
                    ))
                  )}
                </p>
              )}
            </div>

            {/* Additional Comments */}
            {currIPR.comments && (
              <div className="data-field">
                <span>Additional Comments</span>
                <p>
                  {currIPR.comments.trim().split('\n').map((text) => (
                    <p key={text}>{text}</p>
                  ))}
                </p>
              </div>
            )}

            {/* Products Requested */}
            {editingIPR && user.isIPRApprover ? (
              <div>
                <span className="data-field-label">Products Requested</span>
                <IPRItemList
                  items={currIPR.products}
                  setItems={(items) => {
                    setEditingIPR(editingIPR.set('products', items));
                    setEditDirty({ ...editDirty, products: true });
                  }}
                  isRma={currIPR.isRma}
                />
              </div>
            ) : (
              <table className={classNames('table is-striped is-hoverable product-table', { dirty: dirty.products })}>
                <thead>
                  <tr>
                    <th>Product Requested</th>
                    <th className="number-cell">Qty</th>
                    <th>Rev</th>
                    <th>Comments</th>
                    {currIPR.isRma && <th>Adv.<br />Replacement</th>}
                  </tr>
                </thead>
                <tbody>
                  {currIPR.products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.product}</td>
                      <td className="number-cell">{product.qty}</td>
                      <td>{product.rev || '--'}</td>
                      <td>{product.userComments || '--'}</td>
                      {currIPR.isRma && <td>{product.advancedReplacement ? 'Yes' : 'No'}</td>}
                    </tr>
                  ))}
                  {currIPR.products.isEmpty() && (
                    <tr>
                      <td className="has-text-centered" colSpan={currIPR.isRma ? 5 : 4}>No products requested</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {/* Reviewer Comments */}
        {!editingIPR && !ipr.isUnsubmitted && (!ipr.isSubmitted || user.isIPRApprover) && (
          <div className="card mtm">
            <div className="card-body" style={{ paddingTop: 0 }}>
              <div className="data-field" style={{ marginBottom: 0 }}>
                <span>Reviewer Comments</span>
                {(ipr.isShipped || ipr.isCompleted || !user.isIPRApprover || ipr.isRejected) ? (
                  <p>
                    {(ipr.reviewerComments ?? '').trim().split('\n').map((text) => (
                      <p key={text}>{text}</p>
                    ))}
                    {(ipr.reviewerComments ?? '').trim().length === 0 && 'N/A'}
                  </p>
                ) : (
                  <div className="field is-fullwidth">
                    <textarea
                      className="textarea"
                      onChange={(e) => setIPR(ipr.set('reviewerComments', e.target.value))}
                      value={ipr.reviewerComments}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {editingIPR ? (
          <div className="ipr-actions">
            <button
              type="button"
              className="button is-white"
              onClick={() => {
                setEditingIPR(null);
                setEditDirty({} as Dirty);
              }}>
              <i className="fa fa-times prxs" />
              Cancel
            </button>
            <div style={{ flex: 1 }} />
            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end' }}>
              <button
                type="button"
                className="button is-info"
                onClick={() => {
                  setIPR(ipr.merge(editingIPR));
                  setDirty({ ...dirty, ...editDirty });

                  setEditingIPR(null);
                  setEditDirty({} as Dirty);
                }}>
                <i className="fa fa-check prxs" />
                Confirm Changes
              </button>
              <span className="help-text mtxs">
                *Changes will not persist until approved or rejected
              </span>
            </div>
          </div>
        ) : (
          <IPRActions ipr={ipr} user={user} refresh={refresh} onEdit={() => setEditingIPR(ipr)} />
        )}
      </div>

      {!ipr.isUnsubmitted && <IPRStatus ipr={ipr} user={user} />}
    </div>
  );
};

export default IPRDisplay;
