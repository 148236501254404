import React from 'react';
import { useHistory } from 'react-router';
import { cache } from 'swr';

import IPRForm from '@components/ipr_entry/form/IPRForm';
import { User, UserProfile } from '@models/User';
import IPR from '@models/IPR';

interface Props {
  user: User;
  creator: UserProfile;
  ipr?: IPR;
  refresh?: () => Promise<boolean>;
}

const IPRFormPage: React.FC<Props> = ({ creator, ipr, user, refresh }: Props) => {
  const history = useHistory();
  return (
    <div className="content">
      <IPRForm
        user={user}
        creator={creator}
        initialData={ipr}
        onSubmit={async (data) => {
          const { iprNum } = await IPR.upsert(data);
          cache.delete(`/iprs/${iprNum}`);
          if (data.iprNum && !data.isUnsubmitted)
            await refresh();
          else
            history.replace(data.isUnsubmitted ? '/iprs' : `/iprs/${iprNum}`);
        }}
      />
    </div>
  );
};

export default IPRFormPage;
