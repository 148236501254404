import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { fetchUser } from '@actions/auth';
import { IUser } from '@models/User';

import Root from './components/root';
import LoginPage from './components/login/LoginPage';

import './app.scss';

const history = createBrowserHistory();
interface PrivateRouteProps {
  component?: React.ComponentType<any>;
  user?: IUser;
  path?: string | string[];
  location?: Location;
}

const PrivateRoute = ({ component: Component, user, path }: PrivateRouteProps): JSX.Element => (
  <Route
    path={path}
    render={props => (
      user ? (
        <Component user={user} {...props} /> // eslint-disable-line react/jsx-props-no-spreading
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }}
        />
      )
    )}
  />
);

type IProps = {};

const App: React.FC<IProps> = () => {
  const [user, setUser] = useState<IUser>(null);

  useEffect(() => {
    if (!user) fetchUser().then(setUser);
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/" component={Root} user={user} />
      </Switch>
    </Router>
  );
};

export default hot(App);
