import React from 'react';

import { formatDate } from '../../utils/format';
import { MrbShipment } from '../../../../app/models/inventory';

const MrbShipmentRecord: React.FC<MrbShipment> = ({
  serial_number,
  entry_date_time,
  shipment_date,
  po_number,
}: MrbShipment) => {
  if (!serial_number) return null;
  const date_time = shipment_date || entry_date_time;
  return (
    <div>
      <p className="heading">{formatDate(date_time)}</p>
      <p>Shipped as MRB on PO #{po_number}</p>
    </div>
  );
};

export default MrbShipmentRecord;
