/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import ContinuityTestRecord from '@components/device/ContinuityTestRecord';
import ErrorBoundary from '@components/shared/ErrorBoundary';

import ProductionTestRecord from './ProductionTestRecord';
import InventoryRecord from './InventoryRecord';
import HighPotRecord from './HighPotRecord';
import ProgrammingRecord from './ProgrammingRecord';
import ScrapRecord from './ScrapRecord';
import MrbRecord from './MrbRecord';
import MrbAnalysisRecord from './MrbAnalysisRecord';
import RmaRecord from './RmaRecord';
import RmaAnalysisRecord from './RmaAnalysisRecord';
import UnitNotesRecord from './UnitNotesRecord';
import IpoRecord from './IpoRecord';
import VshProductionTestRecord from './VshProductionTestRecord';
import PcxOrPtxProductionTestRecord from './PcxOrPtxProductionTestRecord';
import ShipmentRecord from './ShipmentRecord';
import CmShipmentRecord from './CmShipmentRecord';
import RmaShipmentRecord from './RmaShipmentRecord';
import MrbShipmentRecord from './MrbShipmentRecord';
import IprShipmentRecord from './IprShipmentRecord';
import RmaItemRecord from './RmaItemRecord';
import DeviceInstallRecord from './DeviceInstallRecord';
import Rx542kTestRecord from './Rx542kTestRecord';
import { EVENT_TYPES, RecordTypes } from '../../../../app/models/event';
import {
  ProductionTestData, Vsh500TestData, PcxAndPtxTestData,
  HiPotTestData, ProgrammingTestData, RX542KTestData, ContinuityTestData,
} from '../../../../app/models/test_data';
import {
  Receiving, Scrap, MrbReceiving, Analysis, RmaReceiving, UnitNote, CmShipment,
  IPO, PoShipment, RmaShipment, MrbShipment, IprShipment, RmaItem,
} from '../../../../app/models/inventory';
import { DeviceInstallDetails } from '../../../../app/models/device_configurations';

interface IProps {
  type: EVENT_TYPES;
  record: RecordTypes;
  serial_number: string;
}

const Device: React.FC<IProps> = ({
  type,
  serial_number,
  record,
}: IProps) => {
  let result = null;
  let icon = '';
  let color = 'is-success';

  switch (type) {
    case 'PM500_PRODUCTION_TEST':
    case 'MGT100_PRODUCTION_TEST':
    case 'PRODUCTION_TEST': {
      const nRecord = record as ProductionTestData;
      result = <ProductionTestRecord serial_number={serial_number} {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'INVENTORY_RECEIVE': {
      const nRecord = record as Receiving;
      result = <InventoryRecord serial_number={serial_number} {...nRecord} />;
      color = nRecord.status ? 'is-info':'is-danger';
      icon = 'fa-archive';
      break;
    }
    case 'VSH_PRODUCTION_TEST': {
      const nRecord = record as Vsh500TestData;
      result = <VshProductionTestRecord serial_number={serial_number} {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'PCX_OR_PTX_PRODUCTION_TEST': {
      const nRecord = record as PcxAndPtxTestData;
      result = <PcxOrPtxProductionTestRecord serial_number={serial_number} {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'HIGH_POT': {
      const nRecord = record as HiPotTestData;
      result = <HighPotRecord unit_serial_number={serial_number} {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'PROGRAMMING': {
      const nRecord = record as ProgrammingTestData;
      result = <ProgrammingRecord serial_number={serial_number} {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'SCRAP': {
      result = <ScrapRecord serial_number={serial_number} {...record as Scrap} />;
      color = 'is-info';
      icon = 'fa-trash';
      break;
    }
    case 'MRB': {
      result = <MrbRecord serial_number={serial_number} {...record as MrbReceiving} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'MRB_ANALYSIS': {
      result = <MrbAnalysisRecord serial_number={serial_number} {...record as Analysis} />;
      color = 'is-info';
      icon = 'fa-search';
      break;
    }
    case 'RMA_RECEIVE': {
      result = <RmaRecord serial_number={serial_number} {...record as RmaReceiving} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'RMA_ANALYSIS': {
      result = <RmaAnalysisRecord serial_number={serial_number} {...record as Analysis} />;
      color = 'is-info';
      icon = 'fa-search';
      break;
    }
    case 'UNIT_NOTES': {
      result = <UnitNotesRecord serial_number={serial_number} {...record as UnitNote} />;
      color = 'is-info';
      icon = 'fa-pencil-alt';
      break;
    }
    case 'IPO': {
      result = <IpoRecord serial_number={serial_number} {...record as IPO} />;
      color = 'is-info';
      icon = 'fa-wrench';
      break;
    }
    case 'PO_SHIPMENT': {
      result = <ShipmentRecord serial_number={serial_number} {...record as PoShipment} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'CM_SHIPMENT': {
      result = <CmShipmentRecord serial_number={serial_number} {...record as CmShipment} />;
      color = 'is-info';
      icon = 'fa-truck';
      break;
    }
    case 'RMA_SHIPMENT': {
      result = <RmaShipmentRecord serial_number={serial_number} {...record as RmaShipment} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'MRB_SHIPMENT': {
      result = <MrbShipmentRecord serial_number={serial_number} {...record as MrbShipment} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'IPR_SHIPMENT': {
      result = <IprShipmentRecord serial_number={serial_number} {...record as IprShipment} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'RMA_ITEM': {
      result = <RmaItemRecord serial_number={serial_number} {...record as RmaItem} />;
      color = 'is-info';
      icon = 'fa-archive';
      break;
    }
    case 'DEVICE_INSTALL_DETAILS': {
      result = <DeviceInstallRecord serial_number_mgt={serial_number} {...record as DeviceInstallDetails} />;
      color = 'is-info';
      icon = 'fa-pencil-alt';
      break;
    }
    case 'CONTINUITY_TEST': {
      const nRecord = record as ContinuityTestData;
      result = <ContinuityTestRecord {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    case 'RX54_2K_TEST': {
      const nRecord = record as RX542KTestData;
      result = <Rx542kTestRecord {...nRecord} />;
      color = nRecord.result ? 'is-success' : 'is-danger';
      icon = nRecord.result ? 'fa-check' : 'fa-times';
      break;
    }
    default:
      return null;
  }

  return (
    <div id={type} className="timeline-item">
      <div className={`timeline-marker is-large-marker ${color} has-text-centered`}>
        <i className={`fa ${icon}`} />
      </div>
      <div className="timeline-content">
        <ErrorBoundary>
          {result}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Device;
