import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import IPR from '@models/IPR';
import { useUser } from '@hooks/user';
import { User } from '@models/User';

interface Props {
  ipr: IPR;
  user: User;
}

const IPRStatus: React.FC<Props> = ({ ipr, user }: Props) => {
  const { data: financeApprover } = useUser(ipr.accountingApprover, { shouldRetryOnError: false });
  const { data: inventoryApprover } = useUser(ipr.inventoryApprover, { shouldRetryOnError: false });

  return (
    <div className="ipr-status card" style={{ marginRight: 'auto' }}>
      {/* Finance Approval */}
      {ipr.isFinanceApproved ? (
        <div className="status-step">
          <span className="timestamp">
            {DateTime.fromISO(ipr.accountingDate, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}
          </span>
          <div className="details">
            {ipr.isRejected ? (
              <i className="fa fa-times-circle has-text-danger mrxs" />
            ) : (
              <i className="fa fa-check-circle has-text-primary mrxs" />
            )}
            <div>
              <span className="is-block">Finance {ipr.isRejected ? 'Rejected' : 'Approved'}</span>
              <a className="approver" href={`mailto:${ipr.accountingApprover}?subject=IPR%20%23${ipr.iprNum}`}>
                {financeApprover?.displayName ?? ipr.accountingApprover}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <p className={classNames('status-step pending', {
          active: (ipr.isSubmitted || ipr.isPending) && user.isIPRFinanceApprover,
        })}>
          <i className="far fa-clock" /> Finance Approval
        </p>
      )}

      {/* Inventory Approval */}
      {ipr.isInventoryApproved ? (
        <div className="status-step">
          <span className="timestamp">
            {DateTime.fromISO(ipr.inventoryDate, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}
          </span>
          <div className="details">
            {ipr.isRejected ? (
              <i className="fa fa-times-circle has-text-danger mrxs" />
            ) : (
              <i className="fa fa-check-circle has-text-primary mrxs" />
            )}
            <div>
              <span className="is-block">Inventory {ipr.isRejected ? 'Rejected' : 'Approved'}</span>
              <a className="approver" href={`mailto:${ipr.inventoryApprover}?subject=IPR%20%23${ipr.iprNum}`}>
                {inventoryApprover?.displayName ?? ipr.inventoryApprover}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <p className={classNames('status-step pending', {
          active: (ipr.isSubmitted || ipr.isPending) && user.isIPRInventoryApprover,
        })}>
          <i className="far fa-clock" /> Inventory Approval
        </p>
      )}

      {/* Shipment */}
      {ipr.shipDate ? (
        <div className="status-step">
          <span className="timestamp">{DateTime.fromISO(ipr.shipDate, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}</span>
          <div className="details">
            <i className="fa fa-shipping-fast has-text-primary mrs" />
            <div>
              <span className="is-block">Shipped</span>
            </div>
          </div>
        </div>
      ) : (
        <p className={classNames('status-step pending', {
          active: ipr.isApproved && user.isIPRInventoryApprover,
        })}>
          <i className="far fa-clock" /> Shipment
        </p>
      )}

      {/* Invoice */}
      {ipr.invoiceDate ? (
        <div className="status-step">
          <span className="timestamp">{DateTime.fromISO(ipr.invoiceDate, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}</span>
          <div className="details">
            <i className="fa fa-receipt has-text-primary mrxs" />
            <div>
              <span className="is-block">Invoiced</span>
            </div>
          </div>
        </div>
      ) : (
        <p className={classNames('status-step pending', {
          active: ipr.isShipped && user.isIPRExecutor,
        })}>
          <i className="far fa-clock" /> Invoice
        </p>
      )}
    </div>
  );
};

export default IPRStatus;
