/**
 * Checks if the given value is undefined, null, empty or 0.
 * @param val
 * @return `true` if the given value is empty-like
 */
export function isEmpty(val: any): boolean {
  if (val === undefined || val === null)
    return true;
  if (typeof val === 'string')
    return val.length === 0;
  if (typeof val === 'number')
    return val === 0;
  return false;
}

/**
 * Checks if the given value is present. The opposite of `isEmpty()`
 * @param val
 * @return `true` if the given value is not empty-like
 */
export function isPresent(val: any): boolean {
  return !isEmpty(val);
}
