import React, { useState, useEffect } from 'react';
import ReactTable, { Column, Filter } from 'react-table';
import { Location, History } from 'history';

import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';

import EditCompatibilityModal from './EditCableCompatibilityModal';
import * as Compatibility from '../../actions/compatibility';
import { updateFilteredQueryString, defaultFilterMethod } from '../../utils/table_helpers';
import { CableCompatibilityInfo } from '../../../../app/models/compatibility';
import { formatDate } from '../../utils/format';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
}

const CableCompatibilityPage: React.FC<IProps> = ({location: { search }, history}: IProps) => {
  usePageTitle('Cable Compatibility');
  const [compatibility, setCompatibility] = useState<CableCompatibilityInfo[] | null>(null);
  const [createModalToggled, setCreateModalToggled] = useState<boolean>(false);
  const [editModalToggled, setEditModalToggled] = useState<boolean>(false);
  const [editEntity, setEditEntity] = useState<CableCompatibilityInfo>(null);

  const createCompatibility = (comp: CableCompatibilityInfo): Promise<void> => Compatibility.addCable(comp)
    .then(Compatibility.fetchCables)
    .then(result => setCompatibility(result.data));

  const updateCompatibility = (comp: CableCompatibilityInfo): Promise<void> => Compatibility.updateCable(comp)
    .then(Compatibility.fetchCables)
    .then(result => setCompatibility(result.data));

  useEffect(() => {
    if (compatibility === null) Compatibility.fetchCables().then(result => setCompatibility(result.data));
  }, []);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{
    Header: 'Date Added',
    accessor: 'entry_date_time',
    Cell: function addedDate(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{formatDate(cell.value, DateTime.DATE_MED)}</span>
        </div>
      );
    },
    maxWidth: 200,
  }, {
    Header: 'Added By',
    accessor: 'created_by',
    Cell: function addedby(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 200,
  }, {
    Header: 'Manufacturer',
    accessor: 'cable_manufacturer',
    Cell: function manufactureCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 250,
  }, {
    Header: 'Model',
    accessor: 'cable_model',
    Cell: function modelCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 250,
  }, {
    Header: 'Model Details',
    accessor: 'cable_model_details',
    Cell: function modelDets(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 225,
  }, {
    Header: 'Gauge',
    accessor: 'gauge',
    Cell: function gaugeCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 225,
  }, {
    Header: 'Conductors',
    accessor: 'conductors',
    Cell: function conductorCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 225,
  }, {
    Header: 'Outdoor',
    accessor: 'outdoor',
    Cell: function outdoorCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 120,
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterOutdoor({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="yes">Show Yes</option>
          <option value="no">Show No</option>
        </select>
      );
    },
  }, {
    Header: 'Twisted Pair',
    accessor: 'twisted_pair',
    Cell: function twistPair(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 120,
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterTwistPair({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="yes">Show Yes</option>
          <option value="no">Show No</option>
        </select>
      );
    },
  }, {
    Header: 'Compatible',
    accessor: 'compatible',
    maxWidth: 120,
    Cell: function compatibleCell(cell) {
      const bgColor = compatibilityColor(cell.value);
      return (
        <div style={{paddingLeft: '3px', backgroundColor: bgColor, whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span style={{color: 'white'}}>{cell.value}</span>
        </div>
      );
    },
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterComp({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="yes">Show Yes Only</option>
          <option value="no">Show No Only</option>
          <option value="limitations">Show Limitations Only</option>
          <option value="TBD">Show TBD Only</option>
        </select>
      );
    },
  }, {
    Header: 'Test Method',
    accessor: 'test_method',
    Cell: function testMethod(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
    maxWidth: 135,
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return row[filter.id] === filter.value;
    },
    Filter: function filterTestMethod({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="Field Test">Field Test</option>
          <option value="Eng Simple Test">Eng Simple Test</option>
          <option value="Eng Full Test">Eng Full Test</option>
          <option value="Deduction">Deduction</option>
        </select>
      );
    },
  }, {
    Header: 'Comments',
    accessor: 'comments',
    Cell: function commentsCell(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'pre-wrap'}}>
          <span>{cell.value}</span>
        </div>
      );
    },
  }, {
    Header: 'Edit',
    width: 50,
    accessor: 'id',
    Cell: function editCell(cell) {
      return (
        <button
          className="button-like-link"
          type="button"
          style={{width: '100%', whiteSpace: 'pre-wrap', textAlign: 'center', display: 'block'}}
          onClick={() => {
            setEditModalToggled(true);
            setEditEntity(cell.original);
          }}>
          <i className="fa fa-pencil-alt" />
        </button>
      );
    },
    maxWidth: 100,
    Filter: () => null,
  }];

  return (
    <div className="compatibility-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <EditCompatibilityModal
        toggled={createModalToggled}
        closeAction={() => setCreateModalToggled(false)}
        title="Add Compatibility"
        confirmText="Add (Cannot be removed)"
        okAction={createCompatibility}
      />
      { editEntity
      && (
        <EditCompatibilityModal
          key={editEntity.entry_id}
          toggled={editModalToggled}
          closeAction={() => {
            setEditModalToggled(false);
            setEditEntity(null);
          }}
          entity={editEntity}
          title="Edit Compatibility"
          confirmText="Update"
          okAction={updateCompatibility}
        />
      )}
      <div className="is-flex is-fullwidth">
        <h1 className="page-title" style={{ flex: 1 }}>Cable Compatibility</h1>
        <button
          className="button mrs elev-1"
          type="submit"
          onClick={() => {
            setCreateModalToggled(true);
            setEditEntity(null);
          }}>
          <span>
            Add Compatibility Record
          </span>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
        </button>
        <a
          href={`/reports/cable_compatibility.xlsx?${filtered.map(filter => `${filter.id}=${filter.value}`).join('&')}`}
          className="button is-link elev-1"
          download>
          <span className="icon">
            <i className="fa fa-download" />
          </span>
          <span>Download filtered as Excel</span>
        </a>
      </div>
      {compatibility === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={compatibility}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultFilterMethod={defaultFilterMethod}
          showPagination={compatibility.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

function compatibilityColor(value: string): string {
  switch (value) {
    case 'yes':
      return 'hsl(141, 71%, 48%)';
    case 'no':
      return 'hsl(348, 100%, 61%)';
    case 'limitations':
      return 'hsl(48,  100%, 67%)';
    default:
      return 'hsl(0, 0%, 68%)';
  }
}

export default CableCompatibilityPage;
