import React from 'react';
import {
  Line, XAxis, Tooltip, ComposedChart, Bar,
  ResponsiveContainer, YAxis, Legend,
} from 'recharts';

import ErrorBoundary from '../shared/ErrorBoundary';
import { DeviceMetric } from '../../../../app/models/device';
import { FirstRunYield } from '../../../../app/models/metrics';

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

interface IProps extends DeviceMetric {
  title?: string;
  shouldNotColor?: boolean;
  showTotals?: boolean;
}

const Metrics: React.FC<IProps> = ({
  firstRunYield30Day,
  firstRunYield60Day,
  firstRunYield90Day,
  shippedLast12Months,
  shippingData,
  rmaMetrics,
  firstRunYieldMetrics,
  title,
  shouldNotColor,
  showTotals,
}: IProps) => (
  <div className={`metric-section ${!shouldNotColor && 'box elev-1'}`}>
    <h1 className="has-text-centered is-size-4 mbm">{title}</h1>
    <div className="level">

      <ErrorBoundary>
        <div className="level-item has-text-centered shipping">
          <div className="is-fullwidth">
            <p className="heading"># devices <br />shipped in last 12 months</p>
            <p className={`title ${showTotals && 'bumper'}`}>{!Number.isNaN(shippedLast12Months)
              ? shippedLast12Months : <i className="fa fa-spinner fa-spin fa-lg fa-fw" />}
            </p>
            <ResponsiveContainer width="100%" height={200}>
              <ComposedChart data={shippingData}>
                <Tooltip labelFormatter={(name: number) => MONTHS[name-1]} />
                <XAxis dataKey="month" />
                <Bar name="# Shipped" fill="#82ca9d" dataKey="shipped_count" />
                <Legend verticalAlign="bottom" height={36} />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <div className="level-item has-text-centered rma">
          <div className="rma-percentage">
            <p className="heading">% RMA Products Issued vs Shipped</p>
            <p className="title">
              { rmaMetrics && rmaMetrics.movingAverage
                ? <span />
                : <i className="fa fa-spinner fa-spin fa-lg fa-fw" /> }
            </p>
            <>
              <p className="heading">Total RMAs Issued (90/60/30)</p>
              <p className={`subtitle ${showTotals && 'bumper'}`}>{rmaMetrics && rmaMetrics.movingAverage
                ? (
                  <span>
                    {rmaMetrics.movingAverage[90].issued}
                    <span className="thin-text">/</span>
                    {rmaMetrics.movingAverage[60].issued}
                    <span className="thin-text">/</span>
                    {rmaMetrics.movingAverage[30].issued}
                  </span>
                ) : <i className="fa fa-spinner fa-spin fa-lg fa-fw" />}
              </p>
            </>
            <ResponsiveContainer width="100%" height={200}>
              <ComposedChart data={rmaMetrics && rmaMetrics.monthly}>
                <Tooltip
                  formatter={(value: number, name: string) => (name !== '# Issued' ? value.toFixed(2) : value)}
                  labelFormatter={(name: number) => MONTHS[name-1]}
                />
                <Legend verticalAlign="bottom" height={36} />
                <XAxis dataKey="month" />
                <YAxis yAxisId={0} orientation="left" hide />
                <Bar name="# Issued" fill="#82ca9d" dataKey="issued_count" yAxisId={0} />
                <YAxis yAxisId={1} orientation="right" hide />
                <Line
                  name="% RMA 6MMA"
                  type="monotone"
                  dataKey="percentVsShipped6Month"
                  yAxisId={1}
                  legendType="line"
                  stroke="#ca8285"
                  strokeWidth={2}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <div className="level-item has-text-centered">
          <div className="first-run-yield">
            <p className="heading">First Pass Yield % <br /> Past 90/60/30 Days</p>
            <p className={`subtitle ${showTotals && 'skinny-bumper'}`}>{firstRunYield30Day && firstRunYield60Day && firstRunYield90Day
              ? (
                <span>
                  <span className={getFirstRunYieldColor(firstRunYield90Day, shouldNotColor)}>
                    {firstRunYield90Day.result === null ? 'NA' : firstRunYield90Day.result}
                  </span>
                  <span className="thin-text">/</span>
                  <span className={getFirstRunYieldColor(firstRunYield60Day, shouldNotColor)}>
                    {firstRunYield60Day.result === null ? 'NA' : firstRunYield60Day.result}
                  </span>
                  <span className="thin-text">/</span>
                  <span className={getFirstRunYieldColor(firstRunYield30Day, shouldNotColor)}>
                    {firstRunYield30Day.result === null ? 'NA' : firstRunYield30Day.result}
                  </span>
                </span>
              ) : <i className="fa fa-spinner fa-spin fa-lg fa-fw" />}
            </p>
            {showTotals && (
              <>
                <p className="heading">Total tested</p>
                <p className="subtitle">{firstRunYield30Day && firstRunYield60Day && firstRunYield90Day
                  ? (
                    <span>
                      {firstRunYield90Day.total}
                      <span className="thin-text">/</span>
                      {firstRunYield60Day.total}
                      <span className="thin-text">/</span>
                      {firstRunYield30Day.total}
                    </span>
                  ) : <i className="fa fa-spinner fa-spin fa-lg fa-fw" />}
                </p>
              </>
            )}
            <ResponsiveContainer width="100%" height={200}>
              <ComposedChart data={firstRunYieldMetrics && firstRunYieldMetrics.monthly}>
                <Tooltip
                  formatter={(value: number, name: string) => (name !== '# Tested' ? value.toFixed(2) : value)}
                  labelFormatter={(name: number) => MONTHS[name-1]}
                />
                <Legend verticalAlign="bottom" height={36} />
                <XAxis dataKey="month" />
                <YAxis
                  yAxisId={0}
                  orientation="left"
                  domain={[0, firstRunYieldMetrics && firstRunYieldMetrics.maxNumTested]}
                  hide
                />
                <Bar name="# Tested" fill="#82ca9d" dataKey="total" yAxisId={0} />
                <YAxis yAxisId={1} orientation="right" hide />
                <Line
                  name="First Pass Yield %"
                  type="monotone"
                  dataKey="percentage"
                  yAxisId={1}
                  legendType="line"
                  strokeWidth={2}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  </div>
);

Metrics.defaultProps = {
  title: 'Metrics',
  shouldNotColor: false,
  showTotals: true,
};

function getFirstRunYieldColor(firstRunYield: FirstRunYield, shouldNotColor: boolean): string {
  if (Number.isNaN(firstRunYield.result) || shouldNotColor) return '';
  return firstRunYield.result < 99 ? 'has-text-danger' : 'has-text-success';
}

export default Metrics;
