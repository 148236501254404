import React, { useState } from 'react';

import { formatDate } from '@utils/format';

import { DeviceInstallDetails } from '../../../../app/models/device_configurations';

const DeviceInstallRecord: React.FC<DeviceInstallDetails> = ({
  entry_time,
  serial_number_mgt,
  site,
  organization,
  device_name,
  transmitter_json,
  channel_json,
  power_module_json,
  config_json,
  alerts_json,
  version_json,
  faults_json,
}: DeviceInstallDetails) => {
  const [toggled, setToggled] = useState<boolean>(false);

  if (!serial_number_mgt) return null;

  const combinedData = JSON.parse(`{
    "transmitter": ${transmitter_json || '""'},
    "channels": ${channel_json || '""'},
    "powerModules": ${power_module_json || '""'},
    "config": ${config_json || '""'},
    "alerts": ${alerts_json || '""'},
    "versionInfo": ${version_json || '""'},
    "faults": ${faults_json || '""'}}`);

  const ipAddress = combinedData.transmitter.ipAddress || 'Unknown IP Address';
  const channels = combinedData.channels || [];
  const channelData = channels
    .map((channel: Channel) => ({
      key: channel.slot,
      power: Math.round((channel.outputVoltage / 1000) * (channel.outputCurrent / 1000)),
      name: !channel.name ? '--' : channel.name,
      enabled: channel.mode === 2,
      disabled: channel.mode === 64,
      status: channel.majorStatus ?? -1,
    }))
    .sort((left: ChannelDisplay, right: ChannelDisplay) => left.key - right.key);
  const enabledChannels = channelData
    .map((data: ChannelDisplay) => (data.enabled ? 1 : 0))
    .reduce((enabled: number, count: number) => enabled + count, 0);
  const totalPower = Math.round(channelData
    .map((data: ChannelDisplay) => data.power)
    .filter((power: number) => !Number.isNaN(power))
    .reduce((power: number, total: number) => power + total, 0) / 10) / 100;
  return (
    <div className="device-install-details">
      <p className="heading">{formatDate(entry_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>Installation Configuration Uploaded</p>
        </div>
        <div className="message-body">
          <div className="level has-text-centered">

            <div className="level-item">
              <div>
                <p className="heading">Unit Label</p>
                <p className="is-size-6">{device_name === '' ? '--' : device_name}</p>
              </div>
            </div>

            <div className="level-item">
              <div>
                <p className="heading">Site</p>
                <p className="is-size-6">{site === '' ? '--' : site}</p>
              </div>
            </div>

            <div className="level-item">
              <div>
                <p className="heading">Organization</p>
                <p className="is-size-6">{organization === '' ? '--' : organization}</p>
              </div>
            </div>

            <div className="level-item">
              <div>
                <p className="heading">IP Address</p>
                <p className="is-size-6">{ipAddress === '' ? '--' : ipAddress}</p>
              </div>
            </div>
          </div>

          <div className="level">
            <div className="level-item has-text-centered">
              <p>{`${enabledChannels} channels currently enabled. Total Power of ${totalPower} kW.`}</p>
            </div>
          </div>

          <div className="level">
            <div className="level-item has-text-centered">
              <table className="table info-table is-narrow">
                <thead>
                  <tr>
                    <th className="info-table-item">Channel</th>
                    <th className="info-table-item">Name</th>
                    <th className="info-table-item">Power / Fault</th>
                  </tr>
                </thead>
                <tbody>
                  {channelData.map((channel: ChannelDisplay) => renderRow(channel))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="level">
            <div className="level-item">
              <div>
                <p className="heading has-text-centered">Verbose Configuration</p>
                {toggled
                  ? (
                    <pre>{JSON.stringify(combinedData, null, 2)}</pre>
                  ) : (
                    <pre className="pas has-text-centered">...</pre>
                  )}
                <div className="has-text-centered">
                  <button type="button" className={`button elev-1 mam ${toggled ? 'is-danger' : 'is-info'}`} onClick={() => setToggled(!toggled)}>
                    {toggled ? (<span>Hide Configuration</span>) : ((<span>Show Configuration</span>))}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default DeviceInstallRecord;

type Channel = {
  mode: number;
  slot: number;
  name: string;
  outputVoltage: number;
  outputCurrent: number;
  majorStatus: number;
};

type ChannelDisplay = {
  key: number;
  power: number;
  name: string;
  enabled: boolean;
  disabled: boolean;
  status: number;
};

function getFaultNameFromCode(faultCode: number): string {
  const hiByte = faultCode >> 8; // eslint-disable-line no-bitwise
  switch (hiByte) {
    case -1:
      return 'Card Removed';
    case 0:
      return 'Disabled';
    case 1:
      return 'No Receiver Detected';
    case 2:
      return 'Startup Fault';
    case 3:
      return 'Transmission Fault';
    case 4:
      return 'Overload Fault';
    case 5:
      return 'Temperature Fault';
    case 6:
      return 'Short Circuit Fault';
    case 7:
      return 'Ground Fault';
    case 8:
      return 'Input Power Fault';
    case 9:
      return 'Internal Failure';
    default:
      return 'Unknown Fault';
  }
}

function renderRow(channel: ChannelDisplay): JSX.Element {
  if (channel.status === -1) return <div />;
  return (
    <tr className="info-table-row" key={channel.key}>
      <td className="info-table-item info-table-channel">
        {channel.enabled
          ? (
            <span className="tag is-success">{channel.key}</span>
          ) : (
            <span className={`tag ${channel.disabled ? 'is-grey' : 'is-danger'}`}>
              {channel.key}
            </span>
          )}
      </td>
      <td className="info-table-item">
        {channel.name}
      </td>
      <td className="info-table-item">
        {channel.enabled
          ? (
            <span>
              {channel.power}w
            </span>
          ) : (
            <span className={channel.disabled ? 'disabled' : 'fault'}>
              {getFaultNameFromCode(channel.status)}
              <span className="monospace">
                {` (0x${channel.status.toString(16)})`}
              </span>
            </span>
          )}
      </td>
    </tr>
  );
}
