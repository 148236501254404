import http from './http';
import { SummaryResult, PoShipment, PoSummary, PoShipmentGrouped } from '../../../app/models/inventory';
import { POQuery } from '../../../app/lib/po';

export async function fetch(serial_number: string): Promise<SummaryResult<PoShipment, PoSummary>> {
  const {data} = await http.get(`/po/${serial_number}`);
  return data;
}

export async function fetchAll(): Promise<PoShipmentGrouped[]> {
  const {data} = await http.get('/po');
  return data;
}

export async function search(query: string): Promise<POQuery[]> {
  const { data } = await http.get(`/po/search/${encodeURIComponent(query)}`);
  return data;
}
