import { History } from 'history';
import { Filter } from 'react-table';
import { DateTime, DateTimeFormatOptions } from 'luxon';

import { formatDate } from './format';

export function updateFilteredQueryString(filtered: Filter[], history: History): void {
  history.replace({search: `?${filtered.map((filter: Filter) => `${filter.id}=${filter.value}`).join('&')}`});
}

export function defaultFilterMethod(filter: Filter, row: any): boolean {
  const val = row[filter.id];
  if (Number.isNaN(val)) {
    const actual = String(val).toLowerCase();
    const expected = String(filter.value).toLowerCase();

    // If the expected string is "fi rep tem" then the output regex
    // is "fi.*rep.*tem.*"
    const expectReplace = expected.replace(/\s+/g, '.*');
    const regex = new RegExp(`${expectReplace}.*$`);
    return regex.test(actual);
  }
  return String(val) === filter.value;
}

export function filterAsString(filter: Filter, row: any): boolean {
  return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
}

export function filterDatesShort(filter: Filter, row: any) {
  return filterDates(filter, row, DateTime.DATE_SHORT);
}

export function filterDatesMed(filter: Filter, row: any) {
  return filterDates(filter, row, DateTime.DATE_MED);
}

function filterDates(filter: Filter, row: any, dateFormat: DateTimeFormatOptions): boolean {
  const val = row[filter.id] ? String(row[filter.id]) : '--';
  if (val === '--') return val.includes(filter.value);
  const dateString = formatDate(new Date(val), dateFormat).toLowerCase();
  return dateString.includes(filter.value.toLowerCase());
}
