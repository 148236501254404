import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTable, { Filter, Column } from 'react-table';
import { Location, History } from 'history';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';
import { updateFilteredQueryString, filterAsString, filterDatesMed } from '@utils/table_helpers';
import { IUser } from '@models/User';
import { renderIfQuality } from '@utils/permissions';
import { formatDate } from '@utils/format';

import * as RmaApi from '../../actions/rma';
import * as Metrics from '../../actions/metrics';
import CommentsModal from './CommentsModal';
import { RmaSummaryView } from '../../../../app/models/views';
import { DeviceMetric } from '../../../../app/models/device';
import Rma from '../../../../app/lib/rma';

import LoadingSpinner from '../shared/LoadingSpinner';
import { useRmaMetrics, useRmaSummary } from '@hooks/rma';
import { authorize } from 'passport';

interface IProps {
  location: Location;
  history: History;
  user: IUser;
}

const RmaHighlightsPage: React.FC<IProps> = ({
  location: { search },
  history,
  user,
}: IProps) => {
  usePageTitle('RMAs');

  const [summary, setSummary] = useState<RmaSummaryView[] | null>(null);
  const [metrics, setMetrics] = useState<DeviceMetric>(null);
  const [editingRma, setEditingRma] = useState<Rma>(null);

  const{data: swrMetrics} = useRmaMetrics(metrics); //Load Metrics data into cache
  const{data: swrSummary} = useRmaSummary(summary); //Load RMA Summary data into cache
  
  //Update stale data, if any
  if(swrMetrics) setMetrics(swrMetrics);
  if(swrSummary) setSummary(swrSummary);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];
  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  function updateComments(rma: Rma): Promise<void> {
    return RmaApi.updateComments(rma)
      .then(RmaApi.fetchSummary)
      .then(result => {
        setEditingRma(null);
        setSummary(result);
      });
  }

  const columns: Column[] = [
    {
      Header: 'RMA Number',
      accessor: 'rma_number',
      width: 122,
      Cell: function rmaNum(cell) {
        return (
          <div style={{paddingLeft: '3px'}}>
            <Link to={`/search/rma/${cell.value}`}>{cell.value}</Link>
          </div>
        );
      },
    }, {
      Header: 'Added On',
      accessor: 'entry_date_time',
      width: 122,
      Cell: function entryDate(cell) {
        return (
          <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
            <span>{formatDate(cell.value, DateTime.DATE_MED)}</span>
          </div>
        );
      },
      filterMethod: filterDatesMed,
    }, {
      Header: 'Customer',
      accessor: 'customer',
      Cell: function customerCell(cell) {
        return (
          <div style={{paddingLeft: '3px'}}>
            <span>{cell.value}</span>
          </div>
        );
      },
    },
    {
      Header: 'Site',
      accessor: 'site',
      Cell: function siteCell(cell) {
        return (
          <div style={{paddingLeft: '3px'}}>
            <span>{cell.value}</span>
          </div>
        );
      },
    }, {
      Header: 'Expected Units',
      accessor: 'rma_item_count',
      width: 130,
      Cell: function expectUnitsCell(cell) {
        return (
          <div style={{paddingRight: '0.5rem', textAlign: 'right'}}>
            <span>{cell.value}</span>
          </div>
        );
      },
      Filter: () => null,
    }, {
      Header: 'Received Units',
      accessor: 'received_count',
      width: 150,
      Cell: function receivedCell(cell) {
        const bgColor = cell.original.rma_item_count > cell.value ? '#df1a1ac4' : '#2cb92cc4';
        return (
          <div className="colored-number-cell" style={{backgroundColor: bgColor}}>
            <span>{cell.value}</span>
          </div>
        );
      },
      filterMethod: (filter: Filter, row: any) => {
        if (filter.value === 'all') return true;
        if (filter.value === 'red') return row.rma_item_count > row[filter.id];
        return row.rma_item_count <= row[filter.id];
      },
      Filter: function receivedFilter({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">Show All</option>
            <option value="red">Show Incomplete Only</option>
            <option value="green">Show Complete Only</option>
          </select>
        );
      },
    }, {
      Header: 'Analyzed Units',
      accessor: 'analyzed_count',
      width: 150,
      Cell: function analyzedCell(cell) {
        const bgColor = cell.original.received_count > cell.value ? '#df1a1ac4' : '#2cb92cc4';
        return (
          <div className="colored-number-cell" style={{backgroundColor: bgColor}}>
            <span>{cell.value}</span>
          </div>
        );
      },
      filterMethod: (filter: Filter, row: any) => {
        if (filter.value === 'all') return true;
        if (filter.value === 'red') return row.received_count > row[filter.id];
        return row.received_count <= row[filter.id];
      },
      Filter: function analyzedFilter({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">Show All</option>
            <option value="red">Show Incomplete Only</option>
            <option value="green">Show Complete Only</option>
          </select>
        );
      },
    }, {
      Header: 'Shipped Units',
      accessor: 'shipped_count',
      width: 150,
      Cell: function shippedUnitsCell(cell) {
        const bgColor = cell.original.received_count > cell.value ? '#df1a1ac4' : '#2cb92cc4';
        return (
          <div className="colored-number-cell" style={{backgroundColor: bgColor}}>
            <span>{cell.value}</span>
          </div>
        );
      },
      filterMethod: (filter: Filter, row: any) => {
        if (filter.value === 'all') return true;
        if (filter.value === 'red') return row.received_count > row[filter.id];
        return row.received_count <= row[filter.id];
      },
      Filter: function shippedFilter({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">Show All</option>
            <option value="red">Show Incomplete Only</option>
            <option value="green">Show Complete Only</option>
          </select>
        );
      },
    }, {
      Header: 'Product Types',
      accessor: 'parts',
      Cell: function partsCell(cell) {
        return (
          <div style={{paddingLeft: '0.25rem'}}>
            <span>{cell.value}</span>
          </div>
        );
      },
      filterMethod: (filter: Filter, row: any) => String(row[filter.id])
        .toLowerCase().includes(String(filter.value).toLowerCase()),
    }, { Header: 'Comments',
      accessor: 'comments',
      Cell: function commentsCell(cell) {
        return (
          <div className='comment-box'>
            <span className='comment'>{cell.value}</span>
            <button
              className="button-like-link"
              type="button"
              style={{paddingLeft: '5px'}}
              onClick={() => setEditingRma(cell.original)}>
              <i className="fa fa-pencil-alt" />
            </button>
          </div>
        );
      }}];

  return (
    <div className="rma-highlights-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <div className="is-flex is-fullwidth">
        <h1 className="page-title">
          RMA Summary
        </h1>
        <div className="level-item has-text-centered" style={{ flex: 1 }}>
          {metrics && (
            <div>
              <p className="title">
                <div>
                  <span>
                    {metrics.averageRMATurnaround90Day ?? 0}
                  </span>
                  <span className="thin-text">/</span>
                  <span>
                    {metrics.averageRMATurnaround60Day ?? 0}
                  </span>
                  <span className="thin-text">/</span>
                  <span>
                    {metrics.averageRMATurnaround30Day ?? 0}
                  </span>
                </div>
              </p>
              <p className="heading">Average RMA Received to Analysis Turnaround Time 90/60/30 in Days</p>
            </div>
          )}
        </div>
        {renderIfQuality(
          <Link to="/rma/new">
            <button className="button elev-1" type="button">
              <span>New RMA</span>
              <span className="icon is-small">
                <i className="fa fa-plus" />
              </span>
            </button>
          </Link>,
          user,
        )}
      </div>
      {!summary ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={summary}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultFilterMethod={filterAsString}
          showPagination={summary.length > 100}
          defaultPageSize={100}
        />
      )}

      <CommentsModal
        toggled={!!editingRma}
        key={editingRma ? editingRma.rma_number : null}
        entity={editingRma}
        closeAction={() => setEditingRma(null)}
        okAction={updateComments}
        title="Update Comments"
        confirmText="Update Comments"
      />
    </div>
  );
};

export default RmaHighlightsPage;
