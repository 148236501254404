import React from 'react';

import { formatDate } from '../../utils/format';
import { IprShipment } from '../../../../app/models/inventory';

const IprShipmentRecord: React.FC<IprShipment> = ({
  serial_number,
  entry_date_time,
  shipment_date,
  tracking,
  ipr_number,
  carrier,
  address,
  customer,
}: IprShipment) => {
  if (!serial_number) return null;
  const date_time = shipment_date || entry_date_time;
  return (
    <div className="shipping-record">
      <p className="heading">{formatDate(date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>Shipped in IPR #{ipr_number}</p>
        </div>
        <div className="message-body">
          <div className="level">
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Customer</p>
                <p className="is-size-6">{customer}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Carrier</p>
                <p className="is-size-6">{carrier}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Address</p>
                <p className="is-size-6">{address || 'NA'}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Tracking</p>
                <p className="is-size-6">
                  <a
                    href={`https://www.google.com/search?q=${tracking}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {tracking}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default IprShipmentRecord;
