import React, { useState, useEffect } from 'react';

import { fetchGladosVersion } from '../../actions/settings';

const Footer: React.FC = () => {
  const [version, setVersion] = useState<string>('None');
  useEffect(() => {
    (async () => {
      if (version === 'None') {
        try {
          const gladosVersion = await fetchGladosVersion();
          setVersion(gladosVersion);
        }
        catch (ex) {
          setVersion(ex.message);
        }
      }
    })();
  });
  return (
    <div className="vs_footer">
      <span>{version}</span>
      <span>&copy; {(new Date().getFullYear())} VoltServer Inc.</span>
      <a
        className="mls"
        href="http://voltserver.com"
        target="_blank"
        rel="noopener noreferrer">
        voltserver.com
      </a>
    </div>
  );
};

export default Footer;
