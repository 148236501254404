import React from 'react';

import { formatDate } from '../../utils/format';
import { CmShipment } from '../../../../app/models/inventory';

const CmShipmentRecord: React.FC<CmShipment> = ({
  serial_number,
  shipment_date,
  entry_date_time,
  manufacturer,
  po_number,
}: CmShipment) => {
  if (!serial_number) return null;

  const date_time = shipment_date || entry_date_time;
  return (
    <div>
      <p className="heading">{formatDate(date_time)}</p>
      <p>
        Shipped from {manufacturer}. PO #{po_number}
      </p>
    </div>
  );
};

export default CmShipmentRecord;
