/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import ScrapRecord from '../device/ScrapRecord';
import MrbAnalysisRecord from '../device/MrbAnalysisRecord';
import RmaAnalysisRecord from '../device/RmaAnalysisRecord';
import UnitNotesRecord from '../device/UnitNotesRecord';
import IpoRecord from '../device/IpoRecord';
import { Scrap, Analysis, UnitNote, IPO } from '../../../../app/models/inventory';
import { EVENT_TYPES } from '../../../../app/models/event';
import LoadingSpinner from '../shared/LoadingSpinner';

export interface FuzzyResult {
  type: string;
  data: Scrap | Analysis | UnitNote | IPO;
  date_time: Date;
}

interface IProps {
  results: FuzzyResult[];
}

const FuzzyResults: React.FC<IProps> =({
  results,
}: IProps) => {
  if (results === null) return <LoadingSpinner />;
  if (!results || results.length === 0) return <p> No notes match that search </p>;
  return (
    <div>
      { results.map(renderResult) }
    </div>
  );
};
function renderResult(result: FuzzyResult): JSX.Element {
  const { type, data } = result;
  let output;
  if (type === EVENT_TYPES.SCRAP) output = <ScrapRecord {...data as Scrap} />;
  else if (type === EVENT_TYPES.MRB_ANALYSIS) output = <MrbAnalysisRecord {...data as Analysis} />;
  else if (type === EVENT_TYPES.RMA_ANALYSIS) output = <RmaAnalysisRecord {...data as Analysis} />;
  else if (type === EVENT_TYPES.UNIT_NOTES) output = <UnitNotesRecord {...data as UnitNote} />;
  else if (type === EVENT_TYPES.IPO) output = <IpoRecord {...data as IPO} />;
  else return null;
  return <div className="search-result">{output}</div>;
}

export default FuzzyResults;
