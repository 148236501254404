import React from 'react';
import IPRItem from '@models/IPRItem';

interface Props {
  item: IPRItem;
  isRma: boolean;
  skus: string[];
  onChange: (item: IPRItem) => void;
  onDelete: () => void;
}

const IPRItemRowForm: React.FC<Props> = ({ item, isRma, skus, onDelete, onChange }: Props) => (
  <tr key={`item-${item.id}`}>
    <td>
      <div className="field">
        <div className="select">
          <select
            name="product"
            onChange={(e) => onChange(item.set('product', e.target.value))}
            value={item.product}>
            {skus.map((sku) => (
              <option key={sku} value={sku}>
                {sku}
              </option>
            ))}
            <option value="- other - specify in comments">
                Other (specify in comments)
            </option>
          </select>
        </div>
      </div>
    </td>
    <td>
      <div className="field">
        <div className="control">
          <input
            className="input"
            type="number"
            onChange={(e) => onChange(item.set('qty', e.target.value as any))}
            min={1}
            value={item.qty}
            style={{ width: '4.25rem' }}
          />
        </div>
      </div>
    </td>
    <td>
      <div className="field">
        <div className="control">
          <input
            className="input"
            onChange={(e) => onChange(item.set('rev', e.target.value))}
            type="text"
            value={item.rev}
            style={{ width: '3.5rem' }}
          />
        </div>
      </div>
    </td>
    <td>
      <div className="field">
        <div className="control">
          <input
            className="input"
            onChange={(e) => onChange(item.set('userComments', e.target.value))}
            type="text"
            value={item.userComments}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </td>
    {isRma && (
      <td>
        <div className="field">
          <div className="select">
            <select
              name="product"
              onChange={(e) => onChange(item.set('advancedReplacement', e.target.value === 'Yes'))}
              value={item.advancedReplacement ? 'Yes' : 'No'}>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>
      </td>
    )}
    <td style={{ verticalAlign: 'middle' }}>
      <button
        className="button remove-btn"
        type="button"
        onClick={onDelete}>
        <i className="fas fa-times-circle" />
      </button>
    </td>
  </tr>
);

export default IPRItemRowForm;
