import React, { useMemo } from 'react';
import { List } from 'immutable';

import { useSKUs } from '@hooks/skus';
import IPRItem from '@models/IPRItem';
import LoadingSpinner from '@components/shared/LoadingSpinner';
import IPRItemRowForm from '@components/ipr_entry/form/IPRItemRowForm';

interface Props {
  items?: List<IPRItem>;
  setItems: (items: List<IPRItem>) => void;
  isRma: boolean;
}

const IPRItemList: React.FC<Props> = ({ items, setItems, isRma }: Props) => {
  const { data: skus } = useSKUs();
  const skuList = useMemo(() => (skus ?? []).map((item) => item.sku.replace('SKU-', '')).sort(), [skus]);
  if (!skus)
    return <LoadingSpinner />;

  const addItem = (): void => {
    setItems(List(items).push(new IPRItem({
      id: null,
      product: 'TX550-01-VS',
      rev: '',
      qty: 1,
      advancedReplacement: false,
      userComments: '',
    })));
  };

  const removeItem = (index: number): void => {
    setItems(items.remove(index));
  };
  return (
    <div className="is-fullwidth">
      <table className="table item-table is-fullwidth">
        <thead>
          <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Rev</th>
            <th colSpan={isRma ? 1 : 2}>Comments</th>
            {isRma && <th colSpan={2}>Adv. Replacement</th>}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <IPRItemRowForm
              key={`item-${item.id}`}
              item={item}
              isRma={isRma}
              onChange={(iprItem) => setItems(items.set(index, iprItem))}
              onDelete={() => removeItem(index)}
              skus={skuList}
            />
          ))}
        </tbody>
      </table>
      <div className="buttons is-centered">
        <button
          className="button is-link is-rounded is-outlined"
          onClick={addItem}
          type="button">
          <span className="icon">
            <i className="fas fa-plus-circle" />
          </span>
          <span>Add Item</span>
        </button>
      </div>
    </div>
  );
};

export default IPRItemList;
