import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useNavBarContext } from '../NavBar';
import { IPOQuery } from '../../../../../../app/lib/ipo';
import * as IPOActions from '../../../../actions/ipos';
import LoadingSpinner from '../../LoadingSpinner';

interface Props {
  search: string;
}

const IPOResults: React.FC<Props> = ({ search }: Props) => {
  const { hidePanel } = useNavBarContext();
  const [results, setResults] = useState<IPOQuery[] | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('ipo', '').trim();
    if (!searchVal || searchVal.length === 0) return;
    IPOActions.search(searchVal).then(setResults);
  }, [search]);

  if (!results) return <LoadingSpinner />;
  if (results.length === 0) return <span className="mts">No matches found.</span>;
  return (
    <>
      {results.map((ipo) => (
        <Link
          onClick={hidePanel}
          key={ipo.ipo_number}
          className="search-result"
          to={`/search/ipo/${ipo.ipo_number}`}>
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">IPO #{ipo.ipo_number}</div>
            <div className="result-subtitle">{ipo.count} device(s)</div>
          </div>
          <span className="result-timestamp">
            {DateTime.fromISO(ipo.entry_date_time).toFormat('DD')}
          </span>
        </Link>
      ))}
    </>
  );
};

export default IPOResults;
