import React from 'react';

import { formatDate } from '../../utils/format';
import { getManufacturer } from '../../utils/manufacturers';
import { Receiving } from '../../../../app/models/inventory';

const InventoryRecord: React.FC<Receiving> = ({
  serial_number,
  manufacturer_name,
  entry_date_time,
  receive_date,
  status,
  po_number,
}: Receiving) => {
  if (!serial_number) return null;

  const date_time = receive_date || entry_date_time;
  return (
    <div>
      <p className="heading">{formatDate(date_time)}</p>
      <p>
        { status ? 'Received':'Rejected upon receiving'} from { getManufacturer(manufacturer_name) }. PO #{po_number}
      </p>
    </div>
  );
};

export default InventoryRecord;
