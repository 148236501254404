import React from 'react';

import DeviceRecord from './DeviceRecord';
import { EventRecord, RecordTypes } from '../../../../app/models/event';

interface IProps {
  serial_number: string;
  events: EventRecord<RecordTypes>[];
}

const DeviceTimeline: React.FC<IProps> = ({
  serial_number,
  events,
}: IProps) => {
  if (!serial_number) return;
  return (
    <>
      {events.map((event: EventRecord<RecordTypes>) => (
        <DeviceRecord
          type={event.type}
          serial_number={serial_number}
          record={event.data}
          key={`${event.type}-${event.date_time}`}
        />
      ))}
    </>
  );
};

export default DeviceTimeline;
