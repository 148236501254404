import http from './http';

import {
  Receiving,
  RmaItem,
  RmaUnserializedItem,
  RmaReceiving,
  RmaInventory,
} from '../../../app/models/inventory';
import { Part } from '../../../app/models/parts';

import Manufacturer from '../../../app/models/manufacturer';

/** Receiving **/

export async function submitReceivingCm(receivings: Receiving[]): Promise<void> {
  await http.post('/entry/receiving/cm', receivings);
}

export async function submitReceivingRma(receivingRma: RmaReceiving): Promise<void> {
  await http.post('/entry/receiving/rma', receivingRma);
}

/** RMA **/

export async function getRecentRmas(): Promise<RmaInventory[]> {
  const resp = await http.get('/entry/rma/recentRmas');
  return resp.data as RmaInventory[];
}

/**
 * Functions associated with RMA's.
 */

export async function createRma(rma: RmaInventory): Promise<void> {
  await http.post('/entry/rma', rma);
}

export async function getRma(rmaNumber: string): Promise<RmaInventory> {
  const resp = await http.get(`/entry/rma/${rmaNumber}`);
  return resp.data as RmaInventory;
}

export async function updateRma(rma: RmaInventory): Promise<void> {
  await http.put(`/entry/rma/${rma.rma_number}`, rma);
}

export async function getRmaItems(rmaNumber: string): Promise<RmaItem[]> {
  const resp = await http.get(`/entry/rma/${rmaNumber}/items`);
  return resp.data as RmaItem[];
}

/**
 * Functions associated with Serialized RMA items.
 */

export async function addRmaItem(rmaItem: RmaItem): Promise<void> {
  await http.post(`/entry/rma/${rmaItem.rma_number}/items`, rmaItem);
}

export async function updateRmaItem(rmaItem: RmaItem): Promise<boolean> {
  if (!rmaItem.entry_id) return true;
  const { status } = await http.put(`/entry/rma/${rmaItem.rma_number}/items/${rmaItem.entry_id}`, {...rmaItem});
  return status < 500;
}

export async function deleteRmaItem(rma: RmaInventory, entryId: string): Promise<void> {
  await http.delete(`/entry/rma/${rma.rma_number}/items/${entryId}`);
}

export async function getSerializedRmaItems(rmaNumber: string): Promise<RmaItem[]> {
  const resp = await http.get(`/entry/rma/${rmaNumber}/items/serialized`);
  return resp.data as RmaItem[];
}

/**
 * Functions associated with Unserialized items.
 */

export async function addUnserializedRmaItem(rmaItem: RmaUnserializedItem): Promise<void> {
  await http.post(`/entry/rma/${rmaItem.rma_number}/items/unserialized`, rmaItem);
}

export async function updateUnserializedRmaItem(rmaItem: RmaUnserializedItem): Promise<boolean> {
  if (!rmaItem.entry_id) return true;
  const { status } = await http.put(`/entry/rma/${rmaItem.rma_number}/items/unserialized/${rmaItem.entry_id}`, {...rmaItem});
  return status < 500;
}

export async function deleteUnserializedRmaItem(rma: RmaInventory, entryId: string): Promise<void> {
  await http.delete(`/entry/rma/${rma.rma_number}/items/unserialized/${entryId}`);
}

export async function getUnserializedRmaItems(rmaNumber: string): Promise<RmaUnserializedItem[]> {
  const resp = await http.get(`/entry/rma/${rmaNumber}/items/unserialized`);
  return resp.data as RmaUnserializedItem[];
}

/** General **/
export async function getCustomerNames(): Promise<string[]> {
  const resp = (await http.get('/entry/allCustomers')).data as string[];
  return resp;
}

export async function getAllParts(): Promise<Part[]> {
  const resp = await http.get('/entry/allParts');
  return resp.data as Part[];
}

export async function getAllManufacturers(): Promise<Manufacturer[]> {
  const resp = await http.get('/entry/allManufacturers');
  return resp.data as Manufacturer[];
}

export async function getSalesforceLink(caseNumber: string): Promise<string> {
  const resp = await http.get(`/entry/sfRedirect/${caseNumber}`);
  return resp.data.url as string;
}

export async function updateStatus(rmaNumber: string, status: string): Promise<number> {
  const resp = await http.put(`/rma/${rmaNumber}/status`, { status });
  return resp.data;
}
