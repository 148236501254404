import React, { useState, useEffect } from 'react';
import { Route, Switch, match } from 'react-router';
import { History, Location } from 'history';
import { Link } from 'react-router-dom';

import { usePageTitle } from '@hooks/common';
import { renderIfEngOrOp, renderIfOpOrAccounting, renderIfQuality } from '@utils/permissions';
import { IUser } from '@models/User';

import DevicePage from '../device/DevicePage';
import RmaPage from '../rma/RmaPage';
import PoPage from '../po/PoPage';
import IprPage from '../ipr/IprPage';
import IpoPage from '../ipo/IpoPage';
import FuzzyResults, { FuzzyResult } from './FuzzyResults';
import MetricsLevel from '../metrics/MetricsLevel';

import * as DeviceApi from '../../actions/device';
import * as RmaApi from '../../actions/rma';
import fetchNotes from '../../actions/notes';
import * as MetricsApi from '../../actions/metrics';
import * as PosApi from '../../actions/pos';
import fetchIPR from '../../actions/iprs';
import fetchIPOs from '../../actions/ipos';

import { DeviceMetric, IDevice } from '../../../../app/models/device';
import { IRma } from '../../../../app/models/rma';
import { IprShipment, SummaryResult, IprSummary, IPO, PoShipment, PoSummary } from '../../../../app/models/inventory';

type SearchData = IDevice | IRma | SummaryResult<IprShipment, IprSummary> | IPO[] |
  SummaryResult<PoShipment, PoSummary> | FuzzyResult[] | any[];

interface IProps {
  user: IUser;
  match: match<{input: string; type: string}>;
  history: History;
  location: Location;
}

const SearchPage: React.FC<IProps> = ({user, match: { params }, history, location}: IProps) => {
  const [input, setInput] = useState<string>(params.input);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [data, setData] = useState<SearchData | null>(null);
  const [metrics, setMetrics] = useState<DeviceMetric>(null);
  const [type, setType] = useState<string>(params.type);

  usePageTitle('Search');

  const fetchData = async (searchType = type, searchInput = input): Promise<void> => {
    const fetch = getFetchType(searchType);
    if (!searchInput) return;
    setSubmitting(true);

    try {
      const result = await fetch(searchInput);
      setData(result);
      setSubmitted(true);
      setSubmitting(false);
    }
    catch (err) {
      setData(undefined);
      setSubmitted(true);
      setSubmitting(false);
      throw err;
    }
  };

  useEffect(() => {
    setType(params.type);
    setInput(params.input);
    setData(null);
    fetchData(params.type, params.input);
  }, [location]);

  useEffect(() => {
    fetchData();
    MetricsApi.fetchStandard().then(result => setMetrics(result));
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setType(e.target.value);
    setSubmitted(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInput(e.target.value);
    setSubmitted(false);
  };

  const handleSubmit = (e: React.MouseEvent | React.FormEvent): void => {
    e.preventDefault();
    history.push(`/search/${type}/${input}`);
    setData(null);
  };

  return (
    <section className="section">
      <div>
        <MetricsLevel {...metrics} shouldNotColor /> {/* eslint-disable-line react/jsx-props-no-spreading */}
        <div className="level">
          <div className="level-left" />
          <div className="level-right">
            <div className="level-item">
              <a href="/reports/rma.xlsx" className="button is-link elev-1" download>
                <span className="icon">
                  <i className="fa fa-download" />
                </span>
                <span>Download RMA Report</span>
              </a>
            </div>

            {renderIfEngOrOp(
              <div className="level-item">
                <a href="/reports/mrb.xlsx" className="button is-link elev-1" download>
                  <span className="icon">
                    <i className="fa fa-download" />
                  </span>
                  <span>Download MRB Report</span>
                </a>
              </div>,
              user,
            )}

            {type === 'rma' && renderIfQuality(
              <Link to={`/rma/${input}`}>
                <button className="button" type="button">
                  Edit
                </button>
              </Link>,
              user,
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="field has-addons elev-1">
            <div className="control has-icons-right serial-input">
              <input
                className="input"
                type="text"
                placeholder={getInputPlaceholder(type)}
                value={input}
                onChange={handleChange}
              />
              <span className="icon is-small is-right" style={{zIndex:0}}>
                <i className={`fa fa-check ${submitted ? 'has-text-success' : ''}`} />
              </span>
            </div>
            <p className="control">
              <span className="select">
                <select className="is-addon-right" value={type} onChange={handleSelectChange}>
                  <option value="devices">Serial Numbers</option>
                  <option value="rma">RMAs</option>
                  <option value="ipr">IPRs</option>
                  <option value="ipo">IPOs</option>
                  <option value="notes">Notes</option>
                  {renderIfOpOrAccounting(<option value="po">POs</option>, user)}
                </select>
              </span>
            </p>
            <div className="control">
              <button className={`button is-info ${submitting ? 'is-loading': ''}`} onClick={handleSubmit} type="button">
                Search
              </button>
            </div>
          </div>
        </form>
        {!!input && (
          <Switch>
            <Route
              path="/search/devices"
              render={() => (<DevicePage device={data as IDevice} fetchData={fetchData} />)}
            />
            <Route
              path="/search/rma"
              render={() => (<RmaPage rma={data as IRma} />)}
            />
            <Route
              path="/search/ipr"
              render={() => (<IprPage ipr={data as SummaryResult<IprShipment, IprSummary>} />)}
            />
            <Route
              path="/search/ipo"
              render={() => (<IpoPage ipo={data as IPO[]} />)}
            />
            <Route
              path="/search/notes"
              render={() => (<FuzzyResults results={data as FuzzyResult[]} />)}
            />
            {renderIfOpOrAccounting(
              <Route
                path="/search/po"
                render={() => (<PoPage po={data as SummaryResult<PoShipment, PoSummary>} />)}
              />,
              user,
            )}
          </Switch>
        )}
      </div>
    </section>
  );
};

function getFetchType(type: string): (val: string) => Promise<any> {
  switch (type) {
    case 'devices':
      return DeviceApi.fetch;
    case 'rma':
      return RmaApi.fetch;
    case 'notes':
      return fetchNotes;
    case 'po':
      return PosApi.fetch;
    case 'ipr':
      return fetchIPR;
    case 'ipo':
      return fetchIPOs;
    default:
      return () => new Promise(resolve => resolve([]));
  }
}

function getInputPlaceholder(type: string): string {
  switch (type) {
    case 'devices':
      return 'Enter a devices serial number';
    case 'rma':
      return 'Enter a RMA number';
    case 'notes':
      return 'Enter your search query';
    case 'po':
      return 'Enter a PO number';
    case 'ipr':
      return 'Enter an IPR number';
    case 'ipo':
      return 'Enter an IPO number';
    default:
      return 'Enter your search query';
  }
}

export default SearchPage;
