import React from 'react';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';

import { IUser, User } from '@models/User';
import { useIPR } from '@actions/ipr_entries';
import { useUser } from '@hooks/user';
import IPRFormPage from '@components/ipr_entry/form/IPRFormPage';
import IPRDisplay from '@components/ipr_entry/display/IPRDisplay';
import LoadingSpinner from '@components/shared/LoadingSpinner';
import IPRTimeline from '@components/ipr_entry/IPRTimeline';

import { usePageTitle } from '@hooks/common';

import { IPRState } from '../../../../app/models/ipr_entries';

type PathParams = { iprNum: string };

interface Props {
  user: IUser;
}

const IPRPage: React.FC<Props> = ({ user }: Props) => {
  const { iprNum } = useParams<PathParams>();
  usePageTitle(`IPR #${iprNum}`);

  const { data: ipr, revalidate } = useIPR(iprNum);
  const { data: creator } = useUser(ipr?.createdBy, { shouldRetryOnError: false });

  if (!ipr || !creator)
    return <LoadingSpinner />;

  const showForm = ipr.state === IPRState.UNSUBMITTED && user.profile.mail === ipr.createdBy;
  return (
    <div className="ipr-form">
      <h1 className="page-title">
        Internal Product Request
      </h1>
      {showForm && (
        <p className="subtitle">
          Created by&nbsp;
          <b>{creator.displayName ?? ipr.createdBy.toLowerCase()}</b> on&nbsp;
          <b>{DateTime.fromISO(ipr.createdDatetime, { zone: 'UTC' }).toFormat('DDD')}</b>
        </p>
      )}

      <IPRTimeline state={ipr.state} />

      {showForm ? (
        <IPRFormPage creator={creator} ipr={ipr} user={new User(user)} refresh={revalidate} />
      ) : (
        <IPRDisplay creator={creator} ipr={ipr} user={new User(user)} refresh={revalidate} />
      )}
    </div>
  );
};

export default IPRPage;
