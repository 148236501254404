import React from 'react';

import { Part } from '../../../../app/models/parts';

interface IEntryCheckboxProps {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EntryCheckbox = (props: IEntryCheckboxProps): JSX.Element => {
  const {
    name,
    checked,
    disabled,
    onChange,
  } = props;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              type="checkbox"
              className="elev-1"
              style={{marginTop: '1em'}}
              disabled={disabled}
              checked={checked}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EntryCheckbox.defaultProps = {
  disabled: false,
};

interface IEntryTextboxProps {
  name: string | JSX.Element;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  fieldWidth?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  helperText?: string;
}

export const EntryTextbox = (props: IEntryTextboxProps): JSX.Element => {
  const {
    name,
    value,
    onChange,
    onKeyUp,
    disabled,
    readOnly,
    placeholder,
    fieldWidth,
    helperText,
  } = props;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control elev-1 mbs" style={{width: fieldWidth}}>
            <input
              disabled={disabled ?? false}
              readOnly={readOnly ?? false}
              className="input"
              type="text"
              onKeyUp={onKeyUp}
              onChange={onChange}
              placeholder={typeof name === 'string' ? name : placeholder}
              value={value}
            />
          </div>
          {helperText ? (<span className="helper">{helperText}</span>) : <div />}
        </div>
      </div>
    </div>
  );
};

EntryTextbox.defaultProps = {
  disabled: false,
  readOnly: false,
  placeholder: 'RMA Entry',
  fieldWidth: '2rem',
  onKeyUp: (): any => null,
};

interface IEntryTextAreaProps {
  name: string;
  value: string;
  rows?: number;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  helperText?: string;
}

export const EntryTextArea = (props: IEntryTextAreaProps): JSX.Element => {
  const {
    name,
    value,
    rows,
    onChange,
    disabled,
    helperText,
  } = props;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control elev-1">
            <textarea
              className="textarea"
              value={value}
              rows={rows ?? 6}
              onChange={onChange}
              disabled={disabled ?? false}
            />
          </div>
          {helperText ? (<span className="helper">{helperText}</span>) : <div />}
        </div>
      </div>
    </div>
  );
};

EntryTextArea.defaultProps = {
  rows: 6,
  disabled: false,
};

interface IEntrySelectProps {
  name: string;
  value: string;
  options: string[];
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const EntrySelect = (props: IEntrySelectProps): JSX.Element => {
  const {
    name,
    value,
    options,
    disabled,
    onChange,
  } = props;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <div className="select">
              <select
                className="elev-1"
                disabled={disabled ?? false}
                value={value}
                defaultValue={options[0]}
                onChange={onChange}>
                <option disabled>~~Select One~~</option>
                {options.map((opt: string) => <option key={opt} value={opt}>{opt}</option>)}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EntrySelect.defaultProps = {
  disabled: false,
};

interface IEntryNotification {
  body: JSX.Element | JSX.Element[];
  notificationType: string;
  onClose: () => void;
}

export const EntryNotification = (props: IEntryNotification): JSX.Element => {
  const {
    body,
    notificationType,
    onClose,
  } = props;
  return (
    <div className={`notification ${notificationType}`}>
      <button type="button" aria-label="delete" className="delete" onClick={onClose} />
      {body}
    </div>
  );
};

interface IEntryDate {
  name: string;
  value: Date;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EntryDate = (props: IEntryDate): JSX.Element => {
  const {
    name,
    value,
    disabled,
    onChange,
  } = props;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              disabled={disabled ?? false}
              value={value.toISOString().substring(0, 10)}
              onChange={onChange}
              type="date"
              className="elev-1 input"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EntryDate.defaultProps = {
  disabled: false,
};

type SerialData = {
  part: string;
  jmaBranded: boolean;
}

interface IEntryComboBox {
  name: string;
  options: string[];
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fieldWidth?: string;
  placeholder?: string;
  helperText?: string;
}

export const EntryComboBox = (props: IEntryComboBox): JSX.Element => {
  const {
    name,
    options,
    value,
    disabled,
    onChange,
    fieldWidth,
    placeholder,
    helperText,
  } = props;
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <h3 className="label">{name}</h3>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control elev-1" style={{width: fieldWidth}}>
            <input
              list={`${name}Options`}
              disabled={disabled ?? false}
              className="input"
              type="text"
              onChange={onChange}
              placeholder={typeof name === 'string' ? name : placeholder}
              value={value}
            />
            <datalist id={`${name}Options`}>
              {options && options.map(option => (<option key={option} label={option} value={option} />))}
            </datalist>
          </div>
          {helperText ? (<span className="helper">{helperText}</span>) : <div />}
        </div>
      </div>
    </div>
  );
};

/**
 * Get information encoded in serial number
 * @param serialNum Serial number to check against
 * @param allParts List of parts to check for the serial number in
 * @returns Info derived from the serial number, null if no information could be found.
 */
export function serialToPart(serialNum: string, allParts: Part[]): SerialData {
  const serialPrefix = serialNum.substring(0, 4);

  for (const part of allParts) {
    if (part.serial_prefix_vs === serialPrefix
      || part.serial_prefix_vs_new === serialPrefix) {
      return {
        part: part.part_name,
        jmaBranded: false,
      } as SerialData;
    }
    if (part.serial_prefix_jma === serialPrefix
      || part.serial_prefix_jma_new === serialPrefix) {
      return {
        part: part.part_name,
        jmaBranded: true,
      } as SerialData;
    }
  }

  return null;
}
