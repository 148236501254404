import React from 'react';

import { useSFCaseLink, useSFContactInfo } from '@hooks/rma';

import { IRma } from '../../../../app/models/rma';

interface IProps extends IRma {
  numReceived: number;
  numShipped: number;
}

const RmaInfo: React.FC<IProps> = ({
  customer,
  site,
  rma_number,
  notes,
  numReceived,
  numShipped,
  customer_analysis,
  status,
  comments,
  rma_type,
  requires_eng_analysis,
}: IProps) => {
  const { data: sfLink } = useSFCaseLink(rma_number);
  const { data: sfContact } = useSFContactInfo(rma_number);

  if (!rma_number) return null;
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          <span>RMA #: {rma_number}</span>
          {rma_type === 'SRA' && <span className="tag is-success mls">SRA</span>}
          {status && (
            <span className={`tag mls ${status === 'Closed' ? 'is-danger' : 'is-success'}`}>
              {status}
            </span>
          )}
          {requires_eng_analysis && <span className="tag mls is-link">Requires Engineering Analysis</span>}
          <a
            href={sfLink?.url || '#'}
            className={`mlm button is-small ${!sfLink ? 'inactiveLink' : ''} ${!sfLink ? 'is-loading' : ''}`}
            target="_blank"
            rel="noopener noreferrer">
            <span>{`SFDC${!sfLink ? ' Missing' : ''}`}</span>
            <span className="icon">
              <i className="fa fa-external-link-alt" />
            </span>
          </a>
        </p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <h3 className="vs-title">{customer || 'Unknown customer'}</h3>
              <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                <li><p><span className="icon"><i className="fa fa-building" /></span>{site || 'Unknown site'}</p></li>
                {sfContact && (
                  <>
                    {sfContact.contactInfo.name && (
                      <li>
                        <p>
                          <span className="icon"><i className="fa fa-user" /></span>
                          {sfContact.contactInfo.name}
                        </p>
                      </li>
                    )}
                    {sfContact.contactInfo.email && (
                      <li>
                        <a href={`mailto:${sfContact.contactInfo.email}`}>
                          <span className="icon"><i className="fa fa-envelope" /></span>
                          {sfContact.contactInfo.email}
                        </a>
                      </li>
                    )}
                    {sfContact.contactInfo.phone && (
                      <li>
                        <a href={`tel:+${sfContact.contactInfo.phone}`}>
                          <span className="icon"><i className="fa fa-phone" /></span>
                          {sfContact.contactInfo.phone}
                        </a>
                      </li>
                    )}
                  </>
                )}
              </ul>
              <p>
                <span className="has-text-weight-semibold">Shipped/Received</span>
                <span className={`tag mls is-medium ${numShipped >= numReceived ? 'is-success':'is-danger'}`}>{numShipped} / {numReceived}</span>
              </p>
            </div>
            <div className="column">
              <p className="pre-wrap">
                <span className="vs-subtitle">Notes</span><br />
                {notes || 'None'}
              </p>
              { customer_analysis
              && (
                <p>
                  <span className="vs-subtitle">Customer Analysis</span><br />
                  <a href={customer_analysis.analysis_url} target="_blank" rel="noopener noreferrer">
                    {customer_analysis.analysis_url}
                  </a>
                </p>
              ) }
              { comments
              && (
                <p className="pre">
                  <span className="vs-subtitle">Comments</span><br />
                  {comments}
                </p>
              ) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RmaInfo;
