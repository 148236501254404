import React, { useState, useEffect } from 'react';
import ReactTable, { Filter, Column } from 'react-table';
import { Location, History } from 'history';

import { usePageTitle } from '@hooks/common';

import * as Inventory from '../../actions/inventory';
import { updateFilteredQueryString } from '../../utils/table_helpers';
import { InventoryCount } from '../../../../app/models/views';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
}

const InventoryPage: React.FC<IProps> = ({location: { search }, history}: IProps) => {
  usePageTitle('Inventory');

  const [inventory, setInventory] = useState<InventoryCount[] | null>(null);
  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  useEffect(() => {
    if (inventory === null) Inventory.fetch().then(result => setInventory(result));
  }, []);

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{
    Header: 'Product',
    accessor: 'part_number',
  }, { Header: 'JMA',
    accessor: 'jma_branded',
    Cell: function jmaBranded(cell) {
      return (
        <span>{cell.value ? 'Yes' : 'No'}</span>
      );
    },
    filterMethod: (filter: Filter, row: any) => {
      if (filter.value === 'all') return true;
      return filter.value === String(row[filter.id]);
    },
    Filter: function filterJMA({ filter, onChange }) {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="true">Show JMA Only</option>
          <option value="false">Show VS Only</option>
        </select>
      );
    }}, {
    Header: 'Count',
    accessor: 'count',
    Filter: () => null,
  }];

  return (
    <div className="is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <h1 className="page-title">Inventory</h1>
      {inventory === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={inventory}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultFilterMethod={(filter, row) => String(row[filter.id])
            .toLowerCase().includes(filter.value.toLowerCase())}
          showPagination={inventory.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default InventoryPage;
