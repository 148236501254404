import React from 'react';
import { List } from 'immutable';
import ReactTable, { Column, Filter } from 'react-table';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { emailToName, formatDate } from '@utils/format';
import { filterAsString, filterDatesMed } from '@utils/table_helpers';
import IPR from '@models/IPR';

interface Props {
  iprs?: List<IPR>;
}

const IPRTable: React.FC<Props> = ({ iprs }: Props) => {
  const columns: Column[] = [
    {
      Header: 'IPR #',
      accessor: 'iprNum',
      Cell: function changeCell({value}) {
        return (
          <Link to={`/iprs/${value}`} rel="noopener noreferrer">
            {value}
          </Link>
        );
      },
    },
    {
      Header: 'RMA #',
      accessor: 'rmaNumber',
      Cell: function changeCell(cell) {
        return cell.value ? <Link to={`/search/rma/${cell.value}`}>{cell.value}</Link> : null;
      },
    },
    {
      Header: 'Requested By',
      accessor: 'createdBy',
      Cell(cell) {
        return emailToName(cell.value);
      },
    },
    {
      Header: 'Created Date',
      accessor: 'createdDatetime',
      Cell: function changeCell(cell) {
        return <p>{formatDate(cell.value, DateTime.DATE_MED)}</p>;
      },
      filterMethod: filterDatesMed,
    },
    {
      Header: 'Customer',
      accessor: 'customer',
    },
    {
      Header: 'state',
      accessor: 'state',
      Filter: function filterState({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">All</option>
            <option value="unsubmitted">unsubmitted</option>
            <option value="submitted">submitted</option>
            <option value="rejected">rejected</option>
            <option value="approved">approved</option>
            <option value="pending">pending</option>
            <option value="shipped">shipped</option>
            <option value="completed">completed</option>
          </select>
        );
      },
      filterMethod: (filter: Filter, row: { state: string; inventory_approver: string }) => {
        if (filter.value === 'all') return true;
        return filter.value === row.state;
      },
    },
    {
      Header: 'Accounting Approved',
      accessor: 'accountingApprover',
      Cell: function changeCell(cell) {
        const accountingColor = cell.value === null || cell.value === '' ? '#df1a1ac4' : '#64a70b';
        return (
          <div style={{ color: accountingColor }}>
            <span>{cell.value === null || cell.value === '' ? 'No' : 'Yes'}</span>
          </div>
        );
      },
      Filter: function filterAccountApprove({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">All</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
      filterMethod: (filter: Filter, row: { [x: string]: string }) => {
        if (filter.value === 'all') return true;
        if (filter.value === 'Yes') return row[filter.id] !== '';
        if (filter.value === 'No') return row[filter.id] !== '';
        return false;
      },
    },
    {
      Header: 'Shipping Approved',
      accessor: 'inventoryApprover',
      Cell: function changeCell(cell) {
        const shipApproveCol = cell.value === null || cell.value === '' ? '#df1a1ac4' : '#64a70b';
        return (
          <div style={{ color: shipApproveCol }}>
            <span>{cell.value === null || cell.value === '' ? 'No' : 'Yes'}</span>
          </div>
        );
      },
      Filter: function filterShipApprove({ filter, onChange }) {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value="all">All</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
      filterMethod: (filter: Filter, row: { [x: string]: string }) => {
        if (filter.value === 'all') return true;
        if (filter.value === 'Yes') return row[filter.id] !== '';
        if (filter.value === 'No') return row[filter.id] === '';
        return false;
      },
    },
    {
      Header: 'Shipped Date',
      accessor: 'shipDate',
      Cell: function changeCell(cell) {
        return (
          <p>{cell.value !== null ? formatDate(cell.value, DateTime.DATE_MED) : ''}</p>
        );
      },
      filterMethod: (filter: Filter, row: { [x: string]: string | number | Date }) => {
        const date = new Date(row[filter.id]).toLocaleDateString('en-US', { timeZone: 'UTC' });
        return date.startsWith(filter.value);
      },
    },
  ];

  return (
    <ReactTable
      minRows={2}
      style={{ flex: 1, overflowY: 'auto' }}
      className="-striped -highlight"
      columns={columns}
      filterable
      data={iprs?.toJS() ?? []}
      defaultSorted={[{ id: 'ipr_num', desc: true }]}
      defaultFilterMethod={filterAsString}
      showPagination={(iprs?.size || 0) > 100}
      defaultPageSize={100}
    />
  );
};

export default IPRTable;
