import http from './http';

import { UserSettings } from '../../../app/models/settings';
import { VersionInformation } from '../../../app/utils/changelog';

const BASE_URL = '/settings';

export async function fetchUserSettings(uuid: string): Promise<UserSettings> {
  try {
    const { data } = await http.get(`${BASE_URL}/${uuid}`);
    return data;
  }
  catch (ex) {
    return {
      uuid,
      show_whats_new: false,
      last_version: new Date(),
    };
  }
}

export async function updateUserSettings(settings: UserSettings): Promise<void> {
  return http.post(`${BASE_URL}/update`, { settings });
}

export async function fetchGladosVersion(): Promise<string> {
  const { data } = await http.get(`${BASE_URL}/version`);
  return data.version;
}

export async function fetchRecentChanges(): Promise<VersionInformation> {
  const url = `${BASE_URL}/changes?recent=true`;
  const { data } = await http.get(url);
  return data.changes;
}

export async function fetchChangeAfter(date: string): Promise<VersionInformation> {
  const url = `${BASE_URL}/changes?after=${date}`;
  const { data } = await http.get(url);
  return data.version;
}

export async function fetchChangeBefore(date: string): Promise<VersionInformation> {
  const url = `${BASE_URL}/changes?before=${date}`;
  const { data } = await http.get(url);
  return data.version;
}

export async function throwBackendError(): Promise<void> {
  const url = `${BASE_URL}/error`;
  await http.get(url);
}
