import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { List } from 'immutable';

import IPR, { IIPR } from '@models/IPR';
import { camelCaseKeys } from '@utils/format';

import http from './http';
import { IIPREntry } from '../../../app/models/ipr_entries';

export async function fetchall(): Promise<IIPREntry[]> {
  const {data} = await http.get('/iprs');
  return data;
}

export async function fetch(ipr_num: number): Promise<IIPREntry> {
  const {data} = await http.get(`/iprs/${ipr_num}`);
  return data;
}

export function useIPR(id?: number | string, config?: SWRConfiguration): SWRResponse<IPR, AxiosError> {
  return useSWR<IPR, AxiosError>(
    id ? `/iprs/${id}` : null,
    (url: string) => http.get(url).then((resp) => new IPR(camelCaseKeys(resp.data) as IIPR)),
    config,
  );
}

export function useIPRs(config?: SWRConfiguration): SWRResponse<List<IPR>, AxiosError> {
  return useSWR<List<IPR>, AxiosError>(
    '/iprs',
    (url: string) => http.get(url).then((resp) => {
      const iprList = resp.data.map((ipr: any) => new IPR(camelCaseKeys(ipr) as IIPR));
      return List(iprList);
    }),
    config,
  );
}

export async function remove(ipr_num: number): Promise<void> {
  const {data} = await http.delete(`/iprs/${ipr_num}`);
  return data;
}

export async function add(body: IIPREntry): Promise<{ipr_num: number}> {
  const {data} = await http.post('/iprs', body);
  return data;
}
