import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import { Analysis } from '../../../../app/models/inventory';

const MrbAnalysisRecord: React.FC<Analysis> = ({
  serial_number,
  findings,
  pareto_category,
  pareto_details,
  entry_date_time,
  user_id,
}: Analysis) => {
  if (!serial_number) return null;
  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>MRB Analysis by {user_id || 'Unknown User'} to <Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></p>
        </div>
        <div className="message-body">
          <p>Pareto Category: {pareto_category}</p>
          <p>Pareto Details: {pareto_details}</p>
          <p>Notes: {findings} </p>
        </div>
      </article>
    </div>
  );
};

export default MrbAnalysisRecord;
