import React, { useState } from 'react';
import { match, Redirect, Route, Switch } from 'react-router';
import { Location } from 'history';

import NewIPRPage from '@components/ipr_entry/form/NewIPRPage';
import IPRList from '@components/ipr_entry/list/IPRList';
import { renderIfEng, renderIfEngOrOp, renderIfOpOrAccounting } from '@utils/permissions';
import { IUser, User } from '@models/User';

import SideBar from './shared/navbar/SideBar';
import DocumentsPage from './documents/DocumentsPage';
import IPRPage from './ipr_entry/IPRPage';
import DeviceConfigurationPage from './device_configurations/DeviceConfigurationPage';
import NavBar from './shared/navbar/NavBar';
import DocumentPage from './documents/DocumentPage';

import {
  CableCompatibilityPage,
  CompatibilityPage,
  FieldIssuesPage,
  HowToGetBetterPage,
  InventoryPage,
  MetricsPage,
  POSummaryPage,
  RMAEntryPage,
  RmaHighlightsPage,
  SearchPage,
  SKU2Page,
  SKUPage,
} from './index';

interface IProps {
  user: IUser;
  location: Location;
  history: History;
  match: match<{rma_number: string; ipr_num: string}>;
}

const Root: React.FC<IProps> = ({ location: { pathname }, user }: IProps) => {
  const basePath = pathname.split('/').slice(1)[0];
  const adjustedBasePath = basePath === 'channel' ? 'status' : basePath;
  const [showMenu, setShowMenu] = useState(true);
  return (
    <div style={{ display: 'flex', flexFlow: 'row', height: '100vh' }}>
      <SideBar activeTab={adjustedBasePath} user={user} open={showMenu} />
      <div style={{ display: 'flex', flexFlow: 'column', height: '100vh', width: '100%', overflowY: 'auto' }}>
        <NavBar user={user} toggleMenu={() => setShowMenu(!showMenu)} />
        <div className="main" style={{padding: '1rem 2rem', overflowY: 'auto'}}>
          <Switch>
            <Route path="/metrics" render={() => <MetricsPage />} />
            {renderIfEngOrOp(
              <Route
                path="/inventory"
                render={props => (
                  <InventoryPage
                    location={props.location}
                    history={props.history}
                  />
                )}
              />,
              user,
            )}
            {renderIfOpOrAccounting(
              <Route path="/po/" render={() => <POSummaryPage />} />,
              user,
            )}

            <Route path="/skus" render={() => <SKUPage />} />
            <Route path="/skus2" render={() => <SKU2Page />} />
            <Route
              path="/cable_compatibility"
              render={props => (
                <CableCompatibilityPage
                  location={props.location}
                  history={props.history}
                />
              )}
            />
            <Route
              path="/compatibility"
              render={props => (
                <CompatibilityPage
                  location={props.location}
                  history={props.history}
                />
              )}
            />
            <Route
              path="/search/:type/:input"
              render={props => (
                <SearchPage
                  location={props.location}
                  history={props.history}
                  user={user}
                  match={props.match}
                />
              )}
            />
            <Route
              path="/search/:type"
              render={props => (
                <SearchPage
                  location={props.location}
                  history={props.history}
                  user={user}
                  match={props.match}
                />
              )}
            />
            <Route
              path="/rma/summary"
              render={props => (
                <RmaHighlightsPage
                  location={props.location}
                  history={props.history}
                  user={user}
                />
              )}
            />
            <Route
              path="/deviceConfigurations"
              render={props => (
                <DeviceConfigurationPage
                  location={props.location}
                  history={props.history}
                  user={user}
                />
              )}
            />
            <Route
              path="/rma/:rma_number"
              render={props => (
                <RMAEntryPage
                  history={props.history}
                  match={props.match}
                />
              )}
            />
            <Route
              path="/field_issues"
              render={props => (
                <FieldIssuesPage
                  location={props.location}
                  history={props.history}
                  user={user}
                />
              )}
            />
            <Route path="/navi/:id" exact component={DocumentPage} />
            <Route
              path="/navi"
              render={props => (
                <DocumentsPage
                  location={props.location}
                  history={props.history}
                  user={user}
                />
              )}
            />
            {renderIfEng(
              <Route
                path="/how_to_get_better"
                render={props => (
                  <HowToGetBetterPage
                    location={props.location}
                    history={props.history}
                    user={user}
                  />
                )}
              />,
              user,
            )}
            <Route exact path="/iprs" render={() => <IPRList user={new User(user)} />} />
            <Route exact path="/iprs/new" render={() => <NewIPRPage user={new User(user)} />} />
            <Route path="/iprs/:iprNum" render={() => <IPRPage user={user} />} />
            <Route path="/documents" render={() => <Redirect to="/navi" />} />
            <Route path="/" render={() => <Redirect to="/search/devices" />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Root;
