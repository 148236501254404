import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable, { Filter, Column } from 'react-table';
import { Location, History } from 'history';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';
import { useInstallDetails } from '@hooks/device';
import { updateFilteredQueryString, filterAsString } from '@utils/table_helpers';
import { formatDate } from '@utils/format';
import { IUser } from '@models/User';

import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
  user: IUser;
}

const DeviceConfigurationPage: React.FC<IProps> = ({
  location: { search },
  history,
}: IProps) => {
  usePageTitle('Config Uploads');
  const { data: deviceConfigurations } = useInstallDetails();

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];
  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [
    {
      Header: 'Serial Number',
      accessor: 'serialNumber',
      Cell: function serialNumberCell(cell) {
        return <Link to={`/search/devices/${cell.value}`}>{cell.value}</Link>;
      },
    }, {
      Header: 'Device Type',
      accessor: 'deviceType',
      Cell: function deviceTypeCell(cell) {
        return (
          <div className="has-text-left">
            <span>{cell.value}</span>
          </div>
        );
      },
    }, {
      Header: 'Site',
      accessor: 'site',
      Cell: function siteCell(cell) {
        return (
          <div className="has-text-left">
            <span>{cell.value}</span>
          </div>
        );
      },
    }, {
      Header: 'Customer',
      accessor: 'customer',
      Cell: function customerCell(cell) {
        return (
          <div className="has-text-left">
            <span>{cell.value}</span>
          </div>
        );
      },
    }, {
      Header: 'Active Channels',
      accessor: 'activeChannels',
      Cell: function activeChannelsCell(cell) {
        return (
          <div className="has-text-centered">
            <span className="tag device-status is-success">{cell.value}</span>
          </div>
        );
      },
    }, {
      Header: 'Faulted Channels',
      accessor: 'faultedChannels',
      Cell: function faultedChannelsCell(cell) {
        return (
          <div className="has-text-centered">
            <span className={`tag device-status ${cell.value !== 0 ? 'is-danger' : 'is-white'}`}>
              {cell.value}
            </span>
          </div>
        );
      },
    }, {
      Header: 'Upload Date',
      accessor: 'uploadDate',
      Cell: function uploadDateCell(cell) {
        return (
          <div className="has-text-left">
            <span>{formatDate(cell.value, DateTime.DATE_MED)}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="device-configs is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <h1 className="page-title">Recent Configuration Uploads</h1>

      {deviceConfigurations === undefined ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={deviceConfigurations}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultFilterMethod={filterAsString}
          showPagination={deviceConfigurations.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default DeviceConfigurationPage;
