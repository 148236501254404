import { AxiosPromise } from 'axios';

import { IUser } from '@models/User';

import http from './http';

export function login(username: string, password: string): AxiosPromise<any> {
  return http.post('/login', {username, password});
}

export function logout(): AxiosPromise<any> {
  return http.get('/logout');
}

export async function fetchUser(): Promise<IUser> {
  const {data} = await http.get('/me');
  return data.user;
}
