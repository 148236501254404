import * as React from 'react';
import { Link } from 'react-router-dom';

import * as Entry from '@actions/rma_entry';

import { RmaItem, RmaUnserializedItem } from '../../../../app/models/inventory';
import {
  EntryCheckbox,
  EntryComboBox,
  EntryTextbox,
  EntrySelect,
} from './EntryMiscComponents';

const RMA_ITEM_ACTION_OPTIONS = [
  'Advance Replacement',
  'Repair and Return',
  'Analysis Only',
  'Stock Rotation',
  'None',
];

interface IUnserializedItemRmaEntryProps {
  partName: string;
  quantity: string;
  action: string;
  partNameError?: string;
  quantityError?: string;

  partNameList: string[];

  handlePartNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleActionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;

  submit: () => void;
  readonly: boolean;
}

export const UnserializedItemRmaEntryForm = (props: IUnserializedItemRmaEntryProps): JSX.Element => {
  const {
    partName,
    quantity,
    action,
    partNameError,
    quantityError,

    partNameList,

    handlePartNameChange,
    handleQuantityChange,
    handleActionChange,

    submit,
    readonly,
  } = props;

  if (readonly) {
    return (
      <div />
    );
  }

  return (
    <div>
      <div className="mvm">
        <EntryComboBox
          name="Part Name"
          value={partName}
          fieldWidth="20em"
          onChange={handlePartNameChange}
          options={partNameList}
          helperText={partNameError}
        />
      </div>

      <div className="mvm">
        <EntryTextbox
          name="Quantity"
          value={quantity}
          fieldWidth="20em"
          onChange={handleQuantityChange}
          helperText={quantityError}
        />
      </div>

      <div className="mvm">
        <EntrySelect
          name="Required Action"
          value={action}
          options={RMA_ITEM_ACTION_OPTIONS}
          onChange={handleActionChange}
        />
      </div>

      <div>
        <button
          type="button"
          className="button is-primary mrs"
          onClick={submit}>
          Add Unserialized Item
        </button>
      </div>
    </div>
  );
};

interface ISerializedItemRmaEntryProps {
  serialNumber: string;
  action: string;
  partName: string;
  jmaBranded: boolean;

  handleSerialNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleActionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handlePartNameChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleJmaBrandedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSerialNumberEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  partNames: string[];

  readonly: boolean;
  submit: () => void;
}

export const SerializedItemRmaEntryForm = (props: ISerializedItemRmaEntryProps): JSX.Element => {
  const {
    serialNumber,
    action,
    partName,
    jmaBranded,

    handleSerialNumberChange,
    handleActionChange,
    handlePartNameChange,
    handleJmaBrandedChange,
    handleSerialNumberEnter,

    partNames,

    readonly,
    submit,
  } = props;

  if (readonly) {
    return (
      <div />
    );
  }

  return (
    <div>
      <div className="mvm">
        <EntryTextbox
          name="Serial Number"
          value={serialNumber}
          fieldWidth="20em"
          onChange={handleSerialNumberChange}
          disabled={readonly}
          onKeyUp={handleSerialNumberEnter}
        />
      </div>

      <div className="mvm">
        <EntrySelect
          name="Part Name"
          value={partName}
          options={partNames}
          onChange={handlePartNameChange}
        />
      </div>

      <div className="mvm">
        <EntryCheckbox
          name="JMA Branded"
          checked={jmaBranded}
          onChange={handleJmaBrandedChange}
        />
      </div>

      <div className="mvm">
        <EntrySelect
          name="Required Action"
          value={action}
          options={RMA_ITEM_ACTION_OPTIONS}
          onChange={handleActionChange}
        />
      </div>

      <div>
        <button
          type="button"
          className="button is-primary mrs"
          onClick={submit}>
          Add Serialized Item
        </button>
      </div>
    </div>
  );
};

interface ISerializedRmaItemTable {
  readonly: boolean;
  allRmaItems: RmaItem[];
  deleteSerializedItem: (int: number) => void;
}

export const SerializedRmaTable = (props: ISerializedRmaItemTable): JSX.Element => {
  const {
    allRmaItems,
    readonly,
    deleteSerializedItem,
  } = props;

  if (readonly && allRmaItems.length === 0) return <div />;

  return (
    <table className="table is-fullwidth is-bordered mbm">
      <thead>
        <tr>
          <th>Serial Number</th>
          <th>Part</th>
          <th>Status</th>
          <th>Action</th>
          {readonly ? null : <th>Delete</th>}
        </tr>
      </thead>
      <tbody>
        {!readonly && allRmaItems.length === 0
          ? <tr><td /><td /><td /><td /><td /></tr>
          : allRmaItems.map((item: RmaItem) => (
            <SerializedItemTableRow
              key={item.entry_id}
              item={item}
              readonly={readonly}
              deleteSerializedItem={deleteSerializedItem}
            />
          ))}
      </tbody>
    </table>
  );
};

interface ISerializedItemTableRow {
  item: RmaItem;
  deleteSerializedItem: (int: number) => void;
  readonly: boolean;
}

const SerializedItemTableRow: React.FC<ISerializedItemTableRow> = ({
  item,
  deleteSerializedItem,
  readonly,
}: ISerializedItemTableRow) => {
  const [action, setAction] = React.useState<string>(item.action);
  return (
    <tr key={item.entry_id}>
      <td><Link to={`/search/devices/${item.serial_number}`}>{item.serial_number}</Link></td>
      <td>{item.part_number}</td>
      <td>{item.status}</td>
      <td>{(readonly || !item.entry_id) ? action : (
        <select
          style={{border: 'none', backgroundColor: 'white', borderRadius: '5px', fontSize: 'inherit'}}
          value={action}
          onChange={(evt) => {
            setAction(evt.target.value);
            Entry.updateRmaItem({
              ...item,
              action: evt.target.value,
            });
          }}>
          <option disabled>~~Select One~~</option>
          {RMA_ITEM_ACTION_OPTIONS.map((opt: string) => <option key={opt} value={opt}>{opt}</option>)}
        </select>
      )}
      </td>
      {readonly ? null : (
        <td>
          <div className="has-text-centered">
            <button
              type="button"
              className="has-text-danger"
              style={{ border: 'none', background: 'none' }}
              title="Delete"
              onClick={() => deleteSerializedItem(item.entry_id)}>
              <i className="fa fa-trash" />
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

interface IUnserializedRmaItemTable {
  readonly: boolean;
  allUnserializedItems: RmaUnserializedItem[];
  deleteUnserializedItem: (int: number) => void;
}

export const UnserializedRmaTable = (props: IUnserializedRmaItemTable): JSX.Element => {
  const {
    allUnserializedItems,
    readonly,
    deleteUnserializedItem,
  } = props;

  if (readonly && allUnserializedItems.length === 0) return <div />;

  return (
    <table className="table is-fullwidth is-bordered mbm">
      <thead>
        <tr>
          <th>Part</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>Action</th>
          {readonly ? null : <th>Delete</th>}
        </tr>
      </thead>
      <tbody>
        {!readonly && allUnserializedItems.length === 0
          ? <tr><td /><td /><td /><td /><td /></tr>
          : allUnserializedItems.map(item => (
            <UnserializedPartRow
              key={item.entry_id}
              item={item}
              deleteUnserializedItem={deleteUnserializedItem}
              readonly={readonly}
            />
          ))}
      </tbody>
    </table>
  );
};

interface IUnserializedPartRow {
  item: RmaUnserializedItem;
  deleteUnserializedItem: (int: number) => void;
  readonly: boolean;
}

const UnserializedPartRow: React.FC<IUnserializedPartRow> = ({
  item,
  deleteUnserializedItem,
  readonly,
}: IUnserializedPartRow) => {
  const [action, setAction] = React.useState<string>(item.action);
  return (
    <tr key={item.entry_id}>
      <td>{item.part_number}</td>
      <td>{item.quantity}</td>
      <td>{item.status}</td>
      <td>
        {(readonly || item.entry_id === null) ? action : (
          <select
            style={{border: 'none', backgroundColor: 'white', borderRadius: '5px', fontSize: 'inherit'}}
            value={action}
            onChange={(evt) => {
              setAction(evt.target.value);
              Entry.updateUnserializedRmaItem({
                ...item,
                action: evt.target.value,
              });
            }}>
            <option disabled>~~Select One~~</option>
            {RMA_ITEM_ACTION_OPTIONS.map((opt: string) => <option key={opt} value={opt}>{opt}</option>)}
          </select>
        )}
      </td>
      {readonly ? null : (
        <td>
          <div className="has-text-centered">
            <button
              type="button"
              className="has-text-danger"
              style={{ border: 'none', background: 'none' }}
              title="Delete"
              onClick={() => deleteUnserializedItem(item.entry_id)}>
              <i className="fa fa-trash" />
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};
