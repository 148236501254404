/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';

import { usePageTitle } from '@hooks/common';

import MetricsLevel from './MetricsLevel';
import * as Metrics from '../../actions/metrics';
import LoadingSpinner from '../shared/LoadingSpinner';

const MetricsPage: React.FC = () => {
  usePageTitle('Metrics');
  const [metrics, setMetrics] = useState(null);

  function renderMetrics(): JSX.Element[] {
    const result: JSX.Element[] = [];
    for (const metric in metrics) // eslint-disable-line guard-for-in
      result.push(<MetricsLevel showTotals {...metrics[metric]} title={metric} />);
    return result;
  }

  useEffect(() => {
    if (metrics === null) {
      Metrics.fetchDevices().then(result => {
        setMetrics(result);
      });
    }
  }, []);

  return (
    <section className="section">
      <div>
        {metrics === null ? <LoadingSpinner /> : renderMetrics()}
      </div>
    </section>
  );
};

export default MetricsPage;
