import * as React from 'react';
import { Link } from 'react-router-dom';

import { IDevice } from '../../../../app/models/device';
import ScrapModal from './ScrapModal';

const DeviceInfo: React.FC<IDevice> = ({
  serial_number,
  pca_serial_number,
  product_type,
  firmware_version,
  hardware_version,
  tested,
  mrb,
  ipo,
  rma,
  current_location,
  custom,
  jma,
  mac_address,
  tx_firmware_version,
  tx_hardware_version,
  mgt_firmware_version,
  mgt_hardware_version,
  mgt_gateway_version,
  scrapped,
  revision,
}: IDevice) => {
  if (!serial_number) return <p>Device not found</p>;

  const unitOrPca = (): string => {
    if (!pca_serial_number) return '';
    return serial_number === pca_serial_number ? 'PCA ' : 'Unit ';
  };

  return (
    <div className="card">
      <header className="card-header">
        <div className="level" style={{ width: '100%' }}>
          <div className="level-left">
            <div className="level-item">
              <p className="card-header-title">
                {unitOrPca()}Serial Number: {serial_number}
              </p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {!scrapped && <ScrapModal serial_number={serial_number} />}
            </div>
          </div>
        </div>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <h3 className="vs-title">{product_type ? product_type.toUpperCase() : 'Unknown device'}</h3>
              <p>
                <i className="fa fa-map-marker-alt" /> {current_location || 'Unknown location'}
              </p>
              {pca_serial_number && pca_serial_number !== serial_number && (
                <p>
                  PCA Serial Number: <Link to={`/search/devices/${pca_serial_number}`}>{pca_serial_number}</Link>
                </p>
              )}
              {scrapped && <span className="tag is-danger">Scrapped</span>}
            </div>
            <div className="column is-one-fifth highlight-column">
              <table className="table is-narrow">
                <tbody>
                  <tr>
                    <td>Tested:</td>
                    <td><span className={`tag ${tested ? 'is-success':'is-danger'}`}>{tested ? 'Yes' : 'No'}</span></td>
                  </tr>
                  <tr>
                    <td>RMA:</td>
                    <td><span className={`tag ${rma ? 'is-success':'is-danger'}`}>{rma ? 'Yes' : 'No'}</span></td>
                  </tr>
                  <tr>
                    <td>MRB:</td>
                    <td><span className={`tag ${mrb ? 'is-success':'is-danger'}`}>{mrb ? 'Yes' : 'No'}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="column is-one-fifth highlight-column">
              <table className="table is-narrow">
                <tbody>
                  <tr>
                    <td>IPO:</td>
                    <td><span className={`tag ${ipo ? 'is-success':'is-danger'}`}>{ipo ? 'Yes' : 'No'}</span></td>
                  </tr>
                  <tr>
                    <td>Custom:</td>
                    <td><span className={`tag ${custom ? 'is-success':'is-danger'}`}>{custom ? 'Yes' : 'No'}</span></td>
                  </tr>
                  <tr>
                    <td>JMA:</td>
                    <td><span className={`tag ${jma ? 'is-success':'is-danger'}`}>{jma ? 'Yes' : 'No'}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="column device-info-details">
              {tx_firmware_version && <p><b>TX Firmware Version:</b> {tx_firmware_version}</p>}
              {tx_hardware_version && <p><b>TX Hardware Version:</b> {tx_hardware_version}</p>}
              {mgt_firmware_version && <p><b>MGT Firmware Version:</b> {mgt_firmware_version}</p>}
              {mgt_hardware_version && <p><b>MGT Hardware Version:</b> {mgt_hardware_version}</p>}
              {mgt_gateway_version && <p><b>Software Version:</b> {mgt_gateway_version}</p>}
              {firmware_version && <p><b>Firmware Version:</b> {firmware_version}</p>}
              {hardware_version && <p><b>Hardware Version:</b> {hardware_version}</p>}
              {revision && <p><b>Revision:</b> {revision} </p>}
              {mac_address && <p><b>MAC:</b> {mac_address} </p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceInfo;
