import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {AxiosError} from 'axios';

import { SFContactInfo} from '@models/RMA';
import { DeviceMetric } from '../../../app/models/device';

import { axiosFetcher } from './common';
import { RmaSummaryView } from '../../../app/models/views';

export function useSFCaseLink(
  rmaNumber: string,
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<{ url: string }, AxiosError> {
  return useSWR<{ url: string }, AxiosError>(
    shouldFetch ? `/rma/${rmaNumber}/sfCaseLink` : null,
    axiosFetcher,
    config,
  );
}

export function useSFContactInfo(
  rmaNumber: string,
  shouldFetch: boolean = true,
  config?: SWRConfiguration,
): SWRResponse<SFContactInfo, AxiosError> {
  return useSWR<SFContactInfo, AxiosError>(
    shouldFetch ? `/rma/${rmaNumber}/sfContactInfo` : null,
    axiosFetcher,
    config,
  );
}

export function useRmaMetrics(metrics: DeviceMetric, config?: SWRConfiguration): SWRResponse<DeviceMetric, AxiosError> {
  return useSWR<DeviceMetric, AxiosError>(
    metrics ? null : `/metrics/standard`,
    axiosFetcher,
    config,
  );
}

export function useRmaSummary(summary: RmaSummaryView[], config?: SWRConfiguration): SWRResponse<RmaSummaryView[], AxiosError> {
  return useSWR<RmaSummaryView[], AxiosError>(
    summary ? null : `/rma/summary`,
    axiosFetcher,
    config,
  );
}

// export async function fetchSfLink(rmaNumber: string): Promise<string> {
//   const { data } = await http.get(`/rma/${rmaNumber}/sfCaseLink`);
//   return data.url;//
// }
//
// export async function fetchContactInfo(rmaNumber: string): Promise<Contact> {
//   const { data } = await http.get(`/rma/${rmaNumber}/sfContactInfo`);
//   return data.contactInfo;/
// }
