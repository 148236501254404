import React, { useState, useEffect } from 'react';

import { CompatibilityInfo } from '../../../../app/models/compatibility';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  okAction: (entity: CompatibilityInfo) => Promise<void>;
  title: string;
  confirmText: string;
  entity?: CompatibilityInfo;
}

const EditDocumentModal: React.FC<IProps> = ({
  toggled,
  closeAction,
  title,
  confirmText,
  okAction,
  entity,
}) => {
  const [compatibility, setCompatibility] = useState<CompatibilityInfo>(entity || {
    device_manufacturer: '',
    device_model: '',
    device_model_details: '',
    compatible: 'yes',
    tested_receiver: 'prx440',
    test_method: 'Field Test',
    comments: '',
    ntc_energy: 0,
    design_wattage: 0,
    min_number_of_input_pairs: 1,
    load_type: '',
    loads_per_rx_output: 1,
    test_report_link: '',
  });

  useEffect(() => {
    if (entity) setCompatibility(entity);
  }, [entity]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>): void => {
    setCompatibility({...compatibility, [e.target.name]: e.target.value});
  };

  const handleAdd = async (): Promise<void> => {
    await okAction(compatibility);
    setCompatibility(entity || {
      device_manufacturer: '',
      device_model: '',
      device_model_details: '',
      compatible: 'yes',
      tested_receiver: 'prx440',
      test_method: 'Field Test',
      comments: '',
      ntc_energy: 0,
      design_wattage: 0,
      min_number_of_input_pairs: 1,
      load_type: '',
      loads_per_rx_output: 1,
      test_report_link: '',
    });
    closeAction();
  };

  const {
    device_manufacturer,
    device_model,
    device_model_details,
    compatible,
    tested_receiver,
    test_method,
    design_wattage,
    ntc_energy,
    min_number_of_input_pairs,
    load_type,
    loads_per_rx_output,
    test_report_link,
    comments,
  } = compatibility;

  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" type="button" aria-label="close" onClick={closeAction} />
        </header>
        <section className="modal-card-body">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Manufacturer</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="device_manufacturer"
                    value={device_manufacturer}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Model</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="device_model"
                    value={device_model}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Model Details</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="device_model_details"
                    value={device_model_details}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">NTC Energy (J)</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="number"
                    name="ntc_energy"
                    value={ntc_energy}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Design Wattage (W)</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="number"
                    name="design_wattage"
                    value={design_wattage}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Min # DE Pairs</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select
                      name="min_number_of_input_pairs"
                      value={min_number_of_input_pairs}
                      onChange={handleChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Loads per RX Output</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="loads_per_rx_output" value={loads_per_rx_output} onChange={handleChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Load Type</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="load_type" value={load_type} onChange={handleChange}>
                      <option value="radio">Radio</option>
                      <option value="network_switch">Network Switch</option>
                      <option value="lighting">Lighting</option>
                      <option value="power_supply">Power Supply</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Test Report Link</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="test_report_link"
                    value={test_report_link}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Compatible</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="compatible" value={compatible} onChange={handleChange}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                      <option value="limitations">Limitations</option>
                      <option value="TBD">TBD</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Receiver</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="tested_receiver" value={tested_receiver} onChange={handleChange}>
                      <option value="prx440">PRX440</option>
                      <option value="rx550">RX550</option>
                      <option value="prx482">PRX482</option>
                      <option value="prx482att">PRX482 ATT</option>
                      <option value="rx548">RX548</option>
                      <option value="rx520h">RX520H</option>
                      <option value="rx554">RX554</option>
                      <option value="rx542k">RX54-2K</option>
                      <option value="rxac2m">RXAC-2M</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Test Method</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="test_method" value={test_method} onChange={handleChange}>
                      <option value="Field Test">Field Test</option>
                      <option value="Eng Simple Test">Eng Simple Test</option>
                      <option value="Eng Full Test">Eng Full Test</option>
                      <option value="Deduction">Deduction</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Comments</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <textarea className="textarea" name="comments" value={comments} onChange={handleChange} />
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleAdd} />
        </footer>
      </div>
    </div>
  );
};

export default EditDocumentModal;
