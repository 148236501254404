import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PoInfo from './PoInfo';
import { PoShipment, PoSummary, SummaryResult } from '../../../../app/models/inventory';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  po: SummaryResult<PoShipment, PoSummary>;
}

const PoPage: React.FC<IProps> = ({po}: IProps) => {
  const [sorter, setSorter] = useState<keyof PoShipment>('box_number');
  const [filter, setFilter] = useState<string>('');

  if (po === null) return <LoadingSpinner />;
  if (!po || !po.contents) return <p> No pos match that number </p>;

  const { contents, summary } = po;
  contents.sort((poA: any, poB: any) => {
    if (poA[sorter] < poB[sorter]) return -1;
    if (poA[sorter] > poB[sorter]) return 1;
    return 0;
  });

  const sortedContents = !filter.length ? contents : contents.filter((device) => device.part_number === filter);

  return (
    <div className="po-page">
      <PoInfo summary={summary} shipment={contents} />
      <br />
      <div className="is-pulled-right">
        <h3 className="label">Filter By:</h3>
        <div className="select">
          <select className="elev-1" onChange={(e) => setFilter(e.target.value)}>
            <option value="">No filter</option>
            {
              Object.keys(summary.counts).map((key) => (<option key={`filter-${key}`} value={key}>{key}</option>))
            }
          </select>
        </div>
      </div>
      <table className="table is-fullwidth po-table">
        <thead>
          <th className="clickable" onClick={() => setSorter('serial_number')}>Serial Number</th>
          <th className="clickable" onClick={() => setSorter('part_number')}>Type</th>
          <th className="clickable" onClick={() => setSorter('box_number')}>Box</th>
          <th>Carrier</th>
          <th>Tracking</th>
        </thead>
        <tbody>
          {sortedContents.map(renderTableRow)}
        </tbody>
      </table>
    </div>
  );
};

function renderTableRow(device: PoShipment): JSX.Element {
  const {
    serial_number,
    part_number,
    box_number,
    carrier,
    tracking,
  } = device;

  return (
    <tr>
      <td><Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></td>
      <td>{part_number}</td>
      <td>{box_number}</td>
      <td>{carrier}</td>
      <td>
        <a href={`https://www.google.com/search?q=${tracking}`} target="_blank" rel="noopener noreferrer">
          {tracking}
        </a>
      </td>
    </tr>
  );
}

export default PoPage;
