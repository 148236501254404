import React from 'react';
import { Link } from 'react-router-dom';

import { RmaItem } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';

interface IProps {
  notReceived: RmaItem[];
}

const NotReceivedTable: React.FC<IProps> = ({notReceived}: IProps) => {
  if ((!notReceived || notReceived.length === 0)) {
    return (
      <div>
        <h1 className="has-text-weight-semibold is-size-5">Units Not Received</h1>
        <p className="is-italic has-text-grey">No known unreceived units.</p>
      </div>
    );
  }

  return (
    <div>
      <h1 className="has-text-weight-semibold is-size-5">Units Not Received</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Type</th>
            <th>Date Issued</th>
            <th>JMA Branded</th>
          </tr>
        </thead>
        <tbody>
          { notReceived.map(renderNotReceivedTableRow) }
        </tbody>
      </table>
    </div>
  );
};

function renderNotReceivedTableRow(device: RmaItem): JSX.Element {
  const {
    entry_date_time,
    serial_number,
    part_number,
    jma_branded,
  } = device;

  return (
    <tr key={serial_number}>
      {serial_number === 'N/A' ? (<td />) : (<td><Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></td>)}
      <td>{part_number}</td>
      <td>{formatDate(entry_date_time)}</td>
      <td>{jma_branded ? 'Yes':'No'}</td>
    </tr>
  );
}

export default NotReceivedTable;
