import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import IPR from '@models/IPR';
import { capitalize, emailToName, formatDate } from '@utils/format';

import { IPRState } from '../../../../../app/models/ipr_entries';

interface Props {
  ipr: IPR;
}

const IPRCard: React.FC<Props> = ({ ipr }: Props) => {
  const history = useHistory();
  return (
    <div className="ipr-card mrm" onClick={() => history.push(`/iprs/${ipr.iprNum}`)}>
      <div className={classNames('ipr-card-header', `ipr-state-${ipr.state}`)}>
        <div className="ipr-card-title">
          <h1 className="ipr-num">#{ipr.iprNum}</h1>
          {ipr.isRma && <span className="rma-num">(RMA #{ipr.rmaNumber})</span>}
        </div>
        <span className="ipr-state">
          <i className={stateIcon(ipr.state)} />
          {capitalize(ipr.state)}
        </span>
      </div>
      <div className="ipr-card-body">
        <h1 className="ipr-customer mbs">{ipr.customer}</h1>
        {renderInfo(ipr)}
        <span className="created-by">
          Created by <b>{emailToName(ipr.createdBy)}</b> on {formatDate(ipr.createdDatetime)}
        </span>
      </div>
    </div>
  );
};

function renderInfo(ipr: IPR): JSX.Element {
  if (ipr.isUnsubmitted)
    return <IPRInfo state={ipr.state} title="Status" details="Not Yet Submitted" icon="clock" />;
  if (ipr.isCompleted || ipr.isShipped)
    return <IPRInfo state={ipr.state} title="Shipped on" details={formatDate(ipr.shipDate)} icon="shipping-fast" />;
  if (ipr.isSubmitted) {
    return (
      <div className="info-group">
        <IPRInfo state={ipr.state} title="Finance Approval" details="Pending" icon="clock" regular />
        <IPRInfo state={ipr.state} title="Inventory Approval" details="Pending" icon="clock" regular />
      </div>
    );
  }
  if (ipr.isPending) {
    if (ipr.isFinanceApproved) {
      return (
        <div className="info-group">
          <IPRInfo state={ipr.state} title="Finance Approval" details="Approved" icon="check-circle" />
          <IPRInfo state={ipr.state} title="Inventory Approval" details="Pending" icon="clock" regular />
        </div>
      );
    }
    return (
      <div className="info-group">
        <IPRInfo state={ipr.state} title="Finance Approval" details="Pending" icon="clock" regular />
        <IPRInfo state={ipr.state} title="Inventory Approval" details="Approved" icon="check-circle" />
      </div>
    );
  }
  if (ipr.isApproved) {
    return (
      <div className="info-group">
        <IPRInfo state={ipr.state} title="Finance Approval" details="Approved" icon="check-circle" />
        <IPRInfo state={ipr.state} title="Inventory Approval" details="Approved" icon="check-circle" />
      </div>
    );
  }
  if (ipr.isRejected) {
    if (ipr.accountingApprover) {
      return (
        <IPRInfo
          state={ipr.state}
          title="Finance Rejected"
          details={emailToName(ipr.accountingApprover)}
          icon="times-circle"
        />
      );
    }
    return (
      <IPRInfo
        state={ipr.state}
        title="Inventory Rejected"
        details={emailToName(ipr.inventoryApprover)}
        icon="times-circle"
      />
    );
  }
  return null;
}

interface InfoProps {
  state: IPRState;
  title: string;
  details: string;
  icon: string;
  regular?: boolean;
}
function IPRInfo({ state, title, details, icon, regular }: InfoProps): JSX.Element {
  return (
    <div className={classNames('ipr-info mbm', `ipr-state-${state}`)}>
      <span className="icon prm">
        <i className={classNames(`fa-${icon}`, regular ? 'far' : 'fas')} />
      </span>
      <div className="ipr-info-details">
        <span className="name">{title}</span>
        <span className="details">{details}</span>
      </div>
    </div>
  );
}

function stateIcon(state: string): string {
  switch (state) {
    case IPRState.UNSUBMITTED:
    case IPRState.SUBMITTED:
    case IPRState.PENDING:
      return 'fas fa-clock';
    case IPRState.APPROVED:
    case IPRState.COMPLETED:
      return 'fas fa-check-circle';
    case IPRState.REJECTED:
      return 'fas fa-times-circle';
    case IPRState.SHIPPED:
      return 'fas fa-shipping-fast';
    default:
      return '';
  }
}

export default IPRCard;
