import http from './http';
import { SummaryResult, IprShipment, IprSummary } from '../../../app/models/inventory';
import { IPRQuery } from '../../../app/lib/ipr';

async function fetchIPR(serial_number: string): Promise<SummaryResult<IprShipment, IprSummary>> {
  const {data} = await http.get(`/ipr/${serial_number}`);
  return data;
}

export async function search(query: string): Promise<IPRQuery[]> {
  const { data } = await http.get(`/ipr/search/${encodeURIComponent(query)}`);
  return data;
}

export default fetchIPR;
