import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import { getManufacturer } from '../../utils/manufacturers';
import { ProductionTestData } from '../../../../app/models/test_data';

const ProductionTestRecord: React.FC<ProductionTestData> = ({
  serial_number,
  unit_serial_number,
  pca_serial_number,
  result,
  entry_date_time,
  key_date_time,
  test_date_time,
  manufacturer_name,
  fail_message,
}: ProductionTestData) => {
  const isRxFlex = (serialNum: string): boolean => serialNum.startsWith('1093');

  const getLink = (): JSX.Element => {
    if (serial_number === unit_serial_number && pca_serial_number) {
      return (
        <Link to={`/search/devices/${pca_serial_number}`}>
          {pca_serial_number}
        </Link>
      );
    }
    return <Link to={`/search/devices/${unit_serial_number}`}>{unit_serial_number}</Link>;
  };

  const getSnType = (): string => {
    if (isRxFlex(serial_number)) return 'RX520';
    if (serial_number === unit_serial_number && pca_serial_number) return 'PCA';
    return 'Unit';
  };

  if (!serial_number) return null;

  const date_time = key_date_time || test_date_time || entry_date_time;

  return (
    <div>
      <p className="heading">{formatDateTime(date_time)}</p>
      <p>{result ? 'Passed' : 'Failed'} a production test at {getManufacturer(manufacturer_name)}
        {pca_serial_number && ` with ${getSnType()} serial `}
        {getLink()}
      </p>
      {fail_message && <pre className="fail-message">Fail Message: {fail_message}</pre>}
    </div>
  );
};

export default ProductionTestRecord;
