import React from 'react';
import { DateTime } from 'luxon';

import { User } from '@models/User';
import IPRTimeline from '@components/ipr_entry/IPRTimeline';
import IPRFormPage from '@components/ipr_entry/form/IPRFormPage';

import { usePageTitle } from '@hooks/common';

import { IPRState } from '../../../../../app/models/ipr_entries';

interface Props {
  user: User;
}

const NewIPRPage: React.FC<Props> = ({ user }: Props) => {
  usePageTitle('New IPR');

  const creator = user.profile;
  const createdBy = creator.displayName;
  const createdAt = DateTime.now().toFormat('DDD');

  return (
    <div className="ipr-form">
      <h1 className="page-title has-text-centered">
        Internal Product Request
      </h1>
      <p className="subtitle">
        Created by <b>{createdBy}</b> on <b>{createdAt}</b>
      </p>

      <IPRTimeline state={IPRState.UNSUBMITTED} />
      <IPRFormPage creator={creator} user={user} />
    </div>
  );
};

export default NewIPRPage;
