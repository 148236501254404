import React, { useState } from 'react';

import { IRma } from '../../../../app/models/rma';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  title: string;
  confirmText: string;
  okAction: (rma: IRma) => Promise<void>;
  entity: IRma;
}

const SetOutputField: React.FC<IProps> = ({
  toggled,
  closeAction,
  title,
  confirmText,
  entity,
  okAction,
}: IProps) => {
  const [rma, setRma] = useState<IRma>(entity || {
    comments: '',
    rma_number: null,
    rma_type: null,
    notes: '',
    customer: '',
    customer_analysis: null,
    site: '',
    shipped: null,
    newShipped: null,
    received: null,
    notReceived: null,
    items: [],
    requires_eng_analysis: false,
    status: 'Open',
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setRma({...rma, [e.target.name]: e.target.value});
  };

  const handleAdd = async (): Promise<void> => {
    await okAction(rma);

    setRma(entity || {
      comments: '',
      rma_number: null,
      rma_type: null,
      notes: '',
      customer: '',
      customer_analysis: null,
      site: '',
      shipped: null,
      newShipped: null,
      received: null,
      notReceived: null,
      items: [],
      requires_eng_analysis: false,
      status: 'Open',
    });
    closeAction();
  };

  const { comments } = rma;
  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" type="button" aria-label="close" onClick={closeAction} />
        </header>
        <section className="modal-card-body">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Comments</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <textarea className="textarea" name="comments" value={comments} onChange={handleChange} />
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleAdd} />
        </footer>
      </div>
    </div>
  );
};

export default SetOutputField;
