import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../utils/format';
import { RmaShipment } from '../../../../app/models/inventory';

const RmaShipmentRecord: React.FC<RmaShipment> = ({
  serial_number,
  entry_date_time,
  shipment_date,
  tracking,
  rma_number,
  box_number,
  carrier,
}: RmaShipment) => {
  if (!serial_number) return null;
  const date_time = shipment_date || entry_date_time;
  return (
    <div className="shipping-record">
      <p className="heading">{formatDate(date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>Shipped in RMA #<Link to={`/search/rma/${rma_number}`}>{rma_number}</Link></p>
        </div>
        <div className="message-body">
          <div className="level">
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Box</p>
                <p className="is-size-6">{box_number}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Carrier</p>
                <p className="is-size-6">{carrier}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Tracking</p>
                <p className="is-size-6">
                  <a
                    href={`https://www.google.com/search?q=${tracking}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {tracking}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default RmaShipmentRecord;
