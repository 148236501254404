import React from 'react';
import copy from 'copy-to-clipboard';

import { RmaShipment, RmaSummary } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';

interface IProps {
  summary: RmaSummary;
  shipment: RmaShipment[];
}

const RmaShippingInfo: React.FC<IProps> = ({
  summary: {
    customer,
    address,
    box_count,
    tracking,
    carrier,
    rma_number,
    counts,
  },
  shipment,
}: IProps) => {
  if (!rma_number) return null;

  let shipmentDate = '';
  if (shipment.length) shipmentDate = formatDate(shipment[0].shipment_date);
  return (
    <div className="card mbm">
      <header className="card-header">
        <p className="card-header-title">
          RMA Shipping Info
        </p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <h3 className="vs-title">{customer || 'Unknown customer'}</h3>
              <p className="pre-wrap">
                {address || 'Unknown address'}
              </p>
              <p style={{ fontSize: '1.15rem', marginBottom: 0 }}>
                <i className="fa fa-shipping-fast" /> {carrier || 'Unknown carrier'}
                <i className="fa fa-box mll" />
                <span className="mls">{box_count} box{box_count > 1 ? 'es' : ''}</span>
              </p>
              <p style={{ color: '#9f9f9f', fontStyle: 'italic' }}>{shipmentDate}</p>
            </div>
            <div className="column">
              {renderDeviceCounts(counts)}
            </div>
            <div className="column">
              <span className="has-text-weight-semibold">Tracking</span>
              <button type="button" className="button is-small tooltip mlxs" data-tooltip="Copy all to clipboard">
                <i className="fa fa-copy clickable" aria-hidden="true" onClick={() => copy(tracking.join('\n'))} />
              </button>
              {tracking.map(num => (
                <a
                  key={`rma-tracking-${num}`}
                  href={`https://www.google.com/search?q=${num}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <p>{num}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderDeviceCounts(count: {[key: string]: number}): JSX.Element[] {
  return Object.keys(count).map(key => (
    <p key={`device-count-${key}`} style={{ marginBottom: '0.3rem' }}>
      <b>{key}</b>: {count[key]}
    </p>
  ));
}

export default RmaShippingInfo;
