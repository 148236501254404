import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import { getManufacturer } from '../../utils/manufacturers';
import { Vsh500TestData } from '../../../../app/models/test_data';

const VshProductionTestRecord: React.FC<Vsh500TestData & { serial_number: string}> = ({
  serial_number,
  mfm_serial_number,
  mgt_serial_number,
  result,
  entry_date_time,
  manufacturer_name,
}: Vsh500TestData & { serial_number: string}) => {
  if (!serial_number) return null;

  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <p>
        {result ? 'Passed' : 'Failed'} a VSH500 production test with MGT:&nbsp;
        <Link to={`/search/devices/${mgt_serial_number}`}>{mgt_serial_number}</Link>&nbsp;
        MFM: <Link to={`/search/devices/${mfm_serial_number}`}>{mfm_serial_number}</Link>&nbsp;
        at { getManufacturer(manufacturer_name) }
      </p>
    </div>
  );
};

export default VshProductionTestRecord;
