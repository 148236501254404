import * as React from 'react';

interface IProps {
  show: boolean;
  close: () => void;
  leavePage: () => void;
  save: () => void;
  whichForm: string;
}

const BODY_MESSAGE = (whichForm: string): string => `You appear to have unsaved data in the ${whichForm} form. Do you want to lose your progress?`;

const ConfirmNavigation: React.FC<IProps> = ({
  show,
  close,
  leavePage,
  whichForm,
  save,
}: IProps) => {
  if (!show) return (<div />);

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure?</p>
          <button type="button" className="delete" aria-label="close" onClick={close} />
        </header>

        <section className="modal-card-body">
          <p>{BODY_MESSAGE(whichForm)}</p>
        </section>

        <footer className="modal-card-foot">
          <button type="button" onClick={leavePage} className="button is-danger">
            Proceed
          </button>
          <button type="button" onClick={close} className="button">
            Return To Form
          </button>
          <button type="button" onClick={save} className="button is-success">
            Save Progress
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ConfirmNavigation;
