import { AxiosPromise } from 'axios';

import http from './http';
import { CompatibilityInfo, CableCompatibilityInfo } from '../../../app/models/compatibility';
import { InsertResult } from '../../../app/models/util';

export function fetch(): AxiosPromise<CompatibilityInfo[]> {
  return http.get('/compatibility');
}

export function add(body: CompatibilityInfo): AxiosPromise<InsertResult[]> {
  return http.post('/compatibility', body);
}

export function update(compatibility: CompatibilityInfo): AxiosPromise<void> {
  return http.put(`/compatibility/${compatibility.entry_id}`, compatibility);
}

export function fetchCables(): AxiosPromise<CableCompatibilityInfo[]> {
  return http.get('/compatibility/cable');
}

export function addCable(body: CableCompatibilityInfo): AxiosPromise<InsertResult[]> {
  return http.post('/compatibility/cable', body);
}

export function updateCable(compatibility: CableCompatibilityInfo): AxiosPromise<void> {
  return http.put(`/compatibility/cable/${compatibility.entry_id}`, compatibility);
}
