import React, { useEffect, useState } from 'react';
import { Redirect, RedirectProps } from 'react-router';
import { Location } from 'history';

import { fetchUser } from '@actions/auth';
import { IUser } from '@models/User';

import Footer from '../shared/Footer';

interface IProps {
  location: Location<RedirectProps>;
}

const LoginPage: React.FC<IProps> = ({ location }: IProps) => {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    if (!user)fetchUser().then(setUser);
  }, []);

  const { from } = location.state || { from: { pathname: '/devices' } };
  if (user) return <Redirect to={from} />;
  return (
    <section className="login-hero">
      <div className="hero-body">
        <div className="column">
          <img
            src="/assets/glados.svg"
            className="logo"
            width="300"
            height="200"
            title="VoltServer ETX-8"
            alt="VoltServer Logo"
          />
          <p className="heading" style={{ marginTop: '-5px' }}>Generic Lifecycle and Device Organization System</p>
          <a href="/auth/openid" className="login-link">
            <img
              src="/assets/ms-symbollockup_signin_light.svg"
              className="logo"
              title="VoltServer ETX-8"
              alt="VoltServer Logo"
            />
          </a>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default LoginPage;
