import React from 'react';

import { formatDateTime } from '@utils/format';
import { getManufacturer } from '@utils/manufacturers';

import { ContinuityTestData } from '../../../../app/models/test_data';

const ContinuityTestRecord: React.FC<ContinuityTestData> = ({
  serial_numbers,
  result,
  entry_date_time,
  manufacturer_name,
}: ContinuityTestData) => {
  if (!serial_numbers) return null;
  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <p>{result ? 'Passed': 'Failed'} Continuity Test at {getManufacturer(manufacturer_name)}</p>
    </div>
  );
};

export default ContinuityTestRecord;
