import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import * as DeviceApi from '../../actions/device';

interface IProps {
  serial_number: string;
  fetchData: () => Promise<void>; //Equivalent to getFetchType(devices) in SearchPage.tsx
}

const TimelineNoteField: React.FC<IProps & RouteComponentProps> = ({serial_number, fetchData}: IProps) => {
  const [noteInput, setNoteInput] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [toggled, setToggled] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setNoteInput(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent | React.FormEvent): void => {
    e.preventDefault();
    if (noteInput.trim().length === 0) return;
    setSubmitting(true);
    DeviceApi.addNote(serial_number, noteInput.trim())
      .then(() => fetchData())
      .then(() => {
        setSubmitting(false);
        setNoteInput('');
        setToggled(false);
      });
  };

  if (!toggled) {
    return (
      <div className="timeline-item">
        <div className="timeline-marker is-success is-large-marker">
          <i className="fa fa-pencil-alt" />
        </div>
        <div className="timeline-content">
          <p className="field">
            <button className="button elev-1" type="button" onClick={() => setToggled(!toggled)}>
              <span className="icon"><i className="fa fa-plus" /></span>
              <span>Add Note</span>
            </button>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="timeline-item">
      <div className="timeline-marker is-success is-large-marker">
        <i className="fa fa-pencil-alt" />
      </div>
      <div className="timeline-content">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <div className="control has-icons-right serial-input">
              <textarea
                className="textarea note-text elev-1"
                placeholder="Add a note"
                value={noteInput}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button className={`button elev-1 is-info ${submitting ? 'is-loading': ''}`} type="button" onClick={handleSubmit} disabled={submitting}>
                Add Note
              </button>
            </div>
            <div className="control">
              <button
                className="button elev-1 is-danger"
                type="button"
                onClick={() => setToggled(!toggled)}
                disabled={submitting}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const TimelineNoteFieldWithRouter = withRouter(TimelineNoteField);
export default TimelineNoteFieldWithRouter;
