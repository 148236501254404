import React from 'react';

import { formatDateTime } from '@utils/format';
import { getManufacturer } from '@utils/manufacturers';

import { HiPotTestData } from '../../../../app/models/test_data';

const HighPotRecord: React.FC<HiPotTestData> = ({
  unit_serial_number,
  entry_date_time,
  manufacturer_name,
  result,
}: HiPotTestData) => {
  if (!unit_serial_number) return null;

  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <p>{result ? 'Passed': 'Failed'} High Pot Test at {getManufacturer(manufacturer_name)}</p>
    </div>
  );
};

export default HighPotRecord;
