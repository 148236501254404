import React, { useState, useEffect } from 'react';

import { IUser } from '@models/User';
import { ChangeEvent } from '@models/utils';

import { IHowToGetBetter } from '../../../../app/models/how_to_get_better';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  title: string;
  confirmText: string;
  okAction: (entity: IHowToGetBetter) => Promise<void>;
  user: IUser;
  entity?: IHowToGetBetter;
}

const EditEntryModal: React.FC<IProps> = ({
  toggled,
  closeAction,
  title,
  confirmText,
  okAction,
  user,
  entity,
}: IProps) => {
  const [h2gb, setH2gb] = useState<IHowToGetBetter>(entity || {
    entry_id: null,
    item: '',
    type: '',
    effort_level: 'Low',
    flag: false,
    status: 'Open',
    comment: '',
    link: '',
    upvotes: 0,
    created_by: '',
    created_at: null,
    assigned_to: '',
    updated_at: null,
    updated_by: '',
    upvoted_by: [],
  });

  useEffect(() => {
    if (entity) setH2gb(entity);
  }, [entity]);

  const handleChange = (evt: ChangeEvent): void => {
    const { name, value } = evt.target;
    setH2gb({...h2gb, [name]: value });
  };

  const handleFlagChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = evt.target;
    h2gb.flag = checked;
    setH2gb(h2gb);
  };

  async function handleSave(): Promise<void> {
    await okAction({
      ...h2gb,
      created_by: user.profile.displayName,
    });
    setH2gb(entity || {
      entry_id: null,
      item: '',
      type: '',
      effort_level: 'Low',
      flag: false,
      status: 'Open',
      comment: '',
      link: '',
      upvotes: 0,
      created_by: '',
      created_at: null,
      assigned_to: '',
      updated_at: null,
      updated_by: '',
      upvoted_by: [],
    });
    closeAction();
  }

  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" type="button" aria-label="close" onClick={closeAction} />
        </header>
        <section className="modal-card-body">

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Item</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="item"
                    onChange={handleChange}
                    value={h2gb.item}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Type</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="type"
                    onChange={handleChange}
                    value={h2gb.type}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Effort Level</h3>
            </div>
            <div className="field-body">
              <div className="field is-normal">
                <div className="control">
                  <div className="select is-fullWidth">
                    <select name="effort_level" value={h2gb.effort_level} onChange={handleChange}>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <h3 className="label">Flag</h3>
            </div>
            <div className="field-body" style={{alignItems: 'center'}}>
              <div className="field">
                <div className="control">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="flag"
                    onChange={handleFlagChange}
                    checked={h2gb.flag}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Status</h3>
            </div>
            <div className="field-body">
              <div className="field is-normal">
                <div className="control">
                  <div className="select is-fullWidth">
                    <select name="status" value={h2gb.status} onChange={handleChange}>
                      <option value="Open">Open</option>
                      <option value="✅">Closed ✅</option>
                      <option value="On Hold">On Hold</option>
                      <option value="Abandoned">Abandoned</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Link</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="link"
                    onChange={handleChange}
                    value={h2gb.link || ''}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Comment</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea
                    className="input"
                    name="comment"
                    onChange={handleChange}
                    value={h2gb.comment}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Assigned To</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="assigned_to"
                    onChange={handleChange}
                    value={h2gb.assigned_to || ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleSave} />
        </footer>
      </div>
    </div>
  );
};

EditEntryModal.defaultProps = {
  entity: null,
};

export default EditEntryModal;
