import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { isWebUri } from 'valid-url';
import ReactMarkdown from 'react-markdown';

import { IDocument } from '../../../../../../app/models/document';
import * as DocumentActions from '../../../../actions/document';
import LoadingSpinner from '../../LoadingSpinner';
import ContentModal from '../../ContentModal';

interface Props {
  search: string;
}

const NaviResults: React.FC<Props> = ({ search }: Props) => {
  const [results, setResults] = useState<IDocument[] | null>(null);
  const [popupText, setPopupText] = useState<string | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('navi', '').trim();
    if (!searchVal || searchVal.length === 0) return;

    DocumentActions.search(searchVal).then(setResults);
  }, [search]);

  if (!results) return <LoadingSpinner />;
  if (results.length === 0) return <span className="mts">No matches found.</span>;

  return (
    <>
      {results.map((doc) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={() => {
            if (isWebUri(doc.link)) window.open(doc.link, '_new');
            else setPopupText(doc.link);
          }}
          key={doc.id}
          className="search-result">
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">{doc.title}</div>
            <div className="result-subtitle">{doc.type}</div>
          </div>
          <span className="result-timestamp">
            {doc.last_updated_date ? DateTime.fromISO(doc.last_updated_date).toFormat('DD') : '--'}
          </span>
        </div>
      ))}
      {popupText !== null && (
        <ContentModal closeDialogAction={() => setPopupText(null)}>
          <div className="card markdown-card">
            <div className="card-content">
              <ReactMarkdown source={popupText} />
            </div>
          </div>
        </ContentModal>
      )}
    </>
  );
};

export default NaviResults;
