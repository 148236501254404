import React from 'react';
import classNames from 'classnames';

import { IPRState } from '../../../../app/models/ipr_entries';

interface Props {
  state: IPRState;
}

const IPRTimeline: React.FC<Props> = ({ state }: Props) => (
  <div className="content ipr-timeline">
    <ul className="steps is-horizontal has-gaps is-narrow is-centered has-content-centered">
      <RequestStep state={state} />
      <ApprovalStep state={state} />
      <ShipmentStep state={state} />
      <InvoiceStep state={state} />
    </ul>
  </div>
);

export default IPRTimeline;

function RequestStep({ state }: Props): JSX.Element {
  let marker = <CompletedStep />;
  let text = 'Requested';
  if (state === IPRState.UNSUBMITTED) {
    marker = <ActiveStep />;
    text = 'Request';
  }
  return (
    <li className={classNames('steps-segment', {
      'is-active': state === IPRState.UNSUBMITTED,
    })}>
      {marker}
      <div className="steps-content">
        <p className="heading">
          {text}
        </p>
      </div>
    </li>
  );
}

function ApprovalStep({ state }: Props): JSX.Element {
  let marker; let
      text;
  switch (state) {
    case IPRState.UNSUBMITTED:
      marker = <InactiveStep />;
      text = 'Approval';
      break;
    case IPRState.PENDING:
    case IPRState.SUBMITTED:
      marker = <ActiveStep />;
      text = 'Approval';
      break;
    case IPRState.REJECTED:
      marker = <RejectedStep />;
      text = 'Rejected';
      break;
    default:
      marker = <CompletedStep />;
      text = 'Approved';
  }

  return (
    <li className={classNames('steps-segment', {
      'is-active': [IPRState.SUBMITTED, IPRState.PENDING, IPRState.REJECTED].includes(state),
    })}>
      {marker}
      <div className="steps-content">
        <p className="heading">{text}</p>
      </div>
    </li>
  );
}

function ShipmentStep({ state }: Props): JSX.Element {
  let marker; let
      text;
  switch (state) {
    case IPRState.SHIPPED:
    case IPRState.COMPLETED:
      marker = <CompletedStep />;
      text = 'Shipped';
      break;
    case IPRState.APPROVED:
      marker = <ActiveStep />;
      text = 'Shipment';
      break;
    default:
      marker = <InactiveStep />;
      text = 'Shipment';
  }

  return (
    <li className={classNames('steps-segment', { 'is-active': state === IPRState.APPROVED })}>
      {marker}
      <div className="steps-content">
        <p className="heading">{text}</p>
      </div>
    </li>
  );
}

function InvoiceStep({ state }: Props): JSX.Element {
  let marker; let
      text;
  switch (state) {
    case IPRState.COMPLETED:
      marker = <CompletedStep />;
      text = 'Invoiced';
      break;
    case IPRState.SHIPPED:
      marker = <ActiveStep />;
      text = 'Invoice';
      break;
    default:
      marker = <InactiveStep />;
      text = 'Invoice';
  }

  return (
    <li className={classNames('steps-segment', {
      'is-active': state === IPRState.SHIPPED || state === IPRState.COMPLETED,
    })}>
      {marker}
      <div className="steps-content">
        <p className="heading">
          {text}
        </p>
      </div>
    </li>
  );
}

function CompletedStep(): JSX.Element {
  return (
    <span className="steps-marker">
      <span className="icon">
        <i className="fa fa-check" />
      </span>
    </span>
  );
}

function RejectedStep(): JSX.Element {
  return (
    <span className="steps-marker is-danger">
      <span className="icon">
        <i className="fa fa-times" />
      </span>
    </span>
  );
}

function ActiveStep(): JSX.Element {
  return (
    <span className="steps-marker is-info">
      <span className="icon">
        <i className="fa fa-circle fa-xs" />
      </span>
    </span>
  );
}

function InactiveStep(): JSX.Element {
  return (
    <span className="steps-marker is-hollow" />
  );
}
