import http from './http';
import Device from '../../../app/lib/device';
import { UnitSnPcaSnMap } from '../../../app/models/device';
import { PartQuery } from '../../../app/models/parts';

export async function fetch(serial_number: string): Promise<Device> {
  const {data} = await http.get(`/device/${serial_number}`);
  return data;
}

export async function addNote(serial_number: string, note: string): Promise<number> {
  const {status} = await http.post(`/device/${serial_number}/notes`, {note});
  return status;
}

export async function fetchParts(): Promise<PartQuery[]> {
  const { data } = await http.get('/device/parts');
  return data;
}

export async function getUnitSnPcaSn(sn: string): Promise<UnitSnPcaSnMap> {
  const { data } = await http.get(`/device/mapPCA/${sn}`);
  return data;
}

export async function scrapPart(sn: string, reason: string, notes: string): Promise<boolean> {
  try {
    await http.post(`/device/${sn}/scrap`, { reason, notes });
    return true;
  }
  catch (ex) {
    return false;
  }
}

export async function fetchValidScrapReasons(): Promise<string[]> {
  try {
    const { data } = await http.get('/device/valid-scrap-reaons');
    return data;
  }
  catch (ex) {
    return [];
  }
}
