import http from './http';

import Feedback from '../../../app/models/feedback';

async function sendFeedback(feedback: Feedback): Promise<boolean> {
  try {
    await http.post('/slack/feedback', feedback);
    return true;
  }
  catch {
    return false;
  }
}

export default sendFeedback;
