import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../utils/format';
import { RmaItem } from '../../../../app/models/inventory';

const RmaItemRecord: React.FC<RmaItem> = ({
  serial_number,
  rma_number,
  entry_date_time,
}: RmaItem) => {
  if (!serial_number) return null;
  return (
    <div>
      <p className="heading">{formatDate(entry_date_time)}</p>
      <p>Added to RMA <Link to={`/search/rma/${rma_number}`}>#{rma_number}</Link></p>
    </div>
  );
};

export default RmaItemRecord;
