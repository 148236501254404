import { IUser, User } from '@models/User';

export function renderIfAdmin(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isAdmin) return component;
  return null;
}

export function renderIfPowerUser(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isPowerUser) return component;
  return renderIfAdmin(component, iUser);
}

export function renderIfOp(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isOperations) return component;
  return renderIfPowerUser(component, iUser);
}

export function renderIfEng(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isEngineer) return component;
  return renderIfPowerUser(component, iUser);
}

export function renderIfQuality(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isOperations) return component;
  return renderIfPowerUser(component, iUser);
}

export function renderIfAccounting(component: JSX.Element, iUser: IUser): JSX.Element {
  if (iUser === null) return null;
  const user = new User(iUser);
  if (user.isAccounting) return component;
  return renderIfPowerUser(component, iUser);
}

export function renderIfEngOrOp(component: JSX.Element, iUser: IUser): JSX.Element {
  return renderIfEng(component, iUser) || renderIfOp(component, iUser);
}

export function renderIfOpOrAccounting(component: JSX.Element, iUser: IUser): JSX.Element {
  return renderIfOp(component, iUser) || renderIfAccounting(component, iUser);
}
