/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import RmaInfo from './RmaInfo';
import ShippedTable from './ShippedTable';
import ReceivedTable from './ReceivedTable';
import NotReceivedTable from './NotReceivedTable';
import { IRma } from '../../../../app/models/rma';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  rma: IRma;
}

const RmaPage: React.FC<IProps> = ({rma}: IProps) => {
  if (!rma) return <LoadingSpinner />;

  const {
    notReceived,
    received,
    newShipped,
    shipped,
    customer,
  } = rma;
  if ((!received || received.length === 0)
  && (!shipped || shipped.contents.length === 0)
  && !customer) return <p> No rmas match that number </p>;

  return (
    <div className="rma-page">
      <RmaInfo
        {...rma}
        numReceived={received.length}
        numShipped={shipped.contents.length}
      />
      <hr />
      <ShippedTable shipped={newShipped} />
      <hr />
      <ReceivedTable received={received} />
      <hr />
      <NotReceivedTable notReceived={notReceived} />
    </div>
  );
};

export default RmaPage;
