import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { renderIfEng, renderIfEngOrOp, renderIfOpOrAccounting } from '@utils/permissions';
import { IUser } from '@models/User';

import Footer from '../Footer';

const IS_ACTIVE_CLASS = 'is-active';

interface IProps {
  user: IUser;
  activeTab: string;
  open: boolean;
}

const SideBar: React.FC<IProps> = ({user, activeTab, open}: IProps) => {
  const activeNavClass = (url: string): string => (url === activeTab ? IS_ACTIVE_CLASS : '');

  return (
    <nav className={classNames('sidebar', { open })} role="navigation" aria-label="main navigation">
      <Link to="/" className="sidebar-item">
        {open ? (
          <img src="/assets/glados.png" className="logo" title="GLaDOS" alt="GLaDOS logo" />
        ) : (
          <img src="/assets/icon-310.png" style={{ width: '35px' }} className="logo" title="GLaDOS" alt="GLaDOS logo" />
        )}
      </Link>
      <div className="sidebar-items">
        <Link to="/" className={`sidebar-item ${activeNavClass('search')}`}>
          <i className="fas fa-search" />
          {open && <span>Search</span>}
        </Link>

        {/* Products */}
        {open ? (
          <h3 className="sidebar-subheader">Products</h3>
        ) : (
          <hr />
        )}
        <Link to="/skus2" className={`sidebar-item ${activeNavClass('skus2')}`}>
          <i className="fas fa-tag" />
          {open && <span>SKUs</span>}
        </Link>
        {renderIfEngOrOp(
          <>
            <Link to="/inventory" className={`sidebar-item ${activeNavClass('inventory')}`}>
              <i className="fas fa-boxes" />
              {open && <span>Inventory</span>}
            </Link>
            <Link to="/metrics" className={`sidebar-item ${activeNavClass('metrics')}`}>
              <i className="fas fa-chart-area" />
              {open && <span>Metrics</span>}
            </Link>
          </>,
          user,
        )}

        {/* Management */}
        {open ? (
          <h3 className="sidebar-subheader">Management</h3>
        ) : (
          <hr />
        )}
        <Link to="/rma/summary" className={`sidebar-item ${activeNavClass('rma')}`}>
          <i className="fas fa-sync" />
          {open && <span>RMAs</span>}
        </Link>
        <Link to="/iprs" className={`sidebar-item ${activeNavClass('iprs')}`}>
          <i className="fas fa-box" />
          {open && <span>IPRs</span>}
        </Link>
        {renderIfOpOrAccounting(
          <Link to="/po" className={`sidebar-item ${activeNavClass('po')}`}>
            <i className="fas fa-shopping-cart" />
            {open && <span>PO Summary</span>}
          </Link>,
          user,
        )}
        {renderIfEng(
          <Link to="/deviceConfigurations" className={`sidebar-item ${activeNavClass('deviceConfigurations')}`}>
            <i className="fas fa-cloud-upload-alt" />
            {open && <span>Config Uploads</span>}
          </Link>,
          user,
        )}

        {/* Documentation */}
        {open ? (
          <h3 className="sidebar-subheader">Documentation</h3>
        ) : (
          <hr />
        )}
        <Link to="/compatibility" className={`sidebar-item ${activeNavClass('compatibility')}`}>
          <i className="fas fa-tachometer-alt" />
          {open && <span>Load Compatibility</span>}
        </Link>
        <Link to="/field_issues" className={`sidebar-item ${activeNavClass('field_issues')}`}>
          <i className="fas fa-exclamation-circle" />
          {open && <span>Field Issues</span>}
        </Link>
        <Link to="/navi" className={`sidebar-item ${activeNavClass('navi')}`}>
          <i className="fas fa-link" />
          {open && <span>Navi</span>}
        </Link>
        {renderIfEng(
          <Link to="/how_to_get_better" className={`sidebar-item ${activeNavClass('how_to_get_better')}`}>
            <i className="fas fa-thumbs-up" />
            {open && <span>H2GB</span>}
          </Link>,
          user,
        )}
      </div>
      <div style={{ flex: 1 }} />
      {open && <Footer />}
    </nav>
  );
};

export default SideBar;
