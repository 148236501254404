import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTable, { Column } from 'react-table';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';

import { fetchAll } from '../../actions/pos';
import { filterAsString, filterDatesMed } from '../../utils/table_helpers';
import { PoShipmentGrouped } from '../../../../app/models/inventory';
import { formatDate } from '../../utils/format';
import LoadingSpinner from '../shared/LoadingSpinner';

const POSummaryPage: React.FC<{}> = () => {
  usePageTitle('POs');
  const [summary, setSummary] = useState<PoShipmentGrouped[] | null>(null);

  useEffect(() => {
    if (summary === null) {
      fetchAll().then(result => {
        if (result.length === 0) setSummary([]);
        else setSummary(result);
      });
    }
  }, []);

  const columns: Column[] = [{ Header: 'PO Number',
    accessor: 'po_number',
    Cell: function poNumber(cell) {
      return (
        <div style={{paddingLeft: '3px'}}>
          <Link to={`/search/po/${cell.value}`}>{cell.value}</Link>
        </div>
      );
    }}, {
    Header: 'Shipment Date',
    accessor: 'shipment_date',
    Cell: function shipDate(cell) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{formatDate(cell.value, DateTime.DATE_MED)}</span>
        </div>
      );
    },
    filterMethod: filterDatesMed,
  },
  { Header: 'Customer',
    accessor: 'customer',
    Cell: function customer(cell) {
      return (
        <div style={{paddingLeft: '3px'}}>
          <span>{cell.value}</span>
        </div>
      );
    }},
  { Header: '# of Units',
    accessor: 'num_units',
    Cell: function numUnits(cell) {
      return (
        <div style={{paddingLeft: '3px'}}>
          <span>{cell.value}</span>
        </div>
      );
    }}];

  return (
    <div className="is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <h1 className="page-title">PO Summary</h1>
      {summary === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={summary}
          columns={columns}
          filterable
          defaultFilterMethod={filterAsString}
          showPagination={summary.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default POSummaryPage;
