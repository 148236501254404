import React from 'react';

import { formatDateTime } from '../../utils/format';
import { getManufacturer } from '../../utils/manufacturers';
import { ProgrammingTestData } from '../../../../app/models/test_data';

const ProgrammingRecord: React.FC<ProgrammingTestData> = ({
  serial_number,
  firmware_file,
  key_date_time,
  manufacturer_name,
  result,
}: ProgrammingTestData) => {
  if (!serial_number) return null;

  return (
    <div>
      <p className="heading">{formatDateTime(key_date_time)}</p>
      <p>{result ? 'Success': 'Failure'} programming with {firmware_file} at {getManufacturer(manufacturer_name)}</p>
    </div>
  );
};

export default ProgrammingRecord;
