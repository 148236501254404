import React, { useState } from 'react';

import { IUser } from '@models/User';

import { EntrySelect, EntryTextArea } from '../rma_entry/EntryMiscComponents';
import sendFeedback from '../../actions/feedback';

import ContentModal from './ContentModal';
import Feedback from '../../../../app/models/feedback';

interface IProps {
  closeDialogAction: () => void;
  user: IUser;
}

enum SendingState {
  READY,
  SENDING,
  SUCCESS,
  ERROR,
}

const FeedbackDialog: React.FC<IProps> = ({ closeDialogAction, user }: IProps) => {
  const [feedback, setFeedback] = useState<string>('');
  const [sendingState, setSendingState] = useState<SendingState>(SendingState.READY);
  const [priority, setPriority] = useState<string>('Low');

  async function submitFeedback(): Promise<void> {
    const feedbackPayload: Feedback = {
      submittedBy: user.profile.displayName,
      mailto: user.profile.mail,
      feedbackInfo: feedback,
      priority,
    };
    setSendingState(SendingState.SENDING);
    try {
      await sendFeedback(feedbackPayload);
      setSendingState(SendingState.SUCCESS);
    }
    catch {
      setSendingState(SendingState.ERROR);
    }
    setTimeout(() => {
      setSendingState(SendingState.READY);
      closeDialogAction();
    }, 2000);
  }

  function getSubmitButtonClass(): string {
    if (sendingState === SendingState.READY) return 'is-success';
    if (sendingState === SendingState.SENDING) return 'is-loading is-success';
    if (sendingState === SendingState.SUCCESS) return 'is-success';
    if (sendingState === SendingState.ERROR) return 'is-danger';
    return '';
  }

  function getSubmitButtonContents(): JSX.Element {
    if (sendingState === SendingState.READY) return <span>Submit Feedback</span>;
    if (sendingState === SendingState.SENDING) return <span>Submitting...</span>;
    if (sendingState === SendingState.SUCCESS) {
      return (
        <div>
          <span>Success</span>
          <span className="icon">
            <i className="fa fa-check" />
          </span>
        </div>
      );
    }
    if (sendingState === SendingState.ERROR) {
      return (
        <div>
          <span>Error</span>
          <span className="icon">
            <i className="fa fa-times" />
          </span>
        </div>
      );
    }
    return <div />;
  }

  const slackLink = 'http://slack.com/app_redirect?channel=sw-tool-feedback';
  const priorities = ['Low', 'Medium', 'High', 'Critical'];

  return (
    <ContentModal closeDialogAction={closeDialogAction}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <i className="fas fa-comment mrs" />
            <span>GLaDOS Feedback</span>
          </p>
          <button className="delete" type="button" aria-label="close" onClick={closeDialogAction} />
        </header>
        <section className="modal-card-body">
          <p className="mbm">
            Let us know how we can improve!
            All messages here are sent directly to the
            <a href={slackLink} style={{ fontFamily: 'monospace' }}> #sw-tool-feedback</a> Slack channel.
          </p>
          <EntryTextArea
            name="Feedback"
            value={feedback}
            onChange={event => setFeedback(event.target.value)}
          />
          <EntrySelect
            name="Priority"
            value={priority}
            onChange={event => setPriority(event.target.value)}
            options={priorities}
          />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button
            className={`button ${getSubmitButtonClass()}`}
            type="button"
            onClick={() => submitFeedback()}
            disabled={!feedback}>
            {getSubmitButtonContents()}
          </button>
        </footer>
      </div>
    </ContentModal>
  );
};

export default FeedbackDialog;
