import React from 'react';

import { formatDateTime } from '../../utils/format';
import { Scrap } from '../../../../app/models/inventory';

const ScrapRecord: React.FC<Scrap> = ({
  serial_number,
  scrap_reason,
  notes,
  entry_date_time,
  user_id,
}: Scrap) => {
  if (!serial_number) return null;
  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>Scrapped. Reason: {scrap_reason} - {user_id || 'Unknown User'}</p>
        </div>
        <div className="message-body">
          {notes}
        </div>
      </article>
    </div>
  );
};

export default ScrapRecord;
