import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useNavBarContext } from '../NavBar';
import Device from '../../../../../../app/lib/device';
import * as DeviceActions from '../../../../actions/device';
import LoadingSpinner from '../../LoadingSpinner';

interface Props {
  search: string;
}

const DeviceResults: React.FC<Props> = ({ search }: Props) => {
  const { hidePanel } = useNavBarContext();
  const [device, setDevice] = useState<Device | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('rma', '').trim();
    if (!searchVal || searchVal.length === 0) return;
    DeviceActions.fetch(searchVal).then(setDevice);
  }, [search]);

  if (!device || !device.events) return <LoadingSpinner />;
  if (device.events.length === 0) return <span className="mts">No matches found.</span>;

  const msg = [];
  if (device.firmware_version) msg.push(`FW v${device.firmware_version}`);
  if (device.hardware_version) msg.push(`HW v${device.hardware_version}`);
  if (device.revision) msg.push(`Rev ${device.revision}`);
  return (
    <Link
      onClick={hidePanel}
      className="search-result"
      to={`/search/devices/${device.serial_number}`}>
      <div className="is-fullwidth" style={{ display: 'flex', flexFlow: 'column' }}>
        <div style={{ display: 'flex', flexFlow: 'row' }}>
          <div className="result-title" style={{ flex: 1 }}>
            {device.product_type}
            {device.jma && <span className="tag is-link is-small mls">JMA</span>}
            {device.custom && <span className="tag is-info is-small mls">Custom</span>}
          </div>
          <span className="result-timestamp">
            <i className="fa fa-map-marker-alt mrs" />{device.current_location || 'Unknown Location'}
          </span>
        </div>
        <div className="result-subtitle">{msg.join(', ')}</div>
      </div>
    </Link>
  );
};

export default DeviceResults;
