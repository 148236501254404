import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { POQuery } from '../../../../../../app/lib/po';
import * as POActions from '../../../../actions/pos';
import LoadingSpinner from '../../LoadingSpinner';
import { useNavBarContext } from '../NavBar';

interface Props {
  search: string;
}

const POResults: React.FC<Props> = ({ search }: Props) => {
  const { hidePanel } = useNavBarContext();
  const [results, setResults] = useState<POQuery[] | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('po', '').trim();
    if (!searchVal || searchVal.length === 0) return;
    POActions.search(searchVal).then(setResults);
  }, [search]);

  if (!results) return <LoadingSpinner />;
  if (results.length === 0) return <span className="mts">No matches found.</span>;
  return (
    <>
      {results.map((po) => (
        <Link
          onClick={hidePanel}
          key={po.po_number}
          className="search-result"
          to={`/search/po/${po.po_number}`}>
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">PO #{po.po_number}</div>
            <div className="result-subtitle">{po.customer}</div>
          </div>
          <span className="result-timestamp">
            {DateTime.fromISO(po.shipment_date).toFormat('DD')}
          </span>
        </Link>
      ))}
    </>
  );
};

export default POResults;
