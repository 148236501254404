import React from 'react';

import ContentModal from './ContentModal';

interface IProps {
  closeDialogAction: () => void;
  entity: any;
  action: (entity: any) => Promise<void>;
  message: string | JSX.Element;
}

const ConfirmDeleteDialog: React.FC<IProps> = ({closeDialogAction, entity, action, message}: IProps) => {
  const confirmAction = (): Promise<void> => action(entity);
  return (
    <ContentModal closeDialogAction={closeDialogAction}>
      <div className="modal-card">
        <header className="modal-card-head is-danger">
          <p className="modal-card-title" style={{ marginBottom: 0 }}>
            <i className="fa fa-lg fa-exclamation-triangle mrs" />
            <span>Please Confirm</span>
          </p>
          <button className="delete" type="button" aria-label="close" onClick={closeDialogAction} />
        </header>
        <section className="modal-card-body">
          {message}
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button className="button is-danger" type="button" onClick={confirmAction}>Yes, Delete</button>
          <button className="button" type="button" onClick={closeDialogAction}>No, Cancel</button>
        </footer>
      </div>
    </ContentModal>
  );
};

export default ConfirmDeleteDialog;
