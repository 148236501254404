import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import pcxOrPtx from '../../utils/eltek';
import { PcxAndPtxTestData } from '../../../../app/models/test_data';

const PcxOrPtxProductionTestRecord: React.FC<PcxAndPtxTestData & { serial_number: string }> = ({
  serial_number,
  unit_serial_number,
  mfm_serial,
  mgt_serial,
  eltek_serial,
  eltek_type,
  result,
  entry_date_time,
}: PcxAndPtxTestData & { serial_number: string }) => {
  if (!serial_number) return null;

  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <p>
        {result ? 'Passed' : 'Failed'} a { pcxOrPtx(eltek_type) } production test with
        &nbsp;MGT: <Link to={`/search/devices/${mgt_serial}`}>{mgt_serial}</Link>
        &nbsp;MFM: <Link to={`/search/devices/${mfm_serial}`}>{mfm_serial}</Link>
        &nbsp;Eltek: <Link to={`/search/devices/${eltek_serial}`}>{eltek_serial}</Link>
        { unit_serial_number
          ? <>&nbsp;Chassis: <Link to={`/search/devices/${unit_serial_number}`}>{unit_serial_number}</Link></>
          : '' }
      </p>
    </div>
  );
};

export default PcxOrPtxProductionTestRecord;
