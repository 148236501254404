import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../utils/format';
import { IPO } from '../../../../app/models/inventory';

const IpoRecord: React.FC<IPO> = ({
  serial_number,
  ipo_number,
  notes,
  entry_date_time,
  user_id,
}: IPO) => {
  if (!serial_number) return null;
  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <article className="message">
        <div className="message-header">
          <p>Device was modified on IPO <Link to={`/search/ipo/${ipo_number}`}>#{ipo_number}</Link> by {user_id || 'Unknown User'} to <Link to={`/search/devices/${serial_number}`}>{serial_number}</Link></p>
        </div>
        <div className="message-body">
          {notes}
        </div>
      </article>
    </div>
  );
};

export default IpoRecord;
