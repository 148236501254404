import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'immutable';

import { useIPRs } from '@actions/ipr_entries';
import { User } from '@models/User';
import IPRTable from '@components/ipr_entry/list/IPRTable';
import IPR from '@models/IPR';
import IPRCard from '@components/ipr_entry/list/IPRCard';
import classNames from 'classnames';
import { usePageTitle } from '@hooks/common';

interface Props {
  user: User;
}

const IPRList: React.FC<Props> = ({ user }: Props) => {
  usePageTitle('IPRs');

  const { data: iprs } = useIPRs({ refreshInterval: 12_000 });
  const iprList = iprs ?? List<IPR>();

  const [showCompleted, setShowCompleted] = useState(false);

  const myIPRs = iprList.filter((ipr) => ipr.createdBy === user.profile.mail);
  const myIncompleteIPRs = myIPRs.filter((ipr) => !ipr.isCompleted);

  const awaitingFinance = iprList
    .filter((ipr) => (ipr.isSubmitted || ipr.isPending) && !ipr.isFinanceApproved);
  const awaitingInventory = iprList
    .filter((ipr) => ipr.isApproved || ((ipr.isSubmitted || ipr.isPending) && !ipr.isInventoryApproved));
  const awaitingEither = awaitingFinance
    .concat(awaitingInventory.filter(ipr => !awaitingFinance.contains(ipr)));
  const awaitingInvoice = iprList.filter((ipr) => ipr.isShipped);

  const account = user.isIPRFinanceApprover && !user.isIPRInventoryApprover && !awaitingFinance.isEmpty();
  const inventory = user.isIPRInventoryApprover && !user.isIPRFinanceApprover && !awaitingInventory.isEmpty();
  const either = user.isIPRFinanceApprover && user.isIPRInventoryApprover && !awaitingEither.isEmpty();
  const invoice = user.isIPRExecutor && !awaitingInvoice.isEmpty();
  const myiprs = !myIPRs.isEmpty();

  const twoOrMore = (account ? 1 : 0) + (inventory ? 1 : 0) + (either ? 1 : 0) + (invoice ? 1 : 0) + (myiprs ? 1 : 0);
  const styles = twoOrMore >= 2 ? { flexFlow: 'column' } : { flexFlow: 'column', height: '100%' };
  return (
    <div className="ipr-entry-list is-flex" style={styles}>
      <div className="is-flex is-fullwidth">
        <h1 className="page-title" style={{ flex: 1 }}>Internal Product Requests</h1>
        <Link to="/iprs/new">
          <button type="button" className="button is-pulled-right mbs elev-1 is-link">
            <span>New IPR</span>
            <span className="icon is-small">
              <i className="fa fa-plus" />
            </span>
          </button>
        </Link>
      </div>

      {/* Awaiting Accounting Approval */}
      {account && (
        <>
          <h2 className="page-subtitle">Awaiting Approval</h2>
          {renderIPRList(awaitingFinance)}
        </>
      )}

      {/* Awaiting Inventory Approval / Shipment */}
      {inventory && (
        <>
          <h2 className="page-subtitle">Awaiting Approval / Shipment</h2>
          {renderIPRList(awaitingInventory)}
        </>
      )}

      {/* Awaiting Either Inventory or Finance Approval */}
      {either && (
        <>
          <h2 className="page-subtitle">Awaiting Approval</h2>
          {renderIPRList(awaitingEither)}
        </>
      )}

      {/* Awaiting Invoice */}
      {invoice && (
        <>
          <h2 className="page-subtitle">Awaiting Invoice</h2>
          {renderIPRList(awaitingInvoice)}
        </>
      )}

      {/* My IPRs */}
      {myiprs && (
        <>
          <h2 className="page-subtitle">
            My IPRs
            <button
              type="button"
              className="button is-white has-shadow mlm"
              onClick={() => setShowCompleted(!showCompleted)}>
              <i
                style={{ fontSize: '1rem' }}
                className={classNames('fas mrs', showCompleted ? 'fa-eye-slash' : 'fa-eye')}
              />
              {showCompleted ? 'Hide' : 'Show'} Completed
            </button>
          </h2>
          {renderIPRList(showCompleted ? myIPRs : myIncompleteIPRs)}
        </>
      )}

      {/* IPR Table */}
      <IPRTable iprs={iprs} />
    </div>
  );
};

function renderIPRList(iprs: List<IPR>): JSX.Element {
  if (iprs.isEmpty())
    return <p className="mbm is-italic">You have no pending IPRs</p>;
  return (
    <div className="ipr-list mbm">
      {iprs
        .sortBy((ipr) => -ipr.iprNum)
        .map((ipr) => <IPRCard key={ipr.iprNum} ipr={ipr} />)}
      <div className="empty-spacer" />
    </div>
  );
}

export default IPRList;
