import { useEffect, useState, useRef } from 'react';

import http from '../actions/http';

export const axiosFetcher = (url: string) => http.get(url).then((res) => res.data);

export function useDebounce<V>(value: V, delay: number): V {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => { clearTimeout(handler); };
  }, [value]);

  return debouncedValue;
}

/**
 * A hook used to call a function on the given delay interval
 * @param callback The function to call
 * @param delay The amount of time between function calls
 */
export const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => (savedCallback.current ? savedCallback.current() : null);
    if (delay === null)
      return undefined;

    const id = setInterval(handler, delay);
    return () => clearInterval(id);
  }, [delay]);
};

/**
 * A hook to set the browser tab title with the suffix "VoltQ"
 * @param title The title of the page
 */
export const usePageTitle = (title?: string): void => {
  useEffect(() => {
    window.document.title = title ? `GLaDOS • ${title}` : 'GLaDOS - Generic Lifecycle and Device Organization System';
  }, [title]);
};
