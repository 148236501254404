import http from './http';
import { IDocument } from '../../../app/models/document';

const BASE_URL= '/documents';

export async function fetchAll(): Promise<IDocument[]> {
  const {data} = await http.get(BASE_URL);
  return data;
}

export async function fetch(id: number | string): Promise<IDocument> {
  const { data } = await http.get(`${BASE_URL}/${encodeURIComponent(id)}`);
  return data;
}

export async function create(item: IDocument): Promise<IDocument> {
  const {data} = await http.post(BASE_URL, item);
  return data;
}

export async function update(item: IDocument): Promise<IDocument> {
  const {data} = await http.put(`${BASE_URL}/${item.id}`, item);
  return data;
}

export async function destroy(item: IDocument): Promise<number> {
  const {status} = await http.delete(`${BASE_URL}/${item.id}`);
  return status;
}

export async function search(query: string): Promise<IDocument[]> {
  const { data } = await http.get(`${BASE_URL}/search/${encodeURIComponent(query)}`);
  return data;
}
