import React, { useEffect, useState } from 'react';
import ReactTable, { Column } from 'react-table';

import { usePageTitle } from '@hooks/common';

import * as Inventory from '../../actions/inventory';
import { SKU } from '../../../../app/models/inventory';
import LoadingSpinner from '../shared/LoadingSpinner';

export const SKUPage: React.FC = () => {
  const [skus, setSkus] = useState<SKU[]>([]);

  useEffect(() => {
    Inventory.fetchSKUs().then(result => setSkus(result));
  }, []);

  const columns: Column[] = [{
    Header: 'SKU',
    accessor: 'sku',
  }, {
    Header: 'Description',
    accessor: 'description',
  }];

  return (
    <div className="po-page">
      <h1 className="page-title">Product SKUs</h1>
      <ReactTable
        data={skus}
        columns={columns}
        filterable
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
        defaultPageSize={50}
      />
    </div>
  );
};

export const SKU2Page: React.FC = () => {
  usePageTitle('SKUs');
  const [skus, setSkus] = useState<SKU[] | null>(null);

  useEffect(() => {
    // get list of SKUs, but we only want to display SKUs that are 'In Production' or 'In Design'
    if (skus === null) {
      Inventory.fetchSKUs2().then(result => {
        setSkus(result.filter(sku => sku.status === 'In Production' || sku.status === 'In Design'));
      });
    }
  }, []);

  const columns: Column[] = [{
    Header: 'SKU',
    accessor: 'sku',
    width: 300,
  },
  { Header: 'Item Name',
    accessor: 'name',
    Cell: function itemName(row) {
      return (
        <button
          className="button-like-link"
          type="button"
          onClick={async () => {
          // fetch file data for this item
            const resp = await fetch(`/inventory/skus2/file/${row.original.guid}`);
            if (!resp.ok) {
              alert('No files assocated with this item.');
              return;
            }
            // open the file in this window
            const fileData = await resp.arrayBuffer();
            const blob = new Blob([fileData], {type: 'application/pdf'});
            const objectUrl = URL.createObjectURL(blob);

            // create a link and simulate a click to open in a new tab
            const link = document.createElement('a');
            link.href = objectUrl;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            link.click();
            link.remove();
          }}>
          {row.row.name}
        </button>
      );
    }},
  /* TODO: We want to add this in the future, but currently the Arena API doesn't
  allow us to get the descrition with a single fetch
  {
    Header: 'Customer Description',
    accessor: 'description',
  },*/
  { Header: 'Phase',
    accessor: 'status',
    width: 150,

    // color the lifecycle phase based on its value
    Cell: function colorPhase({ value }) {
      return (
        <span style={{color: (value === 'In Production' ? 'green' : 'grey')}}>{value}</span>
      );
    }}];

  const defaultSorted = [{
    id: 'status',
    desc: true,
  }, {
    id: 'sku',
    desc: false,
  }];

  return (
    <div className="is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      <h1 className="page-title">Product SKUs</h1>
      {skus === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          data={skus}
          columns={columns}
          defaultSorted={defaultSorted}
          filterable
          defaultFilterMethod={
            (filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
          }
          showPagination={skus.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};
