import React, { useState } from 'react';

import { CableCompatibilityInfo } from '../../../../app/models/compatibility';
import SaveButton from '../shared/SaveButton';

interface IProps {
  toggled: boolean;
  closeAction: () => void;
  okAction: (entity: CableCompatibilityInfo) => Promise<void>;
  title: string;
  confirmText: string;
  entity?: CableCompatibilityInfo;
}

const EditCableCompatibilityModal: React.FC<IProps> = ({
  toggled,
  closeAction,
  okAction,
  title,
  confirmText,
  entity,
}: IProps) => {
  const [compatibility, setCompatibility] = useState<CableCompatibilityInfo>(entity || {
    cable_manufacturer: '',
    cable_model: '',
    cable_model_details: '',
    gauge: '',
    conductors: '',
    outdoor: 'yes',
    twisted_pair: 'yes',
    compatible: 'yes',
    tested_receiver: 'prx440',
    test_method: 'Field Test',
    comments: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>): void => {
    setCompatibility({...compatibility, [e.target.name]: e.target.value});
  };

  const handleAdd = async (): Promise<void> => {
    await okAction(compatibility);
    setCompatibility(entity || {
      cable_manufacturer: '',
      cable_model: '',
      cable_model_details: '',
      gauge: '',
      conductors: '',
      outdoor: 'yes',
      twisted_pair: 'yes',
      compatible: 'yes',
      tested_receiver: 'prx440',
      test_method: 'Field Test',
      comments: '',
    });
    closeAction();
  };

  const {
    cable_manufacturer, cable_model, cable_model_details, gauge, conductors,
    outdoor, twisted_pair, compatible, test_method, comments,
  } = compatibility;
  return (
    <div className={`modal ${toggled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" type="button" onClick={closeAction} />
        </header>
        <section className="modal-card-body">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Manufacturer</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="cable_manufacturer"
                    value={cable_manufacturer}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Model</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="cable_model"
                    value={cable_model}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Model Details</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="cable_model_details"
                    value={cable_model_details}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Gauge</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="gauge"
                    value={gauge}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Conductors</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    name="conductors"
                    value={conductors}
                    onChange={handleChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Outdoor</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="outdoor" value={outdoor} onChange={handleChange}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Twisted Pair</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="twisted_pair" value={twisted_pair} onChange={handleChange}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Compatible</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="compatible" value={compatible} onChange={handleChange}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                      <option value="limitations">Limitations</option>
                      <option value="TBD">TBD</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Test Method</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <div className="select">
                    <select name="test_method" value={test_method} onChange={handleChange}>
                      <option value="Field Test">Field Test</option>
                      <option value="Eng Simple Test">Eng Simple Test</option>
                      <option value="Eng Full Test">Eng Full Test</option>
                      <option value="Deduction">Deduction</option>
                    </select>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <h3 className="label">Comments</h3>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <textarea className="textarea" name="comments" value={comments} onChange={handleChange} />
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-text" type="button" onClick={closeAction}>Cancel</button>
          <SaveButton className="" text={confirmText} saveAction={handleAdd} />
        </footer>
      </div>
    </div>
  );
};

EditCableCompatibilityModal.defaultProps = {
  entity: null,
};

export default EditCableCompatibilityModal;
