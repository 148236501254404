import React, { useState } from 'react';

interface IProps {
  saveAction: () => Promise<void>;
  text: string;
  className: string;
}

const SaveButton: React.FC<IProps> = ({ saveAction, text, className}: IProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const handleSave = (): void => {
    setSaving(true);
    saveAction().then(() => setSaving(false));
  };

  return (
    <button
      type="submit"
      className={`button is-success ${saving && 'is-loading'} ${className}`}
      onClick={handleSave}
      disabled={saving}>
      {text}
    </button>
  );
};

export default SaveButton;
