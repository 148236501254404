import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { IPRQuery } from '../../../../../../app/lib/ipr';
import * as IPRActions from '../../../../actions/iprs';
import LoadingSpinner from '../../LoadingSpinner';

interface Props {
  search: string;
}

const IPRResults: React.FC<Props> = ({ search }: Props) => {
  const [results, setResults] = useState<IPRQuery[] | null>(null);

  useEffect(() => {
    const searchVal = search.toLowerCase().replace('ipr', '').trim();
    if (!searchVal || searchVal.length === 0) return;

    IPRActions.search(searchVal).then(setResults);
  }, [search]);

  if (!results) return <LoadingSpinner />;
  if (results.length === 0) return <span className="mts">No matches found.</span>;

  return (
    <>
      {results.map((ipr) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={() => { window.location.href = `/iprs/${ipr.ipr_num}`; }}
          key={ipr.ipr_num}
          className="search-result">
          <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <div className="result-title">IPR #{ipr.ipr_num} - {ipr.customer}</div>
            <div className="result-subtitle">{ipr.created_by}</div>
          </div>
          <span className={classNames('tag', {
            'is-success': ipr.state === 'completed',
            'is-danger': ipr.state === 'rejected',
          })}>
            {ipr.state}
          </span>
        </div>
      ))}
    </>
  );
};

export default IPRResults;
