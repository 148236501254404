import React from 'react';
import { Link } from 'react-router-dom';

import { RX542KTestData } from '../../../../app/models/test_data';
import { formatDateTime } from '../../utils/format';

const Rx542kTestRecord: React.FC<RX542KTestData> = ({
  entry_date_time,
  result,
  fail_message,
  unit_serial_number,
  pca_serial_number,
}: RX542KTestData) => {
  if (!unit_serial_number || !pca_serial_number) return null;

  return (
    <div>
      <p className="heading">{formatDateTime(entry_date_time)}</p>
      <p>
        {result ? 'Passed' : 'Failed'} an RX54-2K production test.
      </p>
      <p>
        Unit Serial:
        <Link to={`/search/devices/${unit_serial_number}`}>
          {unit_serial_number}
        </Link>
      </p>
      <p>
        PCA Serial:
        <Link to={`/search/devices/${pca_serial_number}`}>
          {pca_serial_number}
        </Link>
      </p>
      {fail_message && <pre className="fail-message">Fail Message: {fail_message}</pre>}
    </div>
  );
};

export default Rx542kTestRecord;
