import React, { useState, useEffect } from 'react';
import ReactTable, { Filter, Column } from 'react-table';
import { Location, History } from 'history';
import { DateTime } from 'luxon';

import { usePageTitle } from '@hooks/common';
import {
  fetchAll,
  create,
  update,
  destroy,
  upvote,
  unUpvote,
} from '@actions/field_issues';
import { renderIfAdmin } from '@utils/permissions';
import { updateFilteredQueryString, filterAsString, filterDatesShort } from '@utils/table_helpers';
import { IUser } from '@models/User';
import { formatDate } from '@utils/format';

import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import EditIssueModal from './EditIssueModal';
import { IFieldIssue } from '../../../../app/models/field_issues';
import LoadingSpinner from '../shared/LoadingSpinner';

interface IProps {
  location: Location;
  history: History;
  user: IUser;
}

const FieldIssuesPage: React.FC<IProps> = ({location: { search }, history, user}: IProps) => {
  usePageTitle('Field Issues');

  const [issues, setIssues] = useState<IFieldIssue[] | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IFieldIssue>(null);
  const [deleteItem, setDeleteItem] = useState<IFieldIssue>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const addDocumentDialog = (): void => {
    setModalOpen(true);
    setEditItem(null);
  };

  const editItemFunc = (editingItem: IFieldIssue, evt: React.MouseEvent): void => {
    setModalOpen(true);
    setEditItem(editingItem);
    evt && evt.preventDefault();
  };

  const deleteItemFunc = async (issue: IFieldIssue): Promise<void> => {
    await destroy(issue);
    setShowDeleteDialog(false);
    setDeleteItem(null);
    await fetchAll().then(fieldIssues => setIssues(fieldIssues));
  };

  const saveEntry = async (entry: IFieldIssue): Promise<void> => {
    await (entry.entry_id ? update(entry) : create(entry));
    await fetchAll().then(fieldIssues => setIssues(fieldIssues));
  };

  const doUpvote = async (userID: string, issue: IFieldIssue, index: number): Promise<void> => {
    const newIssue = await upvote(issue, userID);
    setIssues(Object.assign([], issues, {[index]: newIssue}));
  };

  const downvote = async (userID: string, issue: IFieldIssue, index: number): Promise<void> => {
    const newIssue = await unUpvote(issue, userID);
    setIssues(Object.assign([], issues, {[index]: newIssue}));
  };

  const alreadyUpvoted = (upvotedBy: string[], userID: string): boolean => upvotedBy.includes(userID);

  const upvoteOrDownvote = (userID: string, issue: IFieldIssue, index: number): void => {
    if (alreadyUpvoted(issue.upvoted_by, userID)) downvote(userID, issue, index);
    else doUpvote(userID, issue, index);
  };

  const amountVoted = (upvotedBy: string[]): number => upvotedBy.length;

  const toggleIcon = (upvotedBy: string[], userID: string): string => {
    if (alreadyUpvoted(upvotedBy, userID)) return 'fa fa-thumbs-up downvote';
    return 'far fa-thumbs-up novote';
  };

  const toggleColor = (upvotedBy: string[], userID: string): string => {
    if (alreadyUpvoted(upvotedBy, userID)) return 'downvote';
    return 'novote';
  };

  useEffect(() => {
    if (issues === null) fetchAll().then(issue => setIssues(issue));
  }, []);

  const query = new URLSearchParams(search);
  const filtered: Filter[] = [];

  query.forEach((value, key) => {
    filtered.push({id: key, value});
  });

  const columns: Column[] = [{
    Header: 'Title',
    accessor: 'title',
    maxWidth: 800,
    minWidth: 350,
  }, {
    Header: 'Type',
    accessor: 'type',
    maxWidth: 150,
    minWidth: 100,
  }, {
    Header: 'Product',
    accessor: 'product',
    maxWidth: 150,
    minWidth: 100,
  }, {
    Header: 'Status',
    accessor: 'status',
    maxWidth: 140,
    minWidth: 120,
  }, {
    Header: 'Description',
    accessor: 'description',
    maxWidth: 800,
    minWidth: 220,
  }, {
    Header: 'Link',
    accessor: 'link',
    filterable: false,
    maxWidth: 60,
    minWidth: 50,
    Cell: function linkCell(cell) {
      if (!cell.value) return null;
      return (
        <a
          href={cell.value}
          target="_new"
          style={{
            paddingLeft: '3px',
            paddingTop: '3px',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            display: 'block',
            fontSize: '1.3em',
          }}>
          <i className="fa fa-link" />
        </a>
      );
    },
  },
  { Header: 'Upvotes',
    accessor: 'upvoted_by',
    filterable: false,
    maxWidth: 90,
    minWidth: 80,
    sortMethod: (arrA: any[], arrB: any[]) => arrA.length - arrB.length,
    Cell: function upvotedBy(cell) {
      return (
        <div className="has-text-centered">
          <span
            style={{paddingRight: '5px'}}
            className={toggleColor(cell.original.upvoted_by || [], user.profile.id)}>
            {amountVoted(cell.original.upvoted_by)}
          </span>
          <button
            className="button-no-bg"
            type="button"
            onClick={() => upvoteOrDownvote(user.profile.id, cell.original, cell.index)}>
            <i className={toggleIcon(cell.original.upvoted_by || [], user.profile.id)} />
          </button>
        </div>
      );
    }}, {
    Header: 'Created By',
    accessor: 'created_by',
    maxWidth: 100,
  },
  { Header: 'Created On',
    accessor: 'created_at',
    maxWidth: 100,
    filterMethod: filterDatesShort,
    Cell: function createdAt(row) {
      return (
        <div style={{paddingLeft: '3px', whiteSpace: 'normal', wordWrap: 'break-word'}}>
          <span>{row.value ? formatDate(row.value, DateTime.DATE_SHORT) : '--'}</span>
        </div>
      );
    }},
  { Header: 'Actions',
    filterable: false,
    sortable: false,
    resizable: false,
    maxWidth: 80,
    Cell: function actionsCell(cell) {
      return (
        <div className="has-text-centered">
          <button
            className="icon button-like-link"
            type="button"
            onClick={evt => editItemFunc(cell.original, evt)}>
            <i className="fa fa-pencil-alt" />
          </button>
          {renderIfAdmin(
            <button
              className="icon has-text-danger ml button-no-bg"
              type="button"
              title="Delete"
              onClick={() => {
                setShowDeleteDialog(true);
                setDeleteItem(cell.original);
              }}>
              <i className="fa fa-trash" />
            </button>,
            user,
          )}
        </div>
      );
    }}];

  return (
    <div className="documents-page is-flex" style={{ flexFlow: 'column', height: '100%' }}>
      {
        showDeleteDialog
        && (
          <ConfirmDeleteDialog
            entity={deleteItem}
            action={deleteItemFunc}
            message={`Are you sure you want to delete "${deleteItem.title}?"`}
            closeDialogAction={() => {
              setShowDeleteDialog(false);
              setDeleteItem(null);
            }}
          />
        )
      }
      { editItem
        ? (
          <EditIssueModal
            key={editItem.entry_id}
            title="Edit Entry"
            confirmText="Update"
            toggled={modalOpen}
            entity={editItem}
            closeAction={() => setModalOpen(false)}
            okAction={saveEntry}
            user={user}
          />
        )
        : (
          <EditIssueModal
            title="Create Entry"
            confirmText="Add"
            toggled={modalOpen}
            closeAction={() => setModalOpen(false)}
            okAction={saveEntry}
            user={user}
          />
        )}

      <div className="is-flex is-fullwidth">
        <h2 className="page-title" style={{ flex: 1 }}>Field Issues</h2>
        <button className="button elev-1" type="submit" onClick={addDocumentDialog}>
          <span>
            Add New Issue
          </span>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
        </button>
      </div>

      {issues === null ? (
        <LoadingSpinner />
      ) : (
        <ReactTable
          minRows={2}
          style={{ flex: 1, overflowY: 'auto' }}
          className="-striped -highlight"
          data={issues}
          columns={columns}
          filterable
          filtered={filtered}
          onFilteredChange={(filter) => updateFilteredQueryString(filter, history)}
          defaultSorted={[{id: 'created_at', desc: true}]}
          defaultFilterMethod={filterAsString}
          showPagination={issues.length > 100}
          defaultPageSize={100}
        />
      )}
    </div>
  );
};

export default FieldIssuesPage;
