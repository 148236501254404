export interface IUser {
  groups?: string[];
  profile: UserProfile;
}

export interface UserProfile {
  '@odata.context': string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
}

export class User implements IUser {
  groups: string[];
  profile: UserProfile;

  constructor(props: IUser) {
    this.groups = props.groups;
    this.profile = props.profile;
  }

  get isAdmin(): boolean {
    return this.groups.includes('GLADOS ADMINS');
  }

  get isPowerUser(): boolean {
    return this.groups.includes('GLADOS POWER USERS');
  }

  get isEngineer(): boolean {
    return this.groups.includes('ENGINEERING');
  }

  get isOperations(): boolean {
    return this.groups.includes('OPERATIONS');
  }

  get isAccounting(): boolean {
    return this.groups.includes('ACCOUNTING');
  }

  get isQuality(): boolean {
    return this.groups.includes('QUALITY');
  }

  get isIPRFinanceApprover(): boolean {
    return this.groups.includes('IPR FINANCE APPROVERS');
  }

  get isIPRInventoryApprover(): boolean {
    return this.groups.includes('IPR INVENTORY APPROVERS');
  }

  get isIPRApprover(): boolean {
    return this.isIPRInventoryApprover || this.isIPRFinanceApprover;
  }

  get isIPRExecutor(): boolean {
    return this.groups.includes('IPR EXECUTORS');
  }
}
